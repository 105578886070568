import {useState, FormEvent} from 'react'
import { Bank } from '~/api/models'
import { Modal, Input } from '~/components/ui'
import { useError, useRepository } from '~/components/hooks'

interface ICreateBankModal {
    show: boolean
    onCreated: (_: Bank) => void
    onCancel: () => void
    onFinish?: () => void
}

const CreateBankModal = ({ show, onCancel, onCreated, onFinish }: ICreateBankModal) => {

    const [state, setState] = useState({ index: '', name: '' })
    const [loading, setLoading] = useState(false)
    const { error, setErrors } = useError()
    const store = useRepository(({ banks }) => banks.create)

    const handleStore = (event?: FormEvent) => {
        event?.preventDefault()

        setLoading(prev => !prev)
        store(state)
            .then(bank => onCreated(bank))
            .catch(setErrors())
            .finally(() => {
                setLoading(prev => !prev)
                onFinish?.()
            })
    }

    return (
        <Modal
            show={show}
            title="Crear banco"
            confirmText={loading ? 'Procesando...' : 'Guardar'}
            onConfirm={handleStore}
            onCancel={onCancel}>
            <form className="w-full" onSubmit={handleStore}>
                <Input
                    id="index"
                    label="Prefijo"
                    error={error('index')}
                    hint="0105, 0134..."
                    onChange={index => setState($0 => ({...$0, index}))} />

                <Input
                    id="name"
                    label="Nombre"
                    className="mt-4"
                    error={error('name')}
                    onChange={name => setState($0 => ({...$0, name}))} />
            </form>
        </Modal>
    )
}

export default CreateBankModal
