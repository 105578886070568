import { FunctionComponent } from "react";
import { NavLink } from 'react-router-dom'
import classnames from "classnames";
import { Transition } from "@headlessui/react";
import {useSession} from "~/context/session";
import {Role} from "~/constants";

const LinkItem = ({ to, label }: { to: string, label: string }) => (
  <li className="text-gray-700 text-sm">
    <NavLink activeClassName="bg-blue-500 text-white hover:bg-opacity-80" className="hover:bg-blue-500 hover:bg-opacity-25 p-2 block rounded-xl" to={to}>
      {label}
    </NavLink>
  </li>
)  
interface ISidebarProps {
  open: boolean
  onSidebarToggle: () => void
}

const getViewsForUserRole = (id?: number) => {

  const role = id ?? 0

  const views = [
    { for: [Role.admin, Role.developer], component: <LinkItem to="/dashboard" label="Dashboard" /> },
    { for: [Role.admin, Role.developer], component: <LinkItem to="/titulars" label="Titulares" /> },
    { for: [Role.admin, Role.developer], component: <LinkItem to="/accounts" label="Números de cuenta" /> },
    { for: [Role.admin, Role.developer], component: <LinkItem to="/company-accounts" label="Cuentas de empresa" /> },
    { for: [Role.admin, Role.developer], component: <LinkItem to="/banks" label="Bancos" /> },
    { for: [Role.admin, Role.developer], component: <LinkItem to="/paysheets" label="Órdenes de nómina" /> },
    { for: [Role.admin, Role.developer], component: <LinkItem to="/payments" label="Órdenes personales" /> },
    { for: [Role.admin, Role.developer, Role.employee], component: <LinkItem to="/reports" label="Reportes" /> },
    { for: [Role.admin, Role.developer], component: <LinkItem to="/incomes" label="Ingresos" /> },
    { for: [Role.admin, Role.developer, Role.employee], component: <LinkItem to="/settings" label="Configuración" /> }
  ]

  return views.filter(view => view.for.includes(role))
    .map((view, key) => ({...view.component, key}))
}

const Sidebar: FunctionComponent<ISidebarProps> = ({ open, onSidebarToggle }) => {

  const { session } = useSession()

  return (
    <>
      <Transition
        show={open}
        enter="ease-out duration-300 z-30"
        enterTo="opacity-100"
        leave="ease-in duration-200 z-30"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div className="lg:hidden transform bg-gray-200 dark:bg-gray-800 z-30 bg-opacity-50 backdrop-filter backdrop-blur-sm" onClick={onSidebarToggle} />
      </Transition>

      <aside className={classnames('w-56 dark:border-gray-700 overflow-y-auto transition-transform border-r z-30 transform lg:transform-none inset-y-0')}>
        <ul className="p-2 space-y-1 w-full">
          {getViewsForUserRole(session?.type)}
        </ul>
      </aside>
    </>
  )
}

export default Sidebar
