import { AxiosInstance } from 'axios'
import { mapQueryString } from './utils/utils';

import authentication, { IAuthenticationContext } from './repositories/authentication'
import titulars, { ITitularEndpointsContext } from './repositories/titulars'
import accounts, { IAccountEndpointsContext } from './repositories/accounts'
import banks, { IBankEndpointsContext } from './repositories/banks'
import incomes, { IIncomeEndpointsContext } from './repositories/incomes'
import payments, { IPaymentEndpointsContext } from './repositories/payments'
import paysheets, { IPaysheetEndpointsContext } from './repositories/paysheets'
import reports, { IReportsEndpointContext } from './repositories/reports'
import settings, { ISettingEndpointsContext } from './repositories/settings'
import users, { IUserEndpointsContext } from './repositories/users'
import statistics, { IStatisticsEndpointsContext } from './repositories/statistics'
import announcements, { IAnnouncementEndpointsContext } from './repositories/announcements'

export interface IEndpointsContext {
  authentication: IAuthenticationContext
  titulars: ITitularEndpointsContext
  accounts: IAccountEndpointsContext
  banks: IBankEndpointsContext
  incomes: IIncomeEndpointsContext
  payments: IPaymentEndpointsContext
  paysheets: IPaysheetEndpointsContext
  reports: IReportsEndpointContext
  settings: ISettingEndpointsContext
  users: IUserEndpointsContext
  statistics: IStatisticsEndpointsContext
  announcements: IAnnouncementEndpointsContext
}
const endpoints = (axios: AxiosInstance) => Object.entries({
  authentication,
  titulars,
  accounts,
  banks,
  incomes,
  payments,
  paysheets,
  reports,
  settings,
  users,
  statistics,
  announcements
}).reduce((repositories, [name, repository]) => ({
  ...repositories,
  [name]: repository({
    axios,
    mapQueryString,
    getToken: () => localStorage.getItem('token')
  })
}), {} as IEndpointsContext)

export default endpoints