import {FunctionComponent, ReactNode, useEffect} from "react";
import {Button} from "~/components/ui";

interface IBottomSheetModal {
  show: boolean
  onClose: () => void
  title?: string
  actions?: ReactNode
}

const BottomSheetModal: FunctionComponent<IBottomSheetModal> = ({ children, show, onClose, title, actions }) => {

  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : 'auto'
  }, [show])

  return (
    <div className={['fixed bg-gray-900 bg-opacity-75 -top-3 backdrop-filter backdrop-blur-lg z-30', show ? 'inset-0' : ''].join(' ')}>
      <div className={['bg-white fixed bottom-0 inset-x-0 overflow-hidden transform duration-300 transition-transform top-28 rounded-t-xl flex flex-col', !show ? 'translate-y-full' : ''].join(' ')}>
        {show && (
          <>
            {title && (
              <header className="border-b px-2 flex items-center h-14 flex-shrink-0 inline-block">
                <h1 className="text-lg">
                  {title}
                </h1>
              </header>
            )}
            <main className="flex-grow p-4 overflow-y-auto">
              {children}
            </main>
            <footer className="border-t h-14 flex justify-end items-center flex-shrink-0 space-x-2 px-2">
              {actions}
              <Button variant="default" onClick={onClose}>
                Cerrar
              </Button>
            </footer>
          </>
        )}
      </div>
    </div>
  )
}

export default BottomSheetModal