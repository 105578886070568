// export const withoutInvalidValues = <T>(body: {} | Array<T>) => {

//     Object.entries(body)
//         .forEach(pair => {
//             const value = pair[1], attribute = pair[0]

//             if (value === '' || value === undefined || value === null) {
//                 delete body[pair[0]]

//             } else if (typeof value === 'object') {

//                 if (!Array.isArray(value)) {
//                     withoutInvalidValues(value)
//                 } else {
//                     if (!value.length) {
//                         delete body[attribute]
//                     }
//                 }
//             }
//         })

//     return body
// }

// export const toFormData = body => {
//     let data = new FormData()
//     Object.entries(body)
//         .forEach(pair => {
//             if (Array.isArray(pair[1])) return pair[1].forEach(($1, key) => data.append(`${pair[0]}[${key}]`, $1))

//             if (pair[1] === true) return data.append(pair[0], 1)

//             if (pair[1] === false) return data.append(pair[0], 0)

//             // if (Object.isObject(pair[1])) return toFormData(pair[1]) // EXPERIMENTAL

//             data.append(pair[0], pair[1])
//         })
    
//     return data
// }

/**
 * @description map a json into a query string
 * @param filters {JSON}
 * @returns 
 */
export const mapQueryString = (filters: {}): string => {
    return Object.entries(filters)
        .filter(pair => pair[1] !== '')
        .filter(pair => pair[1] !== null)
        .filter(pair => pair[1] !== undefined)
        .map((pair, index) => {
            return `${index === 0 ? '?' : '&'}${pair[0]}=${pair[1]}`
                .replaceAll(/\s+/gm, '') // removes all new lines, tabs and spaces
                .trim()

        }).join('')
}