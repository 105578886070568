import { RoleType } from "./User";

export enum AnnouncementType {
  TYPE_YEARLY = 0,
  TYPE_MONTHLY = 1,
  TYPE_DAILY = 2,
  // TYPE_HOURLY = 3,
  TYPE_YEARLY_AT = 4,
  TYPE_MONTHLY_AT = 5,
  TYPE_DAYLY_AT = 6
}

export enum AnnouncementTarget {
  TARGET_ADMINS = RoleType.admin,
  TARGET_DEVELOPERS = RoleType.developer,
  TARGET_EMPLOYEES = RoleType.employee,
  TARGET_ALL = 0
}

interface Announcement {
  id: number
  title: string
  content: string
  date?: String
  type: AnnouncementType
  target: AnnouncementTarget
}

export default Announcement