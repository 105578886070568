import { useState, useEffect, useMemo, useRef } from 'react'
import { BsCalendar } from 'react-icons/bs'
import styles from './DatePicker.module.css'
import {Input} from "~/components/ui";

interface IDatePickerProps {
  range?: boolean
  id?: string
  label?: string
  required?: boolean
  hint?: string
  onChange?: (_: string[]) => void
  onDifferenceChange?: (_: number) => void,
  value?: string,
  years?: string[] | number[]
}

const DatePicker = ({
  range = false,
  id,
  label,
  required = false,
  value,
  hint,
  ...rest
}: IDatePickerProps) => {
  const MONTH_NAMES = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  const DAYS = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']

  const [month, setMonth] = useState(new Date().getMonth())
  const [year, setYear] = useState(new Date().getFullYear())
  const [show, setShow] = useState(false)
  const [datepickerValue, setDatepickerValue] = useState(value)
  const [_range] = useState(range)
  const [firstDate, setFirstDate] = useState<Date | null>(null)
  const [secondDate, setSecondDate] = useState<Date | null>(null)

  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (!container.current?.contains(event.target as Node)) {
        if (!show) return
        setShow(false)
      }
    }

    window.addEventListener('click', handleOutsideClick)

    return () => window.removeEventListener('click', handleOutsideClick)
  }, [show, container])

  useEffect(() => {
    // if (datepickerValue) {
      rest.onChange?.(_range
        ? (datepickerValue as string ?? '')?.split('-')?.map($0 => $0.trim())
        : [(datepickerValue as string)?.trim()]
      )
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datepickerValue])

  useEffect(() => {    
    setDatepickerValue(value)
  }, [value])

  const initDate = () => {
    const date = new Date()

    if (!range) {
      setDatepickerValue(new Intl.DateTimeFormat('en-US').format(date))
    }

    setYear(date.getFullYear())
    setMonth(date.getMonth())
  }

  // Checks if the day is the current day
  const isToday = (date: number) => {
    const today = new Date()
    const d = new Date(year, month, date)

    return today.toDateString() === d.toDateString()
  }

  // Gets an actual date value from the calendar number passed in
  const getDateValue = (date: number) => {
    let selectedDate = new Date(year, month, date)
    setFirstDate(() => selectedDate)
    setDatepickerValue(() => new Intl.DateTimeFormat('en-US').format(selectedDate))
    setShow(() => false)
  }

  const daysByMonthAndYear = useMemo(() => Array.from({ length: new Date(year, month + 1, 0).getDate() }, (_, idx) => idx + 1), [year, month])
  const blankPlaceholderDays = useMemo(() => Array.from({ length: new Date(year, month).getDay() }, (_, idx) => idx + 1), [year, month])

  // Calculates the _range in days between the first and second date
  const evalRange = (date: number) => {
    let selectedDate = new Date(year, month, date)

    if (!firstDate) {
      setFirstDate(selectedDate)
      return
    }

    let difference = (selectedDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24)

    if (difference <= 0) {
      setFirstDate(selectedDate)
      setSecondDate(null)
      return
    }

    setSecondDate(selectedDate)

    rest.onDifferenceChange?.(difference)
  };

  // Checking to see if the date has been selected or not
  const dateFromDay = (date: number) => {
    let newDate = new Date(year, month, date)

    if (firstDate !== null && secondDate !== null) {
      return (newDate.toDateString() === firstDate.toDateString() || newDate.toDateString() === secondDate.toDateString())
    }

    if (firstDate !== null) {
      return newDate.toDateString() === firstDate.toDateString()
    }

    return false
  }

  // Checking to see which days are between the first and second date
  const betweenDays = (date: number) => {
    let newDate = new Date(year, month, date)

    if (firstDate !== null && secondDate !== null) {
      return newDate <= secondDate && newDate >= firstDate
    }
  }

  useEffect(() => {
    // This function can be used to do computations when the date is changed
    if (firstDate !== null && secondDate !== null) {
      setDatepickerValue(new Intl.DateTimeFormat('en-US').format(firstDate) + ' - ' + new Intl.DateTimeFormat('en-US').format(secondDate))
    }
  }, [firstDate, secondDate])

  useEffect(() => {
    // initDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="antialiased sans-serif select-none" ref={container}>
      <div className="">
        <div className={styles.dFlex}>
          {id && label && (
            <label className={styles.label} htmlFor={id}>{label}</label>
          )}
          {required && (
            <span className={styles.requiredSign}>*</span>
          )}
        </div>
        {hint && (
          <small className={styles.hint}>{hint}</small>
        )}
        <div className={[(label || hint) && 'mt-1'].filter($0 => $0).join(' ')}>
          <div className="relative">
            <input type="hidden" name="date" />

            {/*className="flex justify-between w-full select-none space-x-2 items-center bg-white dark:bg-gray-900 dark:border-gray-700 border border-gray-200 rounded-md px-2 py-2.5 cursor-pointer"*/}
            <div
              onClick={() => {
                setShow(() => !show)
              }}>

              <Input value={datepickerValue?.toString()} suffix={<BsCalendar className="text-gray-500 text-lg mr-2" />} />
              {/*<p className="font-skylight text-sm whitespace-nowrap dark:text-gray-200">*/}
              {/*  {datepickerValue?.toString()}*/}
              {/*</p>*/}
            </div>
            {show && (
              <div className="bg-white dark:bg-gray-900 mt-9 z-10 border border-gray-50 dark:border-gray-700 rounded-lg shadow-md p-4 w-72 absolute top-2 left-0">
                <div className="border-b -mx-4 flex p-2 dark:border-gray-700 -mt-4">
                  <select value={year} className="flex-grow focus:outline-none dark:text-gray-200 bg-transparent" onChange={event => {
                    setMonth(month)
                    setYear(parseInt(event.target.value, 10))
                  }}>
                    {(rest.years ?? Array.from({ length: 150 }, (_, idx) => new Date().getFullYear() - idx))
                      .map(($0: string | number) => <option key={$0} value={$0}>{$0}</option>)}
                  </select>
                  <select className="flex-grow focus:outline-none dark:text-gray-200 bg-transparent" value={month} onChange={event => setMonth(parseInt(event.target.value, 10))}>
                    {MONTH_NAMES.map(($0, $1) => <option key={$1} value={$1}>{$0}</option>)}
                  </select>
                </div>
                <div className="flex justify-between items-center">
                  <div>
                    <span className="text-lg font-bold text-gray-800 dark:text-gray-500 font-skylight">
                        {MONTH_NAMES[month]}
                    </span>
                    <span className="ml-1 text-lg text-gray-600 font-normal dark:text-gray-200 font-skylight">
                        {year}
                    </span>
                  </div>
                  <div>
                    <button type="button" className="transition focus:outline-none ease-in-out duration-100 group inline-flex cursor-pointer text-gray-500 hover:text-gray-800 p-1 rounded-lg" onClick={() => {
                      setYear(month === 0 ? year - 1 : year)
                      setMonth((prev) => prev === 0 ? 11 : prev - 1)
                    }}>
                      <svg className="h-6 w-6 transition-all transform group-hover:scale-105 dark:text-gray-200 inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                      </svg>
                    </button>
                    <button type="button" className="transition focus:outline-none ease-in-out duration-100 group inline-flex cursor-pointer text-gray-500 hover:text-gray-800 p-1 rounded-lg" onClick={() => {
                      setYear(month === 11 ? year + 1 : year)
                      setMonth((prev) => prev === 11 ? 0 : prev + 1)
                    }}>
                      <svg className="h-6 w-6 transition-all transform group-hover:scale-105 dark:text-gray-200 inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="flex flex-wrap mb-3 -mx-1">
                  {DAYS.map((day, index) => (
                    <div className="px-1" key={index}>
                      <div key={index} className="text-gray-800 dark:text-gray-200 font-medium text-center text-xs w-7 font-skylight">
                        {day}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex flex-wrap -mx-1">
                  {blankPlaceholderDays.map((_, index) => (
                    <div className='px-1 mb-1' key={index}>
                      <div key={index} className='cursor-pointer text-center text-sm rounded-lg leading-loose font-skylight w-7 text-gray-700'>
                        {}
                      </div>
                    </div>
                  ))}
                  {daysByMonthAndYear.map((day, index) => (
                    <div className="px-1 mb-1" key={index}>
                      <div key={index} onClick={() => _range ? evalRange(day) : getDateValue(day)} className={[
                        "cursor-pointer bg-white dark:bg-gray-800 text-center dark:text-gray-200 text-sm rounded-lg leading-loose w-7  hover:bg-blue-600 hover:text-white font-skylight",
                        isToday(day)
                          ? "bg-green-600 text-white dark:bg-green-600 dark:text-white"
                          : dateFromDay(day) || betweenDays(day)
                          ? "bg-blue-600 text-white dark:bg-blue-600 dark:text-white"
                          :  ""
                      ].filter($0 => $0).join(' ')}>
                        {day}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex flex-wrap justify-end space-x-2">
                  <button className="text-sm focus:outline-none dark:text-gray-200" onClick={() => {
                    setFirstDate(null)
                    setSecondDate(null)
                    setDatepickerValue(undefined)
                  }}>Limpiar</button>
                  <button className="text-sm focus:outline-none text-green-600" onClick={() => {
                    setFirstDate(null)
                    setSecondDate(null)
                    setDatepickerValue(undefined)
                    initDate()
                    rest.onChange?.([])
                  }}>Hoy</button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DatePicker
