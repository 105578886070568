import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {Table, DropdownItem, Dropdown} from '~/components/ui'
import {useRepository} from '~/components/hooks'
import {Pagination, Account, Titular} from '~/api/models'
import {useBreadcrumbs} from "~/context/breadcrumb";

const Accounts = ({ titular }: { titular?: Titular }) => {

  const { setBreadcrumb } = useBreadcrumbs()
  const { id } = useParams<{ id: string }>()
  const [query, setQuery] = useState({ limit: 10, page: 1, with: 'titular,bank', titular: id })
  const [pagination, setPagination] = useState<Pagination<Account>>({ data: [] })
  const [loadings, setLoadings] = useState({ index: false })

  const { fetchAccounts } = useRepository(({ accounts }) => ({
      fetchAccounts: accounts.index
  }))

  const fetch = (_filters = {}) => {
    const _query = {...query, ..._filters}
    setLoadings(prev => ({...prev, index: !prev.index}))
    fetchAccounts<Pagination<Account>>(_query)
      .then(pagination => new Pagination<Account>(pagination, $0 => new Account($0)))
      .then(pagination => setPagination(pagination))
      .then(() => setQuery(_query))
      .finally(() => setLoadings(prev => ({...prev, index: !prev.index})))
  }

  useEffect(() => {
    setBreadcrumb([
      { title: 'Listado de titulares', to: '/titulars', loading: false },
      { title: titular?.name ?? '', to: `/titulars/${titular?.id}`, loading: !titular },
      { title: 'Cuentas', to: `/titulars/${id}/accounts`, loading: false }
    ])

    return () => setBreadcrumb([])
  }, [titular]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      <div className="flex items-end flex-wrap justify-between mb-4">
        <h1 className="text-2xl dark:text-gray-200">
          Cuentas registradas
        </h1>
      </div>

      <Table
        columnFilter
        onSearch={search => fetch({ search })}
        pagination={pagination}
        onPageChange={page => fetch({ page })}
        loading={loadings.index}
        onRefresh={fetch}
        source={pagination.data}
        columns={[
          { title: 'Número', path: 'number' },
          { title: 'Tipo', path: 'type' },
          { title: 'Banco', path: 'bank.name' },
          { title: 'Acciones', path: 'id', td: ({ item }) => {

            if (item.titular?.scope === 'Empresa') {
              return (
                <Dropdown right>
                  <DropdownItem>Transferir fondos</DropdownItem>
                </Dropdown>
              )
            }

            return <span className="text-gray-500">-- Sin opciones --</span>
          }},
        ]} />
    </>
  )
}

export default Accounts
