import classnames from "classnames"
import { useContext } from "react"
import ToastMessage from "./ToastMessage"
import { ToastContext } from "../../hooks/useToast"

export type ToastContainerProps = {
    variant?: keyof typeof VARIANTS
};

const VARIANTS = {
    'top-left': { style: "top-0 left-0" },
    'top-right': { style: "top-0 right-0" },
    'bottom-right': { style: "bottom-0 right-0" },
    'bottom-left': { style: "bottom-0 left-0" },
    'top-middle': { style: "top-0 left-1/2 -translate-x-1/2 transform" },
    'bottom-middle': { style: "bottom-0 left-1/2 -translate-x-1/2 transform" },
    undefined: { style: "top-0 right-0" },
}

export default function ToastContainer({ variant = "bottom-right" }: ToastContainerProps) {
    const context = useContext(ToastContext)

    const Var = VARIANTS[variant] || VARIANTS['top-right']

    const handleRemove = (id: string) => context?.remove(id)

    return (
        <div className={classnames(Var.style, "fixed z-50 w-full md:max-w-sm", "p-4 md:p-4 max-h-screen overflow-hidden pointer-events-none")}>
            <div className={classnames("flex-1 flex-col fade w-full mr-8 justify-end pointer-events-none")}>
                {context?.data.map((toast) => (
                    <div key={toast.id} className={classnames("flex py-1 w-full", "transform transition-all duration-300 pointer-events-auto")}>
                        <ToastMessage
                            id={toast.id}
                            message={toast.message}
                            type={toast.type}
                            title={toast.title}
                            // icon={toast.icon}
                            truncate={toast.truncate}
                            onRemove={handleRemove}
                            lifetime={toast.lifetime} />
                    </div>
                ))}
            </div>
        </div>
    )
}
