import {ReactNode} from 'react'
import styles from './Switch.module.css'
import {clx} from "../../../utils";

export interface ISwitchProps {
  value: boolean
  disabled?: boolean
  prefix?: ReactNode
  suffix?: ReactNode
  onChange?: (value: boolean) => void
}

const Switch = ({ prefix, suffix, value, disabled, onChange }: ISwitchProps) => {
  return (
    <div className={clx(styles.wrapper, {
      [styles.wrapperDisabled]: disabled
    })}>
      {prefix}
      <button
        type="button"
        onClick={() => onChange?.(!value)}
        className={clx(styles.track, {
          [styles.trackOn]: value,
          [styles.trackDisabled]: disabled
        })}>
        <div className={clx(styles.ball, {
          [styles.ballOn]: value
        })} />
      </button>
      {suffix}
    </div>
  )
}

export default Switch