import {Button, Dropdown, DropdownItem} from "~/components/ui";
import {useEffect, useState} from "react";
import {useRepository, useToast} from "~/components/hooks";
import {Announcement} from "~/api/models";
import CreateAnnouncementModal from "./components/CreateAnnouncementModal";

const AnnouncementRow = ({announcement, onDone}: { announcement: Announcement, onDone: () => void }) => {

  const repository = useRepository(({announcements}) => announcements)
  const toast = useToast()

  const fire = () => {
    repository.fire(announcement)
      .then(() => {
        toast.success({
          title: 'Anuncio enviado',
          message: 'Se ha enviado el anuncio a todos los usuarios'
        })
      })
  }

  const remove = (close: () => void) => {
    close()
    repository.remove(announcement.id)
      .then(() => {
        onDone()
      })
  }

  const ask = () => {
    toast.error({
      lifetime: -1,
      title: '¿Esta seguro de querer eliminar este anuncio?',
      message: ({ close }) => (
        <span className="flex flex-col space-y-2 mt-2">
          <p>Esta acción no puede deshacerse</p>
          <div className="space-x-2 flex items-center">
            <Button variant="dangerous" onClick={() => remove(close)}>
              Confirmar
            </Button>
            <Button variant="default" onClick={close}>
              Cancelar
            </Button>
          </div>
        </span>
      )
    })
  }

  return (
    <li className="hover:bg-primary p-4 hover:bg-opacity-10 rounded-lg">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <h1 className="text-lg">
            {announcement.title}
          </h1>
          <h2 className="text-gray-500">
            {announcement.content}
          </h2>
        </div>
        <div className="space-x-2 flex items-center">
          <Button variant="default" onClick={fire}>
            Disparar
          </Button>

          <Dropdown right>
            {/*<DropdownItem>*/}
            {/*  Actualizar*/}
            {/*</DropdownItem>*/}
            <DropdownItem mode="dangerous" onClick={ask}>
              Eliminar
            </DropdownItem>
          </Dropdown>
        </div>
      </div>
    </li>
  )
}

const Announcements = () => {
  const [showCreate, setShowCreate] = useState(false)
  const [announcements, setAnnouncements] = useState<Announcement[]>([])
  const repository = useRepository(({announcements}) => announcements)

  

  const fetch = (mounted: boolean) => {
    setShowCreate(false)
    repository.index<Announcement[]>({ limit: 0 })
      .then(announcements => {
        if (mounted) {
          setAnnouncements(announcements)
        }
      })
  }

  useEffect(() => {
    let mounted = true

    fetch(mounted)

    return () => {
      mounted = false
    }
  }, []) // eslint-disable-line

  // @ts-ignore
  return (
    <div className="space-y-3">
      <h1 className="text-2xl mb-2">Cada anuncio publicado se emitirá a travez de las siguientes fuentes</h1>
      <ul>
        <li>@simgularybot - Telegram</li>
      </ul>
      <Button variant="primary" onClick={() => setShowCreate(prev => !prev)}>
        Registrar nuevo anuncio
      </Button>

      <ul>
        {announcements.map((announcement, key) => (
          <AnnouncementRow announcement={announcement} key={key} onDone={() => fetch(true)} />
        ))}
      </ul>

      <CreateAnnouncementModal
        show={showCreate}
        onCreate={() => fetch(true)}
        onCancel={() => setShowCreate(false)} />
    </div>
  )
}

export default Announcements
