import {useEffect} from "react";
import {Toast} from "~/components/hooks/useToast";
import {FaTimes} from 'react-icons/fa'
import classnames from 'classnames'

const VARIANTS = {
  // icon: faInfoCircle,
  info: { base: "bg-white border-blue-500", iconstyle: "text-blue-500 ", name: "Info" },

  // icon: faExclamationCircle,
  error: { base: "bg-white border-red-500 ", iconstyle: "text-red-500 ", name: "Error" },

  // icon: faExclamationCircle,
  warning: { base: "bg-white border-yellow-500", iconstyle: "text-yellow-500 ", name: "Warning" },

  // icon: faCheck,
  success: { base: "bg-white border-green-500", iconstyle: "text-green-500 ", name: "Success" },
}

export type Truncate = "truncate-1-lines" | "truncate-2-lines" | "truncate-3-lines"

export type ToastMessageType = {
  id: string
  lifetime?: number
  variant?: keyof typeof VARIANTS | undefined
  onRemove?: (id: string) => void
  truncate?: Truncate
} & Toast

//   icon,
export default function ToastMessage({ id, title, message, lifetime, onRemove, truncate = "truncate-1-lines", type }: ToastMessageType) {
  const Var = type
    ? VARIANTS[type]
    : { base: "bg-white border-gray-600 ", iconstyle: "", name: title } // icon: icon,

  useEffect(() => {
    if (lifetime && onRemove) {
      if (lifetime >= 1) {
        setTimeout(() => {
          onRemove(id);
        }, lifetime);
      }
    }
  }, [lifetime, id, onRemove]);

  return (
    <div className={classnames("flex w-full visible flex-row shadow-lg", "border-l-4 rounded-md duration-100 cursor-pointer", "transform transition-all hover:scale-102", Var.base, type && "max-h-40")}>
      <div className="flex flex-row p-2 flex-no-wrap w-full">

        <div className="flex flex-col flex-no-wrap px-1 w-full">
          {title && (
            <div className="flex my-auto font-bold select-none">{title}</div>
          )}
          <div className={classnames("-mt-0.5 my-auto break-words flex","text-gray-600 text-sm", typeof message === "string" && truncate)}>
            {typeof message === 'function'
              ? message({ close: () => onRemove?.(id) })
              : message}
          </div>
        </div>
        <div onClick={() => onRemove && onRemove(id)} className={classnames("w-10 h-12 mr-2 items-center mx-auto", "text-center leading-none text-sm")}>
          <FaTimes className="mx-auto my-auto h-full text-center text-gray-600 cursor-pointer hover:scale-105 transform" />
        </div>
      </div>
    </div>
  )
}
