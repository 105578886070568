import {useEffect, useState, FunctionComponent} from 'react';
import CircleLoader from '../loaders/CircleLoader'
import styles from './Select.module.css'
import classnames from 'classnames'
import {BsChevronDown} from 'react-icons/bs'

interface IErrorProp {
  value?: string,
  clear: () => void
}

interface ISelectProps {
  loading?: boolean
  label?: any
  error?: IErrorProp
  onChange?: (_?: string) => void
  className?: string
  id?: string
  name?: string
  value?: string,
  required?: boolean
  disabled?: boolean
  hint?: string
}


const Select: FunctionComponent<ISelectProps> = ({
  children,
  loading = false,
  label = null,
  error = { value: undefined, clear: () => {} },
  id,
  name,
  required = false,
  value = undefined,
  className = undefined,
  hint,
  ...rest
}) => {

  const [_value, setValue] = useState<string | undefined>(value)

  useEffect(() => {
    if (_value !== undefined) {
      rest.onChange?.(_value)
      error.clear()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_value])

  useEffect(() => {
    setValue(value)
  }, [value])

  return (
    <div className={classnames(['flex flex-col', className])}>
      <div className="flex">
        {label && (
          <label className="text-gray-900 dark:text-gray-200 font-medium" htmlFor={id}>{label}</label>
        )}

        {required && (
          <span className={styles.requiredSign}>*</span>
        )}
      </div>
      {hint && (
        <small className="text-gray-400 leading-4">{hint}</small>
      )}
      <div id={id} className={classnames([styles.wrapper, {[styles.disabledWrapper]: rest.disabled, 'mt-1': label || hint}])}>
        <select {...rest} value={value} name={name} id={id} onChange={e => {
          setValue(e.target.value)
        }} className={styles.select}>
          {children}
        </select>
        {loading ? (
          <CircleLoader className={styles.loader} />
        ) : <BsChevronDown className="font-bold text-xl" />}
      </div>

      <div className={styles.feedbackZone}>
        {error?.value && (
          <small className={styles.feedbackError}>
            {error?.value}
          </small>
        )}
      </div>
    </div>
  )
}

export default Select
