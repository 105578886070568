import {useState, useEffect, ReactElement, ChangeEvent, useCallback, useMemo} from 'react'
import { debounce } from '~/utils'
import styles from './Textarea.module.css'
import classnames from 'classnames'

interface IErrorProp {
  value?: string,
  clear: () => void
}

interface ITextareaProps {
  id?: string
  mode?: 'default' | 'success' | 'error'
  label?: string
  value?: string | number
  placeholder?: string
  required?: boolean
  autoFocus?: boolean
  disabled?: boolean
  readOnly?: boolean
  hint?: string
  error?: IErrorProp
  counter?: boolean
  onChange?: (_: string) => void
  prefix?: ReactElement
  suffix?: ReactElement,
  className?: string
  onSearch?: (_: string) => void
}

const Input = ({
     id, label, value = '', placeholder, required, autoFocus, disabled, readOnly,
     hint, error = { value: undefined, clear: () => {} }, counter,
     onChange = (e) => e,
     prefix = undefined,
     suffix = undefined,
     onSearch,
     mode = 'default',
     ...rest
   }: ITextareaProps) => {

  const [_value, setValue] = useState(value)

  useEffect(() => {
    setValue(value)
  }, [value])

  const handleSearch = useCallback(debounce((event: ChangeEvent<HTMLTextAreaElement>) => onSearch?.(event?.target?.value), 400), [onSearch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onChange(_value as string)
    error.clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_value])

  const computedMode: typeof mode = useMemo(() => {
    if (error?.value) {
      return 'error'
    }

    return mode
  }, [error?.value, mode])

  return (
    <div className={classnames(styles.mainWrapper, rest.className)}>
      <div className={styles.dFlex}>
        {id && label && (
          <label className={styles.label} htmlFor={id}>{label}</label>
        )}
        {required && (
          <span className={styles.requiredSign}>*</span>
        )}
      </div>
      {hint && (
        <small className={styles.hint}>{hint}</small>
      )}
      <div className={[styles[`inner-${computedMode}`], (label || hint) && 'mt-1'].filter($0 => $0).join(' ')}>
        {prefix}
        <textarea
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          autoFocus={autoFocus}
          placeholder={placeholder}
          value={_value}
          onChange={e => {
            setValue(e.target.value)
            handleSearch(e)
          }}
          className={styles.baseInputClasses}/>

        {suffix}
      </div>
      <div className={styles.feedbackZone}>
        <span className={styles.feedbackError}>
            {error?.value}
        </span>
        {counter && (
          <span className={styles.feedbackCounter}>
              {(value as string).length}
          </span>
        )}
      </div>
    </div>
  )
}

export default Input