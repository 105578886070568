import { createContext, useReducer, FunctionComponent, useContext, Dispatch } from 'react';

export interface IBreadcrumbSegment {
    title: string | number
    to: string
    loading?: boolean
    customLoading?: any
}

export interface IBreadcrumbState {
    segments: IBreadcrumbSegment[]
}

enum BreadcrumbActions {
    SET = 'BREADCRUMB/SET'
}

interface IBreadcrumbAction {
    type: string
    payload: IBreadcrumbSegment[]
}

const initialState: IBreadcrumbState = {
    segments: []
}

const reducer = (state: IBreadcrumbState, action: IBreadcrumbAction): IBreadcrumbState => {
    switch (action.type) {
        case BreadcrumbActions.SET:
            return {...state, segments: action.payload }
        default:
            return state
    }
}

const Store = createContext<{
    state: IBreadcrumbState,
    dispatch: Dispatch<IBreadcrumbAction>
}>({
    state: initialState,
    dispatch: () => null
})

const Provider = Store.Provider
const BreadcrumbProvider: FunctionComponent = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState)

    return <Provider value={{state, dispatch}}>{children}</Provider>
}

export const useBreadcrumbs = () => {
    const { state, dispatch } = useContext(Store)

    const segments = state.segments

    const setBreadcrumb = (segments: IBreadcrumbSegment[] = []) => dispatch({
        type: BreadcrumbActions.SET,
        payload: segments
    })

    return { segments, setBreadcrumb }
}

export default BreadcrumbProvider
