import {Role} from "~/constants";
import PaysheetsScreen from "~/screens/paysheets";
import CreatePaysheetScreen from "~/screens/paysheets/create";
import UpdatePaysheetScreen from "~/screens/paysheets/update";
import CreateManyPaysheetScreen from "~/screens/paysheets/createMany";
import {PrivateRoute} from "~/Application";
import {Switch} from "react-router-dom";

const PaysheetsRouter = () => (
  <Switch>
    <PrivateRoute exact allows={[Role.admin, Role.developer]} path="/paysheets">
      <PaysheetsScreen />
    </PrivateRoute>
    <PrivateRoute exact allows={[Role.admin, Role.developer]} path="/paysheets/create">
      <CreatePaysheetScreen />
    </PrivateRoute>
    <PrivateRoute exact allows={[Role.admin, Role.developer]} path="/paysheets/:id/edit">
      <UpdatePaysheetScreen />
    </PrivateRoute>
    <PrivateRoute exact allows={[Role.admin, Role.developer]} path="/paysheets/create/many">
      <CreateManyPaysheetScreen />
    </PrivateRoute>
  </Switch>
)
export default PaysheetsRouter