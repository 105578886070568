import {FunctionComponent, useEffect, useState} from "react";
import {Titular} from "~/api/models";
import {useRepository} from "~/components/hooks";
import classnames from "classnames";
import {BsCheck} from "react-icons/bs";
import {Button} from "~/components/ui";

interface IEmployeeSelectionStepProps {
  onNext: (employees: Titular[]) => void
  defaultSelected?: number[]
}

const EmployeeSelectionStep: FunctionComponent<IEmployeeSelectionStepProps> = ({ onNext, defaultSelected = [] }) => {

  const [employees, setEmployees] = useState<Titular[]>([])
  const [selected, setSelected] = useState<number[]>(defaultSelected)
  const repository = useRepository(({ titulars }) => ({
    titulars: {
      index: titulars.index
    }
  }))

  const handleSelection = (id: number) => {
    const data = [...selected]
    const exists = data.findIndex(current => current === id)

    if (exists >= 0) {
      data.splice(exists, 1)
    } else {
      data.push(id)
    }

    setSelected(data)
  }

  useEffect(() => {
    repository.titulars.index<Titular[]>({ limit: 0, scope: 'Empleado', with: 'accounts.bank' })
      .then(setEmployees)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const selectAll = () => {
    if (selected.length === employees.length) {
      setSelected([])
    } else {
      setSelected(employees.map(employee => employee.id))
    }
  }

  const handleNext = () => {
    const data = selected.map(id => employees.find(employee => employee.id === id))
      .filter(employee => employee) as Titular[]

    onNext(data)
  }

  return (
    <div className="p-4">
      <small className="dark:text-gray-300 block mb-2">
        Por favor seleccione los titulares a los que se le creara una orden de nómina
      </small>
      <div className="flex space-x-2 mb-2">
        <button className={classnames('focus:outline-none transition-transform transform active:scale-95', {
          'text-blue-600': selected.length !== employees.length,
          'text-red-600': selected.length === employees.length,
        })} onClick={selectAll}>
          {selected.length === employees.length ? 'Eliminar selección' : 'Seleccionar todos'}
        </button>
      </div>
      <div className="grid grid-cols-3 gap-4">
        {employees.map((employee, index) => {

          const isSelected = selected.includes(employee.id)

          return (
            <div key={index}>
              <label className={classnames('border cursor-pointer transition-colors justify-between dark:border-gray-700 rounded-lg px-4 h-14 select-none flex space-x-2 items-center', {
                'bg-green-600 bg-opacity-70 text-white dark:text-white': isSelected,
                'dark:text-gray-400 bg-white': !isSelected
              })}>
                <input type="checkbox" checked={isSelected} className="hidden" onChange={() => handleSelection(employee.id)}/>
                <span>{employee.name}</span>

                {isSelected && (
                  <span className="w-8 h-8 bg-white dark:bg-gray-900 dark:bg-opacity-75 flex items-center justify-center rounded-full">
                    <BsCheck className="text-2xl text-green-600" />
                  </span>
                )}
              </label>
            </div>
          )
        })}
      </div>
      <div className="flex justify-end mt-4">
        <Button variant="primary" onClick={handleNext} disabled={!selected.length}>
          Siguiente
        </Button>
      </div>
    </div>
  )
}

export default EmployeeSelectionStep