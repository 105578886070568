import {useState, useEffect} from 'react'
import {Input, Select, Button} from '~/components/ui'
import {useRepository} from '~/components/hooks'
import {pickMap} from '~/utils/Object'
import {Titular} from '~/api/models'
import {useBreadcrumbs} from "~/context/breadcrumb";

const UpdateTitular = ({ titular, onUpdate }: { titular?: Titular, onUpdate: (_: Titular) => void }) => {

  const { setBreadcrumb } = useBreadcrumbs()
  const update = useRepository(({ titulars }) => titulars.update)
  const [state, setState] = useState({ ric: '', name: '', type: '', scope: '' })

  useEffect(() => {
      if (titular !== undefined) {
          setState(pickMap(titular, ({ id, name, ric, type, scope }) => ({ id, name, ric, type, scope })))
      }

  }, [titular]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBreadcrumb([
      { title: 'Listado de titulares', to: '/titulars', loading: false },
      { title: titular?.name ?? '', to: `/titulars/${titular?.id}`, loading: !titular },
    ])

    return () => setBreadcrumb([])
  }, [titular]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="flex flex-wrap -mx-2">
            <div className="w-full mb-4 px-2">
                <h2 className="text-2xl dark:text-gray-200">Actualizar datos</h2>
            </div>
            <div className="w-full xl:w-1/2 px-2">
                <Input
                    id="name"
                    label="Nombre"
                    value={state.name}
                    onChange={name => setState(prev => ({...prev, name}))} />
            </div>
            <div className="w-full xl:w-1/2 px-2 mt-4 xl:mt-0">
                <Input
                    id="ciric"
                    label="CI/RIC"
                    value={state.ric}
                    onChange={ric => setState(prev => ({...prev, ric}))} />
            </div>
            <div className="w-full xl:w-1/2 px-2 mt-4">
                <Select
                    id="type"
                    label="Tipo"
                    value={state.type}
                    onChange={type => setState(prev => ({...prev, type: type as string }))}>
                    <option value="Natural">Natural</option>
                    <option value="Jurídico">Jurídico</option>
                </Select>
            </div>
            <div className="w-full xl:w-1/2 px-2 mt-4">
                <Select
                    id="scope"
                    label="Ambito"
                    value={state.scope}
                    onChange={scope => setState(prev => ({...prev, scope: scope as string }))}>
                    <option value="Empleado">Empleado</option>
                    <option value="Externo">Externo</option>
                    <option value="Empresa">Empresa</option>
                </Select>
            </div>

            <div className="flex w-full px-2 justify-end mt-4">
                {titular !== undefined && (
                    <Button onClick={() => update(titular.id, state).then($0 => onUpdate({
                        ...titular,
                        ...pickMap($0, ({ type, name, scope, ric }) => ({ type, name, scope, ric }))
                    }))}>
                        Actualizar
                    </Button>
                )}
            </div>
        </div>
    )
}

export default UpdateTitular
