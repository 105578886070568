import { useEffect, useState } from 'react'
import { useRouteMatch, Link, Route, Switch, useParams } from 'react-router-dom'
import { useRepository } from '~/components/hooks'
import { useBreadcrumbs } from '~/context/breadcrumb';
import { Account } from '~/api/models'
import Update from '../accounts/update'
import Paysheets from '../accounts/paysheets'
import Payments from '../accounts/payments'

import {TitlePortal} from "../../components/ui";

const TitularScreen = () => {

  const { setBreadcrumb } = useBreadcrumbs()
  const show = useRepository(({ accounts }) => accounts.show)
  const [account, setAccount] = useState<Account | undefined>(undefined)
  const { id } = useParams<{ id: string }>()
  let { path } = useRouteMatch();

  let lastPath = window.location.pathname.split("/").pop();

  const fetch = () => {
    show(parseInt(id), { with: 'bank,titular', withCount: 'paysheets,payments' })
      .then(setAccount)
  }

  const InfoAccount= () => {
    return (lastPath !== "paysheets" && lastPath !== "payments") ?
      (
        <div className="w-full lg:w-3/5 xl:w-2/6 px-2">
          <div className="bg-white dark:bg-gray-900 rounded-2xl p-2 shadow-md">
            <section className="divide-y dark:divide-gray-800">
              <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                <b>Banco</b>
                <span className="">{account?.bank?.name ?? '-- Sin banco --'}</span>
              </div>
              <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                <b>Tipo</b>
                <span className="">{account?.type}</span>
              </div>
              <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                <b>Titular</b>
                {account?.titular
                  ? (<Link className="text-blue-400" to={`/titulars/${account.titular.id}`}>{account?.titular?.name}</Link>)
                  : (<span className="">-- Sin titular --</span>)}
              </div>
              <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                <b>Captital</b>
                <span className="">{account?.capital}</span>
              </div>

              <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                <b>Órdenes de nómina</b>
                <span className="">{account?.paysheets_count ?? 0}</span>
              </div>
              <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                <b>Órdenes de pago</b>
                <span className="">{account?.payments_count ?? 0}</span>
              </div>
            </section>
          </div>
        </div>
      ) : null       
  }

  useEffect(() => {
    setBreadcrumb([
      { title: 'Listado de cuentas', to: '/company-accounts', loading: false },
      { title: account?.number ?? '', to: `/company-accounts/${account?.id}`, loading: !account },
    ])
  }, [account]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetch()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TitlePortal>
        Detalle de número de cuenta
      </TitlePortal>
      <div className="flex flex-col w-full p-2">
        <h2 className="text-2xl dark:text-gray-200 mb-4">
          {account?.number}
        </h2>
        <div className="flex flex-wrap justify-center -mx-2">
          
          <InfoAccount></InfoAccount>

          <div className={`w-full px-2 mt-4 ${(lastPath !== "paysheets" && lastPath !== "payments") ? 'xl:w-4/6 xl:mt-0' : ''}`}>     
            <Switch>
              <Route exact path={path}>
                <Update account={account} onUpdate={setAccount} />
              </Route>
              <Route path={`${path}/paysheets`}>
                <Paysheets enterprise={id}/>
              </Route>              
              <Route path={`${path}/payments`}>
                <Payments enterprise={id}/>
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </>
  )
}

export default TitularScreen
