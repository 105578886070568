import { useState, FunctionComponent, useEffect } from 'react'
import { AnnouncementTarget, AnnouncementType } from '~/api/models/Announcement'
import { ICreateAnnouncementBody } from '~/api/repositories/announcements'
import { useError, useRepository, useToast } from '~/components/hooks'
import { BottomSheetModal, Button, DatePicker, Select, Input, Textarea } from '~/components/ui'

interface CreateAnnouncementModalProps {
  show: boolean,
  onCreate: () => void
  onCancel: () => void
}

const announcementTypeTranslations = {
  [AnnouncementType.TYPE_YEARLY]: 'Anualmente',
  [AnnouncementType.TYPE_MONTHLY]: 'Mensualmente',
  [AnnouncementType.TYPE_DAILY]: 'Diariamente',
  // [AnnouncementType.TYPE_HOURLY]: 'Cada hora',
  [AnnouncementType.TYPE_YEARLY_AT]: 'Cada año el...',
  [AnnouncementType.TYPE_MONTHLY_AT]: 'Todos los meses el dia...',
  [AnnouncementType.TYPE_DAYLY_AT]: 'Todos los dias a las...'
}

const announcementTargetTranslations = {
  [AnnouncementTarget.TARGET_ADMINS]: 'Administradores',
  [AnnouncementTarget.TARGET_DEVELOPERS]: 'Desarrolladores',
  [AnnouncementTarget.TARGET_EMPLOYEES]: 'Empleados',
  [AnnouncementTarget.TARGET_ALL]: 'Todos',
}


const CreateAnnouncementModal: FunctionComponent<CreateAnnouncementModalProps> = ({ show, onCreate, onCancel }) => {
  const [form, setForm] = useState<ICreateAnnouncementBody>({
    title: '',
    content: '',
    target: AnnouncementTarget.TARGET_EMPLOYEES,
    type: AnnouncementType.TYPE_DAILY
  })
  const repository = useRepository(({announcements}) => announcements)
  const toast = useToast()
  const { error, setErrors } = useError()

  const createAnnouncement = () => {
    repository.store(form)
      .then(() => {
        toast.success({
          title: 'Operación exitosa',
          message: 'Se ha creado el anuncio con éxito'
        })

        onCreate()
      })
      .catch(setErrors())
  }

  useEffect(() => {
    if (![AnnouncementType.TYPE_MONTHLY_AT, AnnouncementType.TYPE_YEARLY_AT].includes(form.type)) {
      setForm(prev => ({...prev, date: undefined}))
    }
  }, [form.type])

  return (
    <BottomSheetModal
        show={show}
        actions={
          (
            <Button onClick={createAnnouncement} variant="primary">
              Confirmar
            </Button>
          )
        }
        onClose={onCancel}>
        <div className="space-y-4">
          <Input
            onChange={title => setForm(prev => ({...prev, title}))}
            label="Título"
            error={error('title')}
            id="title" />
          <div>
            <label htmlFor="content">Contenido</label>
            <Textarea
              id="content"
              error={error('content')}
              onChange={content => setForm(prev => ({...prev, content}))}/>
          </div>

          <Select
            error={error('type')}
            label="¿Cuando disparar este anuncio?"
            onChange={value => setForm(prev => ({...prev, type: Number(value)}))}>
              {Object.values(AnnouncementType)
                .filter(type => !isNaN(Number(type)))
                .map((type) => (
                <option value={type}>
                  {announcementTypeTranslations[type as AnnouncementType]}
                </option>
              ))}
          </Select>

          {[AnnouncementType.TYPE_MONTHLY_AT, AnnouncementType.TYPE_YEARLY_AT].includes(form.type) && (
            <DatePicker
              id="date"
              label={announcementTypeTranslations[form.type]}
              onChange={([date]) => setForm(prev => ({...prev, date: date}))}/>
          )}

          <Select
            error={error('target')}
            label="¿A quien va dirigido?"
            onChange={value => setForm(prev => ({...prev, target: Number(value)}))}>
              {Object.values(AnnouncementTarget)
                .filter(target => !isNaN(Number(target)))
                .map((target) => (
                <option value={target}>
                  {announcementTargetTranslations[target as AnnouncementTarget]}
                </option>
              ))}
          </Select>
        </div>
      </BottomSheetModal>
  )
}

export default CreateAnnouncementModal
