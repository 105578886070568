import { ReactElement } from 'react';
import { Modal } from '~/components/ui'

interface IDeleteModalProps {
    show: boolean
    title: string,
    heading: string | ReactElement | Node,
    subheading?: string | number | ReactElement | Node,
    loading?: boolean
    onConfirm: () => void
    onCancel: () => void
}

const DeleteModal = ({
    show,
    title,
    heading,
    subheading,
    loading = false,
    onConfirm,
    onCancel
}: IDeleteModalProps) => (
    <Modal
        show={show}
        isDanger
        title={title}
        confirmText={loading ? 'Procesando...' : 'Confirmar'}
        onConfirm={onConfirm}
        onCancel={onCancel}>
            <h2 className="text-lg ">{heading}</h2>
            <h3 className=" mt-2">{subheading}</h3>
    </Modal>
)

export default DeleteModal
