import {FunctionComponent, useState} from 'react';
import BreadcrumbsProvider from '~/context/breadcrumb'
import Header from './Header'
import Sidebar from './Sidebar'

const AuthenticatedLayout: FunctionComponent = ({ children }) => {

  const [open, toggle] = useState(false)

  return (
    <BreadcrumbsProvider>
      <div className="min-h-screen w-full">
        <div className="flex w-full">
          <Sidebar open={open} onSidebarToggle={() => toggle(prev => !prev)} />
          <section className="flex-col relative flex overflow-y-hidden w-full h-screen transform flex-grow transition-all">
            <Header onSidebarToggle={() => toggle(prev => !prev)} />
            <main className="flex-grow bg-gray-50 overflow-y-auto flex flex-col justify-stretch w-full max-w-full min-w-0">
              { children }
            </main>
          </section>
        </div>
      </div>
    </BreadcrumbsProvider>
  )
}

export default AuthenticatedLayout
