import {Role} from "~/constants";
import BanksScreen from "~/screens/banks";
import {PrivateRoute} from "~/Application";
import {Switch} from "react-router-dom";

const BanksRouter = () => (
  <Switch>
    <PrivateRoute exact allows={[Role.admin, Role.developer]} path="/banks">
      <BanksScreen />
    </PrivateRoute>
  </Switch>
)

export default BanksRouter