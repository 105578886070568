interface Dictionary<Value> {
    [key: string]: Value
}

export const groupBy = <T extends Dictionary<any>>(array: T[], key: string): Dictionary<T[]> => {
    return array.reduce((accumulator: Dictionary<T[]>, current :T) => {
        accumulator[current[key]] = [...accumulator[current[key]] || [], current];
        return accumulator;
    }, {} as Dictionary<T[]>);
}
