import { useState, FormEvent } from 'react';
import {Input, Logo, Button} from '~/components/ui';
import {useRepository, useError, useToast} from '~/components/hooks'
import { useSession } from '~/context/session';
import { useHistory, Link } from 'react-router-dom';

const LoginScreen = () => {

    const { setUser } = useSession()
    const { error, setErrors } = useError()
    const [loading, setLoading] = useState(false)
    const router = useHistory()
    const toast = useToast()
    const [state, setState] = useState({ email: '', password: '' })
    const login = useRepository(({ authentication }) => authentication.login)

    const handleLogin = (event: FormEvent) => {
        event.preventDefault()

        setLoading(prev => !prev)
        login(state)
            .then(session => {
              setUser(session)

              if (session.user.isAdmin || session.user.isDeveloper) {
                router.push('/titulars')
              } else if (session.user.isEmployee) {
                router.push('/reports')
              }
            })
          .catch(setErrors(() => {
            toast.error({
              title: 'Oops!',
              message: 'Por favor verifique sus datos'
            })
          }))
          .finally(() => setLoading(prev => !prev))
    }

    return (
      <div className="h-screen w-full flex flex-col items-center justify-center">
        <Logo size="140px" />
        <div className="w-full flex w-full px-16 md:px-0 md:w-1/3 lg:w-2/6 xl:w-1/4">
          <form onSubmit={handleLogin} className="w-full flex flex-col items-center space-y-4">
            <Input
              id="email"
              label="Correo electrónico"
              value={state.email}
              className="w-full"
              error={error('email')}
              onChange={email => setState(prev => ({...prev, email}))} />
            <Input
              id="password"
              label="Contraseña"
              className="w-full"
              value={state.password}
              type="password"
              error={error('password')}
              onChange={password => setState(prev => ({...prev, password}))} />

            <Button variant="primary" className="w-full" disabled={loading} loading={loading}>
              Iniciar sesión
            </Button>

            <span className="text-gray-600">
              ¿Eres un empleado?
            </span>

            <Link to="/register">
              <a href="/register" className="text-primary">
                Registrate
              </a>
            </Link>
          </form>
        </div>
      </div>
    )
}

export default LoginScreen
