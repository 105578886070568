import { FunctionComponent } from 'react';

const GuestLayout: FunctionComponent = ({ children }) => {
    
    return (
        <div>
            {children}
        </div>
    )
}

export default GuestLayout