import { useCallback, useContext, useState, ReactNode, createContext } from "react";
import ToastContainer, { ToastContainerProps } from "../ui/toast/ToastContainer";
import { Truncate } from "../ui/toast/ToastMessage";

/////////////////////////////////////
/// Types
/////////////////////////////////////


interface MessageContext {
  close: () => void
}

export type ToastProviderProps = {
  children: ReactNode
} & ToastContainerProps

type ToastMessageType = "info" | "success" | "warning" | "error";

export type Toast = {
  id: string
  lifetime: number
  message: string | ((_: MessageContext) => ReactNode)
  type?: ToastMessageType
  truncate?: Truncate
  title?: string
};

export type ToastContextType = {
  data: Array<Toast>
  error: (_: {title?: string, message: string | ((_: MessageContext) => ReactNode), lifetime?: number, truncate?: Truncate}) => void
  warning: (_: {title?: string, message: string | ((_: MessageContext) => ReactNode), lifetime?: number, truncate?: Truncate}) => void
  success: (_: {title?: string, message: string | ((_: MessageContext) => ReactNode), lifetime?: number, truncate?: Truncate}) => void
  info: (_: {title?: string, message: string | ((_: MessageContext) => ReactNode), lifetime?: number, truncate?: Truncate}) => void
  // push: (message: string, type: ToastMessageType, lifetime?: number, truncate?: Truncate) => void
  // custom: (message: string | ReactNode,lifetime: number,truncate?: Truncate,icon?: ReactNode) => void
  remove: (id: string) => void
};

/////////////////////////////////////
/// Global and Helpers
/////////////////////////////////////

export const ToastContext = createContext<ToastContextType | undefined>(undefined);

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const useToast = () => useContext(ToastContext) as ToastContextType;

const DEFAULT_INTERVAL = 2500;

/////////////////////////////////////
/// Implementation
/////////////////////////////////////

interface PushType {
  title?: string,
  message: string | ((_: MessageContext) => ReactNode),
  type?: ToastMessageType,
  lifetime?: number,
  truncate?: Truncate
}

export default function ToastProvider({ children, variant }: ToastProviderProps) {
  const [data, setData] = useState<Array<Toast>>([])

  const Push = useCallback(({message, type, title, lifetime, truncate}: PushType) => {
    if (message) {
      const new_item: Toast = {
        id: uuidv4(),
        message,
        type,
        title,
        lifetime: lifetime ? lifetime : DEFAULT_INTERVAL,
        truncate: truncate,
      }

      setData((prevState) => [...prevState, new_item])
    }
  }, [setData])

  // const PushCustom = useCallback((message: string | React.ReactNode, lifetime?: number, truncate?: Truncate) => {
  //     if (message) {
  //         const new_item: Toast = {
  //             id: uuidv4(),
  //             message: message,
  //             lifetime: lifetime ? lifetime : DEFAULT_INTERVAL,
  //             truncate: truncate,
  //             type: undefined,
  //         }

  //         setData((prevState) => [...prevState, new_item]);
  //     }
  // }, [setData, data])

  const PushError = useCallback(({ message, title, lifetime, truncate }: PushType) => Push({message, title, type: 'error', lifetime, truncate}), [Push])
  const PushWarning = useCallback(({ message, title, lifetime, truncate }: PushType) => Push({message, title, type: 'warning', lifetime, truncate}), [Push])
  const PushSuccess = useCallback(({ message, title,  lifetime, truncate }: PushType) => Push({message, title, type: 'success', lifetime, truncate}), [Push])
  const PushInfo = useCallback(({ message, title, lifetime, truncate }: PushType) => Push({message, title, type: 'info', lifetime, truncate}), [Push])

  const ToastContexd = useCallback(() => {
    return {
      data: data,
      error: PushError,
      warning: PushWarning,
      success: PushSuccess,
      info: PushInfo,
      // push: Push,
      // custom: PushCustom,
      async remove(id: string) {
        setData((prevState) => prevState.filter((e) => e.id !== id));
      },
    }
    // PushCustom, Push
  }, [data, setData, PushError, PushWarning, PushSuccess, PushInfo]);

  return (
    <ToastContext.Provider value={ToastContexd()}>
      <ToastContainer variant={variant} />
      {children}
    </ToastContext.Provider>
  );
}
