import { useRef, useEffect, useState, FunctionComponent } from 'react'
import { createPortal } from 'react-dom'

const Portal: FunctionComponent = ({ children }) => {
  const ref = useRef<HTMLDivElement>()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector('#title_header') as HTMLDivElement
    setMounted(true)
  }, [])

  return mounted && ref.current ? createPortal((
    <h1 className="text-2xl text-gray-800 dark:text-gray-200">
      {children}
    </h1>
  ), ref.current) : null
}

export default Portal