import { useEffect, useState } from "react"
import { Preferences } from "~/api/models"
import { useRepository, useToast } from "~/components/hooks"
import { useSession } from "~/context/session"
import { Button, Logo, Switch } from '~/components/ui'

const translations = {
  allow_send_birthday_proximity_notification: {
    title: 'Permitir enviar notificación de proximidad de cumpleaños.',
    hint: 'Notificará con dos dias de anticipación cuando tu cumpleaños se acerque.'
  },
  allow_receive_birthday_proximity_notification: {
    title: 'Recibir notificaciones de cumpleaños de otros.',
    hint: 'Te permitirá recibir con dos dias de anticipación y el día en cuestion que tus compañeros cumplan años.'
  },
  allow_receive_paysheet_creation_notification: {
    title: 'Recibir notificaciones de pago de nómina.',
    hint: 'Cada vez que se registré un pago de nómina a tu cuenta serás notificado.'
  },
  allow_display_age_on_birthday_notification: {
    title: 'Mostrar tu edad en la notificación de cumpleaños.',
    hint: 'Si desactivas esta opción tu edad se ocultará para todos los empleados.'
  },
}

const PreferencesScreen = () => {

  const fetch = useRepository(({ users }) => users.show)
  const updatePreferences = useRepository(({ users }) => users.updatePreferences)
  const [preferences, setPreferences] = useState<Preferences>({
    allow_send_birthday_proximity_notification: false,
    allow_receive_birthday_proximity_notification: false,
    allow_receive_paysheet_creation_notification: false,
    allow_display_age_on_birthday_notification: false
  })
  const toast = useToast()
  const { session } = useSession()
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [loadingIndex, updateLoadingIndex] = useState(false)

  useEffect(() => {
    updateLoadingIndex(prev => !prev)
    fetch(session!.id, { with: 'preferences' })
      .then(({ preferences }) => {
        setPreferences(preferences!)
      })
      .finally(() => updateLoadingIndex(prev => !prev))
  }, []) // eslint-disable-line

  const update = () => {
    setLoadingUpdate(prev => !prev)
    updatePreferences(session!.id, preferences)
      .then(() => {
        toast.success({
          title: 'Actualización de preferencias',
          message: 'Sus datos han sido guardados con exito'
        })
      })
      .finally(() => setLoadingUpdate(prev => !prev))
  }

  return (
    <div className="space-y-4">
      <div className="relative">
        <ul>
          {(Object.entries(preferences) as [keyof typeof translations, boolean][]).map(([attribute, value], key) => (
            <li key={key} className="p-4 rounded-xl hover:bg-primary hover:bg-opacity-10">
              <div className="flex justify-between items-start">
                <div className="flex flex-col">
                  <h1 className="text-lg font-medium">
                    {translations[attribute].title}
                  </h1>
                  <h2 className="text-gray-500">
                  {translations[attribute].hint}
                  </h2>
                </div>

                <Switch value={value} onChange={value => setPreferences(prev => ({...prev, [attribute]: value }))} />
              </div>
            </li>
          ))}
        </ul>
        {loadingIndex && (
          <div className="absolute inset-0 bg-white bg-opacity-50 backdrop-blur-lg backdrop-filter flex items-center justify-center flex-col">
            <Logo className="animate-pulse" />
            <h1 className="text-lg animate-pulse">Cargando...</h1>
          </div>
        )}
      </div>
      <div className="flex justify-end">
        <Button variant="primary" onClick={update} loading={loadingUpdate}>
          Actualizar preferencias
        </Button>
      </div>
    </div>
  )
}

export default PreferencesScreen