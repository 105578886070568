class Setting {

    id?: number
    bonus: string | number
    sso: string | number
    lacks: string | number

    constructor(map: Setting) {
        this.id = map.id
        this.bonus = map.bonus ?? 0
        this.sso = map.sso ?? 0
        this.lacks = map.lacks ?? 0
    }
}

export default Setting