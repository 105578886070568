import Account from './Account'

class Income {

    id: number
    account_id: number
    provider: string
    number: number | string
    amount: number | string
    issued_at: string

    account?: Account

    constructor(map: Income) {
        this.id = map.id
        this.account_id = map.account_id
        this.provider = map.provider
        this.number = map.number
        this.amount = map.amount
        this.issued_at = map.issued_at
        this.account = map.account
    }
}

export default Income