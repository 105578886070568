import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Switch, Route, useRouteMatch, NavLink } from 'react-router-dom'
import { useRepository } from '~/components/hooks'
import { Titular } from '~/api/models'
import Accounts from './accounts'
import Paysheets from './paysheets'
import Payments from './payments'
import Update from './update'
import {TitlePortal} from "../../components/ui";

const TitularScreen = () => {

  const show = useRepository(({ titulars }) => titulars.show)
  const [titular, setTitular] = useState<Titular | undefined>(undefined)
  const { id: userID } = useParams<{ id: string }>()
  let { path, url } = useRouteMatch();

  const fetch = () => {
    show(parseInt(userID), { with: 'accounts.bank', withCount: 'paysheets,payments,accounts' })
      .then(setTitular)
  }

  useEffect(() => {
    fetch()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TitlePortal>
        Detalle de titular
      </TitlePortal>
      <div className="flex flex-col w-full p-2">
        <h2 className="text-2xl mb-4 dark:text-gray-200">
          {titular?.name}
        </h2>
        <div className="flex flex-wrap justify-center -mx-2">
          <div className="w-full xl:w-2/6 px-2">
            <div className="bg-white dark:bg-gray-900 rounded-2xl p-2 shadow-md">
              <section className="divide-y dark:divide-gray-800">
                <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                  <b>CI/RIC</b>
                  <span>{titular?.ric}</span>
                </div>
                <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                  <b>Tipo</b>
                  <span>{titular?.type}</span>
                </div>
                <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                  <b>Ambito</b>
                  <span>{titular?.scope}</span>
                </div>
                <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                  <b>Salario</b>
                  <span>{titular?.salary}</span>
                </div>
                <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                  <b>Cuentas bancarias registradas</b>
                  <span>{titular?.accounts_count ?? 0}</span>
                </div>
                <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                  <b>Órdenes de nómina</b>
                  <span>{titular?.paysheets_count ?? 0}</span>
                </div>
                <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                  <b>Órdenes de pago</b>
                  <span>{titular?.payments_count ?? 0}</span>
                </div>
              </section>
            </div>
          </div>

          <div className="w-full xl:w-4/6 px-2 mt-4 xl:mt-0">
            <ul className="flex space-x-2 mb-2">
              <li className="float-left dark:text-gray-200">
                <NavLink exact activeClassName="bg-blue-500 text-white bg-opacity-75" className="py-2 px-4 hover:text-white hover:bg-blue-500 block rounded-lg" to={`${url}`}>
                  Actualizar
                </NavLink>
              </li>
              <li className="float-left dark:text-gray-200">
                <NavLink exact activeClassName="bg-blue-500 text-white bg-opacity-75" className="py-2 px-4 hover:text-white hover:bg-blue-500 block rounded-lg" to={`${url}/accounts`}>
                  Cuentas
                </NavLink>
              </li>
              <li className="float-left dark:text-gray-200">
                <NavLink exact activeClassName="bg-blue-500 text-white bg-opacity-75" className="py-2 px-4 hover:text-white hover:bg-blue-500 block rounded-lg" to={`${url}/paysheets`}>
                  Nominas
                </NavLink>
              </li>
              <li className="float-left dark:text-gray-200">
                <NavLink exact activeClassName="bg-blue-500 text-white bg-opacity-75" className="py-2 px-4 hover:text-white hover:bg-blue-500 block rounded-lg" to={`${url}/payments`}>
                  Pagos
                </NavLink>
              </li>
            </ul>

            <Switch>
              <Route exact path={path}>
                <Update titular={titular} onUpdate={setTitular} />
              </Route>
              <Route exact path={`${path}/accounts`}>
                <Accounts titular={titular} />
              </Route>
              <Route path={`${path}/paysheets`}>
                <Paysheets titular={titular} />
              </Route>
              <Route path={`${path}/payments`}>
                <Payments titular={titular} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </>
  )
}

export default TitularScreen
