import RepositoryContext from '../contracts/RepositoryContext';
import { AxiosResponse } from 'axios'
import { Announcement } from '../models'
import { AnnouncementTarget, AnnouncementType } from '../models/Announcement';

export interface ICreateAnnouncementBody {
  title: string,
  content: string,
  date?: String
  type: AnnouncementType
  target: AnnouncementTarget
}

export interface IAnnouncementEndpointsContext {
  index: <T>(query: object) => Promise<T>
  show: (announcement: number) => Promise<Announcement>
  store: (_: ICreateAnnouncementBody) => Promise<Announcement>
  fire: (_: Announcement) => Promise<Announcement>
  remove: (announcement: number) => Promise<Announcement>
}

const endpoints = ({ axios, mapQueryString }: RepositoryContext): IAnnouncementEndpointsContext => ({
  index: <T> (query = {}) => axios.get(`/api/announcements${mapQueryString(query)}`).then(({ data }: AxiosResponse<T>) => data as T),
  show: (announcement: number) => axios.get(`/api/announcements/${announcement}`).then(({ data }: AxiosResponse<Announcement>) => data as Announcement),
  store: (body: ICreateAnnouncementBody) => axios.post('/api/announcements', body).then(({ data }: AxiosResponse<Announcement>) => data as Announcement),
  fire: (body: Announcement) => axios.post(`/api/announcements/${body.id}/fire`, body).then(({ data }: AxiosResponse<Announcement>) => data as Announcement),
  remove: (announcement: number) => axios.delete(`/api/announcements/${announcement}`).then(({ data }) => data as Announcement)
})


export default endpoints