import {Role} from "~/constants";
import AccountsScreen from "~/screens/accounts";
import AccountScreen from "~/screens/accounts/show";
import {PrivateRoute} from "~/Application";
import {Switch} from "react-router-dom";

const AccountsRouter = () => (
  <Switch>
    <PrivateRoute exact allows={[Role.admin, Role.developer]} path="/accounts">
      <AccountsScreen />
    </PrivateRoute>
    
    <PrivateRoute allows={[Role.admin, Role.developer]} path="/accounts/:id">
      <AccountScreen />
    </PrivateRoute>
  </Switch>
)

export default AccountsRouter