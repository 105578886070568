import {Setting, Titular} from "~/api/models";
import {useEffect, useState} from "react";
import {TitlePortal} from "~/components/ui";
import {useBreadcrumbs} from "~/context/breadcrumb";
import BasicDataStep, { IBasePaysheetForm } from "~/screens/paysheets/components/BaseManyForm";
import EmployeeSelectionStep from "~/screens/paysheets/components/EmployeeSelectionStep";
import FormsStep from "~/screens/paysheets/components/FormsStep";
import { useRepository } from "~/components/hooks";

enum Step {
  employeesSelection,
  basicData,
  formsGeneration
}

const CreateMany = () => {
  const [step, setStep] = useState<Step>(Step.employeesSelection)
  const [employees, setEmployees] = useState<Titular[]>([])
  const { setBreadcrumb } = useBreadcrumbs()
  const [base, setBase] = useState<IBasePaysheetForm>({})
  const fetchSettings = useRepository(({ settings }) => settings.show)
  const [settings, setSettings] = useState<Setting>({ sso: 0, bonus: 0, lacks: 0 })

  useEffect(() => {
    setBreadcrumb([
      { title: 'Órdenes de nómina', to: '/paysheets' },
      { title: 'Crear órden', to: '/paysheets/create' },
      { title: 'Multiple', to: '/paysheets/create/many' },
    ])

    fetchSettings()
      .then(settings => {
        setSettings(settings)

      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TitlePortal>
        Crear multiples órdenes
      </TitlePortal>
      {(() => {
        if (step === Step.employeesSelection) {
          return <EmployeeSelectionStep
            defaultSelected={employees.map(employee => employee.id)}
            onNext={employees => {
            setStep(Step.basicData)
            setEmployees(employees)
          }} />
        }

        if (step === Step.basicData) {
          return <BasicDataStep
            onBack={() => setStep(Step.employeesSelection)}
            onNext={base => {
              setStep(Step.formsGeneration)
              setBase(base)
            }} />
        }

        return <FormsStep settings={settings} employees={employees} base={base} onCreate={() => {
          setStep(Step.employeesSelection)
          setEmployees([])
          setBase({})
        }} />
      })()}
    </>
  )
}

export default CreateMany
