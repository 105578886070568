import User from "./User"

class Session {
    user: User
    access_token: string
    expires_in: number
    token_type: string

    constructor(map: Session) {
        this.access_token = map.access_token
        this.expires_in = map.expires_in
        this.token_type = map.token_type
        this.user = new User(map.user)
    }
}

export default Session