import {useState, useRef, useEffect, ReactNode, FunctionComponent, Fragment} from 'react'
import { AiOutlineEllipsis } from 'react-icons/ai'
import { Transition } from "@headlessui/react";
import classnames from "classnames";

interface IDropdown {
  bottom?: boolean,
  right?: boolean,
  width?: 'w-48' | 'w-96'
  children?: ReactNode
  label?: (context: { show: boolean }) => ReactNode
}

const Dropdown: FunctionComponent<IDropdown> = ({ children, bottom = false, right = false, width = 'w-48', label }) => {
  const [show, setShow] = useState(false)
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (!container.current?.contains(event.target as Node)) {
        if (!show) return
        setShow(false)
      }
    }

    window.addEventListener('click', handleOutsideClick)

    return () => window.removeEventListener('click', handleOutsideClick)
  }, [show, container])

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!show) return

      if (event.key === 'Escape') {
        setShow(false)
      }
    }

    document.addEventListener('keyup', handleEscape);
    return () => document.removeEventListener('keyup', handleEscape);
  }, [show]);

  const handleClick = () => {
    setShow(!show)
  }

  return (
    <div ref={container} className="relative inline-block">
      <button className="focus:outline-none active:scale-95 transform transition-transform group" onClick={handleClick}>
        {label?.({ show }) ?? (
          <span className="inline-flex h-8 w-8 bg-white border rounded-full items-center justify-center">
          <AiOutlineEllipsis className={classnames('text-xl transition-transform transform', {
            'rotate-90': !show
          })} />
        </span>
        )}
      </button>

      <Transition
        show={show}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
          <div className={["absolute mt-1 border bg-white dark:bg-gray-900 dark:border-gray-800 rounded-xl overflow-hidden shadow-md z-10", bottom && 'bottom-full', right ? 'right-0' : 'left-0', width].filter($0 => $0).join(' ')}>
            {children}
          </div>
      </Transition>
    </div>
  );
};

export default Dropdown
