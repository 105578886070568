import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Switch, Route, useRouteMatch, NavLink, Link } from 'react-router-dom'
import { useRepository } from '~/components/hooks'
import { useBreadcrumbs } from '~/context/breadcrumb';
import { Account } from '~/api/models'
import Paysheets from './paysheets'
import Payments from './payments'
import Update from './update'
import {TitlePortal} from "../../components/ui";

const TitularScreen = () => {

  const { setBreadcrumb } = useBreadcrumbs()
  const show = useRepository(({ accounts }) => accounts.show)
  const [account, setAccount] = useState<Account | undefined>(undefined)
  const { id } = useParams<{ id: string }>()
  let { path, url } = useRouteMatch();

  const fetch = () => {
    show(parseInt(id), { with: 'bank,titular', withCount: 'paysheets,payments' })
      .then(setAccount)
  }

  useEffect(() => {
    setBreadcrumb([
      { title: 'Listado de cuentas', to: '/accounts', loading: false },
      { title: account?.number ?? '', to: `/accounts/${account?.id}`, loading: !account },
    ])
  }, [account]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetch()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TitlePortal>
        Detalle de número de cuenta
      </TitlePortal>
      <div className="flex flex-col w-full p-2">
        <h2 className="text-2xl dark:text-gray-200 mb-4">
          {account?.number}
        </h2>
        <div className="flex flex-wrap justify-center -mx-2">
          <div className="w-full lg:w-3/5 xl:w-2/6 px-2">
            <div className="bg-white dark:bg-gray-900 rounded-2xl p-2 shadow-md">
              <section className="divide-y dark:divide-gray-800">
                <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                  <b>Banco</b>
                  <span className="">{account?.bank?.name ?? '-- Sin banco --'}</span>
                </div>
                <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                  <b>Tipo</b>
                  <span className="">{account?.type}</span>
                </div>
                <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                  <b>Titular</b>
                  {account?.titular
                    ? (<Link className="text-blue-400" to={`/titulars/${account.titular.id}`}>{account?.titular?.name}</Link>)
                    : (<span className="">-- Sin titular --</span>)}
                </div>
                <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                  <b>Captital</b>
                  <span className="">{account?.capital}</span>
                </div>

                <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                  <b>Órdenes de nómina</b>
                  <span className="">{account?.paysheets_count ?? 0}</span>
                </div>
                <div className="flex flex-wrap p-2 justify-between dark:text-gray-200">
                  <b>Órdenes de pago</b>
                  <span className="">{account?.payments_count ?? 0}</span>
                </div>
              </section>
            </div>
          </div>

          <div className="w-full xl:w-4/6 px-2 mt-4 xl:mt-0">
            <ul className="flex space-x-2 mb-2">
              <li className="float-left dark:text-gray-200">
                <NavLink exact activeClassName="bg-blue-500  text-white bg-opacity-75" className="py-2 px-4 hover:text-white   hover:bg-blue-500 block rounded-lg" to={`${url}`}>
                  Actualizar
                </NavLink>
              </li>
              <li className="float-left dark:text-gray-200">
                <NavLink exact activeClassName="bg-blue-500  text-white bg-opacity-75" className="py-2 px-4 hover:text-white   hover:bg-blue-500 block rounded-lg" to={`${url}/paysheets`}>
                  Nominas
                </NavLink>
              </li>
              <li className="float-left dark:text-gray-200">
                <NavLink exact activeClassName="bg-blue-500  text-white bg-opacity-75" className="py-2 px-4 hover:text-white   hover:bg-blue-500 block rounded-lg" to={`${url}/payments`}>
                  Pagos
                </NavLink>
              </li>
            </ul>

            <Switch>
              <Route exact path={path}>
                <Update account={account} onUpdate={setAccount} />
              </Route>
              <Route path={`${path}/paysheets`}>
                <Paysheets />
              </Route>
              <Route path={`${path}/payments`}>
                <Payments />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </>
  )
}

export default TitularScreen
