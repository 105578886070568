// import {AreaChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Area} from 'recharts';
import {TitlePortal} from "~/components/ui";
import {useEffect, useState} from "react";
// import {useRepository} from "~/components/hooks";

// const data = [
//   {
//     "name": "Page A",
//     "algo": 4000,
//     "some": 2400,
//     "amt": 2400
//   },
//   {
//     "name": "Page B",
//     "algo": 3000,
//     "some": 1398,
//     "amt": 2210
//   },
//   {
//     "name": "Page C",
//     "algo": 2000,
//     "some": 9800,
//     "amt": 2290
//   },
//   {
//     "name": "Page D",
//     "algo": 2780,
//     "some": 3908,
//     "amt": 2000
//   },
//   {
//     "name": "Page E",
//     "algo": 1890,
//     "some": 4800,
//     "amt": 2181
//   },
//   {
//     "name": "Page F",
//     "algo": 2390,
//     "some": 3800,
//     "amt": 2500
//   },
//   {
//     "name": "Page G",
//     "algo": 3490,
//     "some": 4300,
//     "amt": 2100
//   }
// ]

const Dashboard = () => {

  const [_, setWidth] = useState(800) // eslint-disable-line

  useEffect(() => {
    const handleResize = (event: any) => {
      setWidth(event.target.innerWidth - (document.querySelector('body aside')?.clientWidth ?? 0) - 20)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setWidth(window.innerWidth - (document.querySelector('body aside')?.clientWidth ?? 0) - 20)
  }, [])

  return(
    <>
      <TitlePortal>
        Dashboard
      </TitlePortal>
      <div className="flex flex-col p-4 space-y-4">
        <h1 className="text-4xl text-gray-700">
          Registros del año {new Date().getFullYear()}
        </h1>
        <div className="grid grid-cols-4 gap-4">
          <div className="bg-white rounded-xl border border-gray-50 shadow-lg p-4">
            <h1 className="text-lg">Órdenes de nómina</h1>
          </div>
          <div className="bg-white rounded-xl border border-gray-50 shadow-lg p-4">
            <h1 className="text-lg">Órdenes de pago</h1>
          </div>
          <div className="bg-white rounded-xl border border-gray-50 shadow-lg p-4">
            <h1 className="text-lg">Ingresos</h1>
          </div>
          <div className="bg-white rounded-xl border border-gray-50 shadow-lg p-4">
            <h1 className="text-lg">Egresos</h1>
          </div>
        </div>
      </div>
      <div>
        {/*<AreaChart width={width} height={400} data={data} margin={{top: 5, right: 20, bottom: 5, left: 0}}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area type="monotone" dataKey="algo" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
          <Area type="monotone" dataKey="some" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
        </AreaChart>*/}
      </div>
    </>
  )
}

export default Dashboard
