import { useState, FormEvent } from 'react';
import { Modal, Input, Select } from '~/components/ui'
import { useRepository, useError } from '~/components/hooks'
import { Titular } from '~/api/models';

interface ICreateTitularModalProps {
    show: boolean,
    onCreated: (_: Titular) => void
    onCancel: () => void
    onError?: () => void
    onFinish?: () => void
}

const CreateTitularModal = ({ show, onCancel, onCreated, onError, onFinish }: ICreateTitularModalProps) => {

    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({ name: '', scope: '', type: '', ric: '' })
    const { error, setErrors } = useError()
    const create = useRepository(({ titulars }) => titulars.create)

    const handleCreate = (event?: FormEvent) => {
        event?.preventDefault()
        setLoading(prev => !prev)
        create(state)
            .then(onCreated)
            .catch(errors => {
                setErrors(errors)
                onError?.()
            })
            .finally(() => {
                setLoading(prev => !prev)
                onFinish?.()
            })
    }

    return (
        <Modal
            show={show}
            title="Crear titular"
            confirmText={loading ? 'Procesando...' : 'Guardar'}
            onConfirm={handleCreate}
            onCancel={onCancel}>
            <form className="flex flex-col" onSubmit={handleCreate}>
                <Input
                    className="w-full"
                    id="name"
                    label="Nombre"
                    value={state.name}
                    error={error('name')}
                    onChange={name => setState(prev => ({...prev, name}))} />

                <Input
                    className="w-full mt-4"
                    id="ciric"
                    label="CI/RIC"
                    value={state.ric}
                    error={error('ric')}
                    onChange={ric => setState(prev => ({...prev, ric}))} />

                <Select
                    className="w-full mt-4"
                    id="type"
                    label="Tipo"
                    value={state.type}
                    error={error('type')}
                    onChange={type => setState(prev => ({...prev, type: type as string }))}>
                    <option value="">-- Tipo --</option>
                    <option value="Natural">Natural</option>
                    <option value="Jurídico">Jurídico</option>
                </Select>

                <Select
                    className="w-full mt-4"
                    id="scope"
                    label="Ámbito"
                    value={state.scope}
                    error={error('scope')}
                    onChange={scope => setState(prev => ({...prev, scope: scope as string }))}>
                    <option value="">-- Ámbito --</option>
                    <option value="Externo">Externo</option>
                    <option value="Empresa">Empresa</option>
                </Select>
            </form>
        </Modal>
    )
}

export default CreateTitularModal
