import {FunctionComponent} from "react";

interface ILogoProps {
  size?: string
  color?: string
  secondaryColor?: string
  dot?: string
  className?: string
}

const Logo: FunctionComponent<ILogoProps> = ({ size = '90px', color = '#000', secondaryColor = '#000', dot = '#00CCFF', className }) => (
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={className} width={size} height={size} viewBox="0 0 512 512" enableBackground="new 0 0 512 512">
    <g>
      <path fill={color} d="M238.556,149.305c-1.396-1.388-2.978-2.087-4.745-2.087h-37.958c-1.772,0-3.417,0.699-4.935,2.087 c-1.518,1.395-2.277,3.103-2.277,5.125v168.032c0,1.772,0.695,3.358,2.087,4.745c1.386,1.393,3.094,2.089,5.125,2.089h37.958 c2.021,0,3.665-0.696,4.935-2.089c1.264-1.387,1.899-2.973,1.899-4.745V154.431C240.645,152.408,239.942,150.7,238.556,149.305z" />
      <path fill={color} d="M175.531,237.858c2.697,6.438,4.168,13.342,5.34,20.289c0.175,1.404,0.31,2.797,0.408,4.171 c0.057,1.261,0.084,2.548,0.084,3.864c0,3.638-0.929,10.515-0.929,10.515l-0.716,3.337c-1.523,8.949-8.264,21.181-15.391,29.375 c-1.018,1.172-2.093,2.304-3.236,3.39c-2.44,2.232-5.083,4.143-7.87,5.787c-2.788,1.647-5.72,3.022-8.749,4.184 c-8.939,4.229-23.137,6.674-30.669,7.175c-2.853,0.33-11.155,0.229-14.041,0.066c-8.66-0.487-16.539-3.055-18.394-12.329 c-0.993-7.631,0.414-23.712,0.977-25.275c0.563-1.562,1.28-3.089,2.163-4.561c0.982-1.375,2.037-2.479,3.153-3.355 c1.118-0.881,2.297-1.536,3.527-2.012c2.747-0.81,5.573-1.019,8.42-0.954c1.425,0.033,20.146,0.528,21.291,0.123 c3.708-0.81,6.26-1.89,8.521-7.79c3.768-9.566-4.083-20.56-12.853-22.322c-1.27-0.636-11.83-4.58-15.679-6.527 c-1.282-0.65-30.847-12.587-37.133-16.053c-1.257-0.693-2.496-1.417-3.711-2.175c-7.284-5.018-13.698-11.193-18.246-17.999 c-0.911-1.361-1.745-2.748-2.497-4.155c-0.434-1.178-0.888-2.355-1.346-3.538c-0.46-1.182-0.923-2.369-1.371-3.565 c-0.39-1.487-0.773-2.984-1.138-4.487c-0.365-1.501-0.712-3.007-1.034-4.516c-2.065-13.652-2.064-25.948,4.476-38.899 c9.555-16.083,16.578-20.807,27.173-26.937c10.597-6.125,22.748-9.917,36.459-11.375c9.972-0.414,19.733,0.21,29.292,1.871 c7.96,1.387,15.059,3.349,21.293,5.886c1.246,0.509,2.458,1.038,3.636,1.592c7.851,3.875,17.273,8.586,20.414,16.438 c2.404,10.821-1.635,21.641-8.594,28.937c-0.994,1.043-2.047,2.014-3.15,2.905c-4.015,3.167-8.379,4.112-12.886,3.724 c-1.503-0.13-3.021-0.407-4.547-0.801c-3.272-0.672-6.331-1.774-9.271-3.081c-1.47-0.652-2.912-1.355-4.343-2.083 c-6.085-3.807-22.453-8.539-33.887-7.087c-12.831,2.611-15.972,19.885-4.278,28.097c1.029,0.749,2.103,1.44,3.191,2.076 c1.089,0.635,25.449,11.708,37.567,17.392c12.118,5.683,20.424,9.782,26.348,14.417 C169.231,226.222,175.531,237.858,175.531,237.858z" />
      <path fill={color} d="M484.436,119.368c-1.313-1.228-2.884-1.849-4.714-1.849h-39.673c-1.313,0-2.355,0.394-3.144,1.176 c-0.785,0.79-1.57,1.739-2.355,2.858l-59.313,83.338c-0.264,0.452-0.92,1.236-1.962,2.352c-1.053,1.125-2.494,1.681-4.322,1.681 h-0.394c-1.833,0-3.339-0.445-4.518-1.344c-1.178-0.894-1.903-1.564-2.161-2.015l-59.704-84.35 c-0.526-0.892-1.377-1.731-2.554-2.521c-1.179-0.782-2.159-1.176-2.945-1.176h-39.282c-1.835,0-3.473,0.621-4.908,1.849 c-1.443,1.234-2.161,2.747-2.161,4.536v142.36c0,1.574,0.718,2.973,2.161,4.199c1.435,1.236,3.073,1.851,4.908,1.851h38.494 c1.831,0,3.401-0.556,4.714-1.682c1.31-1.115,1.967-2.57,1.967-4.368v-53.979c0-2.018,0.521-3.024,1.569-3.024 c1.307,0,2.485,1.006,3.537,3.024l42.816,51.417c2.091,2.238,3.986,3.695,5.695,4.367c1.698,0.672,4.91,1.008,9.621,1.008h6.677 c3.929,0,6.741-0.446,8.446-1.342c1.699-0.894,3.861-2.801,6.48-5.715c4.451-5.152,11.455-13.439,21.016-24.867 c9.557-11.425,16.431-19.827,20.623-25.205c1.57-1.564,2.751-2.352,3.533-2.352c1.045,0,1.573,1.01,1.573,3.024v118.943 c0,1.794,0.65,3.251,1.963,4.37c1.31,1.122,2.88,1.682,4.714,1.682h38.888c1.83,0,3.401-0.56,4.714-1.682 c1.306-1.119,1.963-2.576,1.963-4.37V123.904C486.398,122.115,485.741,120.603,484.436,119.368z" />
      <path fill={dot} d="M49.849,271.273c-30.381,0-30.381,47.11,0,47.11C80.229,318.384,80.229,271.273,49.849,271.273z" />
      <path fill={secondaryColor} d="M42.355,402.28l-16.106-39.014h6.065l13.189,31.948l12.769-31.948h5.275L48.068,402.28H42.355z" />
      <path fill={secondaryColor} d="M86.002,402.28v-39.014h23.895v4.14H92.055v12.521h14.959v4.086H92.055v14.128h19.08v4.14H86.002z" />
      <path fill={secondaryColor} d="M134.701,402.28v-39.014h5.964l21.39,30.104v-30.104h5.216v39.014h-5.934l-21.418-30.092v30.092H134.701z" />
      <path fill={secondaryColor} d="M195.016,402.28v-39.014h23.895v4.14h-17.842v12.521h14.959v4.086h-14.959v14.128h19.081v4.14H195.016z" />
      <path fill={secondaryColor} d="M240.948,402.28v-4.43l22.911-30.444h-21.499v-4.14h28.765v4.14l-22.914,30.444h22.914v4.43H240.948z" />
      <path fill={secondaryColor} d="M295.757,363.267h6.055v24.621c0,4.288,0.874,7.231,2.622,8.829c1.748,1.601,4.189,2.399,7.32,2.399 c1.979,0,3.65-0.312,5.016-0.936c1.364-0.624,2.423-1.638,3.172-3.045c0.747-1.405,1.125-3.759,1.125-7.063v-24.806h5.303v24.727 c0,4.234-0.573,7.287-1.714,9.16c-1.145,1.871-2.758,3.357-4.843,4.454c-2.087,1.1-4.851,1.647-8.287,1.647 c-3.672,0-6.641-0.569-8.907-1.712c-2.267-1.144-3.979-2.707-5.131-4.693c-1.153-1.986-1.73-4.99-1.73-9.016V363.267z" />
      <path fill={secondaryColor} d="M353.709,402.28v-39.014h23.896v4.14h-17.842v12.521h14.959v4.086h-14.959v14.128h19.08v4.14H353.709z" />
      <path fill={secondaryColor} d="M402.407,402.28v-39.014h6.054v34.874h18.937v4.14H402.407z" />
      <path fill={secondaryColor} d="M445.573,402.28l16.944-39.014h5.975l16.682,39.014h-6.542l-4.655-10.807h-18.098l-4.685,10.807H445.573z M457.673,387.334 h14.522l-7.239-16.8L457.673,387.334z" />
    </g>
  </svg>
)

export default Logo
