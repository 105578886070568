import RepositoryContext from '../contracts/RepositoryContext';
import { AxiosResponse } from 'axios'
import { Setting } from '../models'

export interface ISettingEndpointsContext {
    show: () => Promise<Setting>,
    update: (_: Setting) => Promise<Setting>
}

const endpoints = ({ axios }: RepositoryContext): ISettingEndpointsContext => ({
    show: () => axios.get(`/api/settings`).then(({ data }: AxiosResponse<Setting>) => new Setting(data)),
    update: (body: Setting) => axios.put('/api/settings', body).then(({ data }: AxiosResponse<Setting>) => new Setting(data))
})


export default endpoints