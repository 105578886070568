import {BottomSheetModal, Button, CircleLoader, Dropdown, DropdownItem} from "~/components/ui";
import {Titular, User} from "~/api/models";
import {useCallback, useEffect, useState} from "react";
import {useRepository, useToast} from "~/components/hooks";
import {RoleType} from "~/api/models/User";

interface IBoundUsersModal {
  bounding?: User,
  onClose: () => void,
  onBound: (user: User) => void
}

const RoleMap = {
  [RoleType.admin]: 'Administrador',
  [RoleType.developer]: 'Desarrollador',
  [RoleType.employee]: 'Empleado',
}

const TitularRow = ({ titular, bounding, onBound }: { titular: Titular, bounding: User, onBound: (user: User) => void }) => {

  const toast = useToast()

  const {bind, unbound: unboundMethod} = useRepository(({ users }) => ({
    bind: users.bind,
    unbound: users.unbound
  }))

  const confirm = (role: RoleType, close: () => void) => {
    close()
    bind(bounding.id, {
      type: role,
      titular_id: titular.id
    })
    .then(onBound)
  }

  const prepareToRemove = (userId: number) => {
    toast.warning({
      title: `¿Seguro que desea remover este vínculo??`,
      message: ({ close }) => (
        <div className="flex flex-col space-y-2 items-start">
          <p>Siempre puedes volver a vincularlo</p>
          <Button variant="default" onClick={() => unbound(userId, close)}>
            Aceptar
          </Button>
        </div>
      ),
      lifetime: -1
    })
  }

  const unbound = (userId: number, close: () => void) => {
    close()
    unboundMethod(userId)
      .then(onBound)
  }

  const select = (role: RoleType) => {
    toast.info({
      title: `¿Seguro que desea seleccionar este titular como ${RoleMap[role]}?`,
      message: ({ close }) => (
        <div className="flex flex-col space-y-2 items-start">
          <p>Esta acción le dara acceso al administrador</p>
          <Button variant="default" onClick={() => confirm(role, close)}>
            Aceptar
          </Button>
        </div>
      ),
      lifetime: -1
    })
  }

  return (
    <div className="p-4 flex justify-between items-center rounded-lg hover:bg-primary hover:bg-opacity-10">
      <div className="flex flex-col">
        <h1 className="text-xl">
          {titular.name}
        </h1>
        {titular.user && (
          <h2 className="text text-gray-400">
            Enlazado a {titular.user.name} - {RoleMap[titular.user.type]}
          </h2>
        )}
      </div>
      <div className="space-x-2">
        {((current: Titular, user: User) => {

          if (current.user_id) {
            return (
              <Button variant="dangerous" onClick={() => prepareToRemove(current.user_id!)}>
                Remover
              </Button>
            )
          }

          if (user.is_bound) {
            if (current.user_id === user.id) {
              return (
                <Button variant="dangerous" onClick={() => prepareToRemove(user.id)}>
                  Remover
                </Button>
              )
            }

            return <span />
          }
          
          return (
            <Dropdown right label={() => (
              <Button variant="default">
                Seleccionar
              </Button>
            )}>
              <DropdownItem onClick={() => select(RoleType.admin)}>
                Administrador
              </DropdownItem>
              <DropdownItem onClick={() => select(RoleType.developer)}>
                Desarrollador
              </DropdownItem>
              <DropdownItem onClick={() => select(RoleType.employee)}>
                Empleado
              </DropdownItem>
            </Dropdown>
          )
        })(titular, bounding)}
      </div>
    </div>
  )
}

const BoundUsersModal = ({ bounding, onClose, onBound }: IBoundUsersModal) => {

  const [titulars, setUsers] = useState<Titular[]>([])
  const [loading, setLoading] = useState(false)
  const fetchTitulars = useRepository(({ titulars }) => titulars.index)
  const fetch = useCallback(fetchTitulars, [fetchTitulars])

  const handleFetch = (mounted: boolean) => {
    if (bounding !== undefined) {
      setLoading(prev => !prev)
      fetch<Titular[]>({ limit: 0, scope: 'Empleado', with: 'user', sort_by: 'name,asc' })
        .then(titulars => {
          if (mounted) {
            setUsers(titulars)
          }
        })
        .finally(() => setLoading(prev => !prev))
    }
  }

  useEffect(() => {
    let mounted = true

    handleFetch(mounted)

    return () => {
      mounted = false
    }
  }, [bounding]) // eslint-disable-line

  return (
    <BottomSheetModal
      show={bounding !== undefined}
      onClose={onClose}
      title={`Seleccione el empleado relacionado a ${bounding?.name}`}>
      <div className="space-y-2 relative w-full">
        {loading && (
          <div className="absolute inset-0 flex bg-white z-10 bg-opacity-50 backdrop-filter backdrop-blur-lg items-start pt-32 justify-center">
            <CircleLoader className="w-10 h-10" />
          </div>
        )}
        {titulars.map((titular, key) => (
          <TitularRow titular={titular} key={key} bounding={bounding!} onBound={(user) => {
            handleFetch(true)
            onBound(user)
          }} />
        ))}
      </div>
    </BottomSheetModal>
  )
}

export default BoundUsersModal