import { useState } from 'react'

export interface IError {
  value: any,
  clear: () => void
}

export enum HTTPError {
  UNPROCESABLE_ENTITY = 422
}

export type CallbackErrorContext = {
  code: HTTPError
  error: any
}

const useError = () => {

  const [errors, set] = useState({} as any)

  const setErrors = (callback: (context: CallbackErrorContext) => void = () => {}) => (error: any) => {
    if (error?.response?.status === 422) {
      set(error.response.data.errors)
    } else {
      set(error)
    }

    const code = error?.response?.status as HTTPError

    callback({ code, error })
  }

  // const setErrors = (error: any) => {
  //   if (error?.response?.status === 422) {
  //     set(error.response.data.errors)
  //   } else {
  //     set(error)
  //   }
  //
  //   const code = error?.response?.status as HTTPError
  //
  //   return (callback: (context: CallbackErrorContext) => void) => callback({ code, error })
  // }

  const has = (name: string) => errors.hasOwnProperty(name)

  const error = (name: string): IError => ({
    value: errors[name]?.[0] ?? null,
    clear: () => set((e: any) => {
      const object = {...e}
      delete object[name]
      return object
    })
  })

  return { setErrors, errors, has, error }
}

export default useError