import { useState, useEffect } from 'react';
import { Modal, Select } from '~/components/ui'
import { useRepository, useToast } from '~/components/hooks'
import { Account, Titular } from '~/api/models';

interface IAssignModalProps {
    show: boolean,
    account?: Account
    onAssigned: (_: Account) => void
    onCancel: () => void
    onError?: () => void
    onFinish?: () => void
}

const AssignTitularModal = ({ show, account, onCancel, onAssigned, onError, onFinish }: IAssignModalProps) => {

    const toast = useToast()
    const [loadings, setLoadings] = useState({ assign: false, titulars: false })
    const [titulars, setTitulars] = useState<Titular[]>([])
    const [titular, setTitular] = useState<string>()
    const {update, fetchTitulars} = useRepository(({ accounts, titulars }) => ({
        update: accounts.update,
        fetchTitulars: titulars.index
    }))

    useEffect(() => {
        setLoadings(prev => ({...prev, titulars: !prev.titulars}))
        fetchTitulars<Titular[]>({ limit: 0 })
            .then(titulars => setTitulars(titulars.map($0 => new Titular($0))))
            .finally(() => setLoadings(prev => ({...prev, titulars: !prev.titulars})))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal
            show={show}
            title="Asignar titular"
            confirmText={loadings.assign ? 'Procesando...' : 'Guardar'}
            onConfirm={() => {
                update(account!.id, {...account!, titular_id: titular})
                    .then(account => {
                        toast?.success({ message: 'Se ha asignado el titular con exito' })
                        onAssigned(account)
                    })
                    .catch(onError)
                    .finally(onFinish)
            }}
            onCancel={onCancel}>
            <h2 className="text-gray-800 ">
                Esta por asignar el titular {titulars.find($0 => $0.id === parseInt(titular ?? '0', 10))?.name} a la cuenta {account?.number}
            </h2>
            <Select
                className="w-full mt-4"
                loading={loadings.titulars}
                id="titualar_id"
                label="Titular"
                value={titular}
                onChange={value => setTitular(value)}>
                <option value="">-- Titular --</option>
                {titulars.map($0 => <option key={$0.id} value={$0.id}>{$0.name}</option>)}
            </Select>
        </Modal>
    )
}

export default AssignTitularModal
