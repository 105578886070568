import { useEffect, useState } from 'react'
import { useBreadcrumbs } from '~/context/breadcrumb'
import { Button, DatePicker, Input } from '~/components/ui'
import {useSession} from "~/context/session";
import { TelegramLoginButton, TelegramUser } from '~/components/social'
import {useRepository, useToast} from "~/components/hooks";

const AccountConfigurationScreen = () => {

  const { setBreadcrumb } = useBreadcrumbs()
  const { session, updateUser } = useSession()
  const updateMethod = useRepository(({ users }) => users.update)
  const [email, setEmail] = useState(session?.email ?? '')
  const toast = useToast()
  const [passwords, setPasswords] = useState({ password: '', password_confirm: '' })
  const [birth_date, setBirthDate] = useState(session?.birth_date)

  useEffect(() => {
    setBreadcrumb([
      { title: 'Configuraciones', to: '/settings' },
      { title: 'Cuenta', to: '/settings/account' },
    ])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const isPasswordConfirmButtonDisabled = () => {
    return Object.values(passwords)
        .every($0 => $0 === '')
      || passwords.password !== passwords.password_confirm
      || Object.values(passwords)
        .some($0 => $0.length <= 6)
  }

  const associateTelegramId = (user: TelegramUser) => {
    updateMethod(session!.id, { simgulary_bot_id: user.id })
      .then((user) => {
        toast.success({
          title: 'Asociación exitosa',
          message: 'Ahora podrás recibir notificaciones a travez de @simgularybot'
        })

        updateUser(user)
      })
  }

  const updateBirthDate = () => {
    updateMethod(session!.id, { birth_date  })
      .then((user) => {
        toast.success({
          title: 'Asociación exitosa',
          message: 'Ahora podrás recibir notificaciones a travez de @simgularybot'
        })

        updateUser(user)
      })
  }

  return (
    <div className="flex flex-col items-start w-full space-y-4">
      <div className="flex flex-wrap -mx-2 w-full">
        <div className="w-full xl:w-1/2 px-2">
          <h1 className="text-2xl mb-2 dark:text-gray-200">Actualizar correo electrónico</h1>
          <Input
            id="email"
            label="Correo electrónico"
            hint="Correo electrónico para iniciar sesión."
            value={email}
            onChange={setEmail} />

          <Button className="mt-2" variant="default">
            Confirmar
          </Button>
        </div>
        <div className="w-full xl:w-1/2 px-2">
          <h1 className="text-2xl mb-2 dark:text-gray-200">Actualizar contraseña</h1>
          <Input
            id="password"
            label="Contraseña"
            hint="Actualiza este campo solo para cambiar tu contraseña."
            value={passwords.password}
            onChange={password => setPasswords(prev => ({...prev, password}))} />

          <Input
            id="password_confirm"
            className="mt-4"
            label="Confirmación de contraseña"
            hint="Este campo debe coincidir con tu contraseña."
            value={passwords.password_confirm}
            onChange={password_confirm => setPasswords(prev => ({...prev, password_confirm}))} />

          <Button disabled={isPasswordConfirmButtonDisabled()} className="mt-2" variant="default">
            Confirmar
          </Button>
        </div>
        
        <div className="w-full xl:w-1/2 px-2">
          <h1 className="text-2xl mb-2 dark:text-gray-200">Datos personales</h1>
          <DatePicker
            id="birth_date"
            label="Fecha de nacimiento"
            hint="Esta fecha se usará para tener constancia de tu edad y si lo deseas tambien notificar a los demas empleados sobre la proximidad de tu cumpleaños."
            value={birth_date}
            onChange={([value]) => setBirthDate(value)} />

          <Button className="mt-2" variant="default" onClick={updateBirthDate}>
            Confirmar
          </Button>
        </div>
      </div>
      
      <div className="flex w-full flex-col">
        <h1 className="text-2xl dark:text-gray-200">Enlaza tu cuenta de telegram</h1>
        <p className="text-gray-400 text-sm mt-2">Esta opción te permitirá hacer uso de <span className="text-black dark:text-white">@simgularybot</span> donde podras revisar tus pagos,</p>
        <p className="text-gray-400 text-sm">obtener recibos de pago, verificar tu sueldo y mas</p>
        <div className="mt-4">
          {session?.simgulary_bot_id ? (
            <Button variant="dangerous">
              Remover permiso
            </Button>
          ) : (
            <TelegramLoginButton
              usePic
              botName="simgularybot"
              dataOnauth={associateTelegramId}/>
          )}
        </div>
      </div>
    </div>
  )
}

export default AccountConfigurationScreen
