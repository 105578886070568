import RepositoryContext from '../contracts/RepositoryContext';
import {Session, User} from '../models'

interface ILoginBody {
    /**
     * @description some email
     */
    email: string,
    /**
     * @description some password
     */
    password: string
}

interface IRegisterBody extends ILoginBody {
    name: string
    re_password: string
}

const endpoints = ({ axios }: RepositoryContext) => ({
    /**
     * @description some function
     * @param body 
     * @returns 
     */
    login: (body: ILoginBody) => axios.post(`/api/login`, body).then(({ data }) => new Session(data)),
    register: (body: IRegisterBody) => axios.post(`/api/register`, body).then(({ data }) => data as User)
})

export interface IAuthenticationContext {
    /**
     * @description asd
     */
    login: (body: ILoginBody) => Promise<Session>,
    register: (body: IRegisterBody) => Promise<User>
}

export default endpoints