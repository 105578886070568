import Repository from '../contracts/Repository'
import RepositoryContext from '../contracts/RepositoryContext';
import { AxiosResponse } from 'axios'
import { Income } from '~/api/models'

interface ICreateIncomeBody {}

export interface IIncomeEndpointsContext extends Repository<Income, ICreateIncomeBody, ICreateIncomeBody> {}

const endpoints = ({ axios, mapQueryString }: RepositoryContext): IIncomeEndpointsContext => ({
    index: <T>(query: {}) => axios.get(`/api/incomes${mapQueryString(query)}`).then(({ data }: AxiosResponse<T>) => data),
    show: (id: number, query: {}) => axios.get(`/api/incomes/${id}${mapQueryString(query)}`).then(({ data }: AxiosResponse<Income>) => data),
    create: (body: ICreateIncomeBody) => axios.post(`/api/incomes`, body).then(({ data }: AxiosResponse<Income>) => new Income(data)),
    update: (id: number, body: ICreateIncomeBody) => axios.patch(`/api/incomes/${id}`, body).then(({ data }: AxiosResponse<Income>) => new Income(data)),
    remove: (id: number) => axios.delete(`/api/incomes/${id}`)
})


export default endpoints