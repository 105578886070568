import {useState, ReactElement, PropsWithChildren, KeyboardEvent, useMemo, useCallback} from 'react';
import {debounce} from '~/utils'
import classnames from 'classnames'
import {DropdownItem, Dropdown, Pagination, Logo} from '~/components/ui';
import {Pagination as PaginationModel} from '~/api/models'
import { BsFillEyeFill, BsChevronDown, BsChevronUp, BsFillEyeSlashFill, BsArrowRepeat, BsSearch } from 'react-icons/bs';

interface ITDContext<Source> {
  item: Source
  index: { row: number, column: number }
}

export interface Column<Source> {
  /**
   *
   * @type string
   * @description this is the TH title
   */
  title: string
  /**
   *
   * @type string
   * @description this attribute defines the key path
   * of the object to reach a given attribute
   */
  path: string
  /**
   *
   * @type boolean
   * @description this attribute defines if the column
   * is hidden by default
   */
  visible?: boolean
  /**
   *
   * @param context {ITDContext}
   * @param context.item {`Source`}
   * @param context.index {{ row: number, column: number }}
   * @description this attribute renders a custom cell content
   * for this column
   */
  td?: (context: ITDContext<Source>) => ReactElement | string | number | boolean
  th?: (index: number) => ReactElement | string | number | boolean
  filterField?: () => any

  columnFilterable?: boolean
}

interface Toolbar {
  right?: ReactElement | Node | null
  left?: ReactElement | Node | null
  bottom?: ReactElement | Node | null
}

interface ITableProps<Source> {
  columns: Column<Source>[]
  source: Source[]
  columnFilter?: boolean
  refreshField?: ReactElement
  onRefresh?: () => void
  loading?: boolean
  onSearch?: (_: string) => void
  searchDelay?: number
  toolbar?: Toolbar
  pagination?: PaginationModel<Source>,
  onPageChange?: (_: number) => void
}

const Table = <Source, >({
   columns,
   source,
   columnFilter,
   refreshField,
   loading,
   onSearch,
   searchDelay = 400,
   pagination,
   onPageChange,
   ...rest
 }: PropsWithChildren<ITableProps<Source>>) => {

  const [hiddenColumns, setHiddenColumns] = useState(columns.filter($0 => $0.visible === false).map($0 => $0.path))
  const sourceColumns = columns.map($0 => ({ ...$0, visible: !hiddenColumns.includes($0.path) }))
  const visibleColumns = useMemo(() => sourceColumns.filter($0 => $0.visible), [sourceColumns])
  const hasAtLeastOneFilter = sourceColumns.some($0 => $0.filterField)

  const toggleColumnsVisibility = useCallback((key: string) => {
    const index = hiddenColumns.findIndex($0 => $0 === key)
    const _columns = [...hiddenColumns]

    index >= 0 ? _columns.splice(index, 1) : _columns.push(key)

    setHiddenColumns(_columns)
  }, [hiddenColumns])

  const toolbarItems = {
    search: onSearch && (
      <div className="flex items-center focus-within:text-black text-gray-400 border border-gray-200 bg-white rounded-lg dark:bg-gray-900 overflow-hidden px-2 focus-within:outline-none focus-within:ring-2 focus-within:ring-secondary">
        <BsSearch className="dark:text-gray-200 transition-colors" />
        <input
          type="text"
          placeholder="Buscar..."
          className="p-2 font-medium bg-transparent dark:text-gray-200 focus:outline-none"
          onChange={debounce((event?: KeyboardEvent<HTMLInputElement>) => onSearch?.((event?.target as HTMLInputElement).value), searchDelay)}/>
      </div>
    ),
    columnFilter: columnFilter && (
      <Dropdown right label={({ show }) => (
        <div className="py-3 px-4 dark:bg-gray-900 dark:text-gray-200 transition-colors rounded-md focus-within:outline-none focus-within:ring-2 focus-within:ring-secondary bg-gray-100 text-sm flex items-center space-x-2 text-gray-700">
          <span>Columnas</span>
          {show ? <BsChevronDown /> : <BsChevronUp /> }
        </div>
      )}>
        {sourceColumns
          .filter($0 => $0.columnFilterable === undefined || $0.columnFilterable)
          .map(($0, key) => (
            <DropdownItem key={key} onClick={() => toggleColumnsVisibility($0.path)}>
              <div className={`flex space-x-3 justify-between items-center text-left w-full ${$0.visible ? 'text-gray-900 dark:text-gray-200' : 'text-gray-400 dark:text-gray-500'}`}>
                <p className="truncate">{$0.th ?? $0.title}</p>
                {$0.visible ? <BsFillEyeFill className="flex-shrink-0" /> : <BsFillEyeSlashFill className="flex-shrink-0" />}
              </div>
            </DropdownItem>
          ))}
      </Dropdown>
    ),
    refresh: refreshField || (rest.onRefresh && (
      <button onClick={() => rest.onRefresh?.()} className="py-3 px-4 dark:bg-gray-900 dark:text-gray-200 transition-colors rounded-md focus:outline-none focus:ring-2 focus:ring-secondary bg-gray-100 text-sm flex items-center space-x-2 text-gray-700">
        {loading
          ? <BsArrowRepeat className={classnames({'animate-spin': loading})} />
          : 'Refrescar'
        }
      </button>
    ))
  }

  const shouldShowToolbar = Object.values(toolbarItems).some($0 => $0) || columnFilter

  const hastDataToShow = useMemo(() => source.length > 0, [source])

  const renderCell = (src: Source, column: Column<Source>): any => {
    if (typeof src === 'object') {
      let key: any = {...src}

      try {
        column.path?.split?.('.')?.forEach?.(section => key = key[section])
      } catch (err) {}

      return key
    }

    return null
  }

  return (
    <div className="w-full">
      {shouldShowToolbar && (
        <div className="py-2 flex w-full flex-wrap justify-between items-center">
            <span>
              {toolbarItems.search}
              {rest.toolbar?.left}
            </span>
          <span className="flex space-x-2">
            {toolbarItems.columnFilter}
            {toolbarItems.refresh}
            {rest.toolbar?.right}
          </span>
        </div>
      )}
      <div className="align-middle inline-block min-w-full sm:rounded-xl relative bg-white dark:bg-gray-900">
        {loading && (
          <div className="absolute inset-0 bg-white bg-opacity-50 backdrop-filter backdrop-blur flex-col z-10 p-4 flex justify-center items-center">
            <Logo className="animate-pulse" />
            <h1 className="text-lg animate-pulse">Cargando...</h1>
          </div>
        )}
        {rest.toolbar?.bottom}
        <table className="w-full relative">
          <thead>
          <tr>
            {visibleColumns.map(($0, key) => (
              <th key={key} className={classnames('overflow-hidden font-medium px-6 dark:bg-gray-700 dark:text-gray-300 text-gray-700 py-3 bg-gray-100 text-left text-sm leading-4', {
                'rounded-l-lg': key === 0,
                'rounded-r-lg': key === visibleColumns.length - 1
              })}>
                {$0.title}
              </th>
            ))}
          </tr>
          </thead>
          <tbody>

          {hasAtLeastOneFilter && (
            <tr>
              {visibleColumns.map(($0, key) => (
                <td key={key} className="text-left text-sm leading-4 font-medium tracking-wider p-2">
                  {$0.filterField?.()}
                </td>
              ))}
            </tr>
          )}

          

          {/* {loading && !hastDataToShow && (
            <tr>
              <td colSpan={visibleColumns.length} className="bg-white dark:bg-gray-900 p-2">
                <h1 className="text-2xl text-center p-2 animate-bounce pt-8 dark:text-gray-200">Cargando...</h1>
              </td>
            </tr>
          )} */}

          {!loading && !hastDataToShow && (
            <tr className="z-10">
              {/* + expandable */}
              <td colSpan={visibleColumns.length}>
                <h2 className="text-2xl text-center block p-8 dark:text-gray-200">No hay datos encontrados</h2>
              </td>
            </tr>
          )}

          {hastDataToShow && source.map(($0, $0_key) => (
            <tr key={$0_key} className="even:bg-gray-100 dark:even:bg-gray-800 dark:text-gray-300 even:bg-opacity-70">
              {visibleColumns.map(($1, $1_key) => (
                <td key={`column_${$0_key}_${$1_key}_${$1.path}`} className="px-6 py-3 mx-auto text-sm">
                  {$1.td?.({
                    item: $0,
                    index: { row: $0_key, column: $1_key }
                  }) ?? renderCell($0, $1)}
                </td>
              ))}
            </tr>
          ))}
          </tbody>
        </table>

        {pagination && (
          <div className="mt-3">
            <Pagination
              onChange={page => onPageChange?.(page)}
              perPage={pagination.per_page ?? 9}
              current={pagination.current_page ?? 1}
              total={pagination.total ?? 0}
              pageRange={2}/>
          </div>
        )}
      </div>
    </div>
  )
}

export default Table
