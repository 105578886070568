import {useEffect, useState, useCallback} from 'react'
import { useParams } from 'react-router-dom'
import {Select, Input, Button, DatePicker, TitlePortal} from '~/components/ui'
import {useError, useToast, useRepository} from '~/components/hooks'
import {Switch} from '@headlessui/react'
import {Titular, Account, Setting} from '~/api/models'
import {useBreadcrumbs} from '~/context/breadcrumb'

export type Group<T> = {
  title: string
  items: T[]
}

export const baseForm = {
  titular_id: '',
  account_id: '',
  enterprise_id: '',
  transaction: '', // Deposito, Cheque, Transferencia
  number: '',
  amount: '',
  payday: '',
  adition: '',
  adition_reason: '',
  concept: '',
  observation: '',
  discount: '',
  discount_reason: '',
  issued_at: '',
  realized: 'Yoleizy Henriquez',
  authorized: ''
}

const CreatePaysheetScreen = () => {

  const toast = useToast()
  const {setBreadcrumb} = useBreadcrumbs()
  const {setErrors, error} = useError()
  const [state, setState] = useState(baseForm)
  const [settings, setSettings] = useState<Setting>({ sso: 0, lacks: 0, bonus: 0 })
  const [loadings, setLoadings] = useState({ titulars: false, accounts: false, store: false, settings: false })
  const [has, setHas] = useState({ lph: false, sso: false, lacks: false, bonus: false })
  const { id } = useParams<{ id: string }>()
  const [titulars, setTitulars] = useState<Titular[]>([])
  const [titular, setTitular] = useState<Titular | undefined>()
  const [accounts, setAccounts] = useState<Account[]>([])
  const [enterpriseAccounts, setEnterpriseAccounts] = useState<Group<Account>[]>([])
  const [titularAccounts, setTitularAccounts] = useState<Group<Account>[]>([])
  const {fetchTitulars, fetchAccounts, fetchSettings, updatePaysheet, fetchPaysheet} = useRepository(({ titulars, accounts, settings, paysheets }) => ({
    fetchTitulars: titulars.index,
    fetchAccounts: accounts.index,
    fetchSettings: settings.show,
    updatePaysheet: paysheets.update,
    fetchPaysheet: paysheets.show,
    //updatePaysheet: paysheets.update
  }))


  const reducedAccounts = useCallback((predicate: (value: Account, index: number, array: Account[]) => boolean): Group<Account>[] => {
    return accounts.reduce(($0, $1) => {
      if (!$0.some($2 => $2.title === $1.type)) {
        $0.push({
          title: $1.type,
          items: accounts.filter($2 => $2.type === $1.type).filter(predicate)
        })
      }

      return $0
    }, [] as Group<Account>[])
      .filter($0 => $0.items.length > 0)
  }, [accounts])

  useEffect(() => {
    setLoadings(prev => ({...prev, titulars: !prev.titulars}))
    fetchTitulars<Titular[]>({ limit: 0, scope: 'Empleado' })
      .then(titulars => setTitulars(titulars.map($0 => new Titular($0))))
      .finally(() => setLoadings(prev => ({...prev, titulars: !prev.titulars})))

    setLoadings(prev => ({...prev, accounts: !prev.accounts}))
    fetchAccounts<Account[]>({ limit: 0, with: 'bank,titular' })
      .then(accounts => setAccounts(accounts.map($0 => new Account($0))))
      .finally(() => setLoadings(prev => ({...prev, accounts: !prev.accounts})))

    setLoadings(prev => ({...prev, settings: !prev.settings}))
    fetchSettings()
      .then(settings => setSettings(settings))
      .finally(() => setLoadings(prev => ({...prev, settings: !prev.settings})))
      

    fetchPaysheet(parseInt(id),{})
      .then(paysheet => {        
        return setState({
          titular_id: `${paysheet.titular_id}`,
          account_id: `${paysheet.account_id}`,
          enterprise_id: `${paysheet.enterprise_id}`,
          transaction: paysheet.transaction, // Deposito, Cheque, Transferencia
          number: paysheet.number,
          amount: ``,
          payday: `${paysheet.payday}`,
          adition: `${paysheet.adition}`,
          adition_reason: `${paysheet.adition_reason}`,
          concept: paysheet.concept,
          observation: paysheet.observation,
          discount: `${paysheet.discount}`,
          discount_reason: `${paysheet.discount_reason}`,
          issued_at: paysheet.issued_at,
          realized: paysheet.realized,
          authorized: paysheet.authorized
        })      
      })
      //.finally(() => setLoadings(prev => ({...prev, paysheet: !prev.paysheet})))

    setBreadcrumb([
      { title: 'Órdenes de nómina', to: '/paysheets' },
      { title: 'Crear órden', to: '/paysheets/create' },
    ])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setEnterpriseAccounts(reducedAccounts($0 => $0.titular?.scope === 'Empresa'))
  }, [accounts, reducedAccounts])

  useEffect(() => {
    if (state.titular_id) {
      setTitularAccounts(reducedAccounts($0 => $0.titular_id?.toString() === state.titular_id.toString()))
    }

    setTitular(titulars.find($0 => $0.id.toString() === state.titular_id))
  }, [state.titular_id, reducedAccounts, titulars])

  useEffect(() => {    
    if (state.payday) {
      const titular = titulars.find($0 => $0.id.toString() === state.titular_id) ?? { salary: 0 }
      console.log("titular",titular);
      console.log("dsfsda",state.payday);


      setState(prev => ({
        ...prev,
        amount: state.payday === 'completo'
          ? titular.salary.toString()
          : (titular.salary / 2).toString()
      }))
    }
  }, [state.payday, state.titular_id])

  const calculateLPH = () => {
    return !titular ? 'Sin titular' : (titular?.salary || 0) * 0.01
  }

  const handleStore = () => {
    setLoadings(prev => ({...prev, store: !prev.store}))
    updatePaysheet(parseInt(id),{
      ...state,
      sso: has.sso ? settings.sso : 0,
      lacks: has.lacks ? settings.lacks : 0,
      bonus: has.bonus ? settings.bonus : 0,
      lph: has.lph ? (titular?.salary || 0) * 0.01 : 0
    })
      .then(() => toast?.success({ title: 'Exito', message: `Se ha creado una órden para el titular ${titular?.name}` }))
      .catch(setErrors())
      .finally(() => setLoadings(prev => ({...prev, store: !prev.store})))
  }

  return (
    <>
      <TitlePortal>
        Editar órden de nómina
      </TitlePortal>
      <div className="w-full p-2">
        <div className="flex flex-wrap -mx-2">
          <div className="w-full xl:w-5/6 px-2 flex flex-wrap">
            <div className="bg-white dark:bg-gray-800 w-full flex flex-wrap p-2 rounded-2xl">
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2">
                <Select
                  required
                  id="titular_id"
                  label="Titular"
                  error={error('titular_id')}
                  value={state.titular_id}
                  loading={loadings.titulars}
                  onChange={titular_id => setState(prev => ({...prev, titular_id: titular_id as string}))}>
                  <option value="">-- Titular --</option>
                  {titulars.map($0 => <option key={$0.id} value={$0.id}>{$0.name}</option>)}
                </Select>
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2 lg:mt-0 mt-4">
                <Select
                  required
                  id="account_id"
                  label="Cuenta"
                  disabled={!titular}
                  error={error('account_id')}
                  value={state.account_id}
                  loading={loadings.accounts}
                  onChange={account_id => setState(prev => ({...prev, account_id: account_id as string}))}>
                  <option value="">-- Cuenta --</option>
                  {titularAccounts
                    .map(($0, $1) => (
                      <optgroup key={$1} label={$0.title}>
                        {$0.items.map(($2, $3) => <option title={$2.number.toString()} value={$2.id} key={`${$1}_${$3}`}>{$2.bank?.name}</option>)}
                      </optgroup>
                    ))}
                </Select>
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2 xl:mt-0 mt-4">
                <Select
                  required
                  id="transaction"
                  label="Forma de pago"
                  value={state.transaction}
                  loading={loadings.titulars}
                  error={error('transaction')}
                  onChange={transaction => setState(prev => ({...prev, transaction: transaction as string}))}>
                  <option value="">-- Forma de pago --</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Depósito">Depósito</option>
                  <option value="Transferencia">Transferencia</option>
                </Select>
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
                <Input
                  required
                  id="number"
                  error={error('number')}
                  label={`Número de ${state.transaction.toLowerCase() || 'transacción'}`}
                  value={state.number}
                  onChange={number => setState(prev => ({...prev, number}))} />
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
                <Input
                  required
                  id="amount"
                  label="Monto"
                  error={error('amount')}
                  disabled
                  value={state.amount}
                  onChange={amount => setState(prev => ({...prev, amount}))} />
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
                <Select
                  required
                  id="enterprise_id"
                  error={error('enterprise_id')}
                  label="Cuenta de la empresa"
                  value={state.enterprise_id}
                  loading={loadings.accounts}
                  onChange={enterprise_id => setState(prev => ({...prev, enterprise_id: enterprise_id as string}))}>
                  <option value="">-- Cuenta --</option>
                  {enterpriseAccounts
                    .map(($0, $1) => (
                      <optgroup key={$1} label={$0.title}>
                        {$0.items.map(($2, $3) => {
                          const disabled = ($2.capital ?? 0) < (titulars.find($4 => $4.id.toString() === state.titular_id)?.salary ?? 0)
                          return (
                            <option
                              disabled={disabled}
                              title={`${$2.number}${disabled ? ' (Fondo insuficiente)' : ''}`}
                              value={$2.id} key={`${$1}_${$3}`}>
                              {$2.bank?.name}
                            </option>
                          )
                        })}
                      </optgroup>
                    ))}
                </Select>
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
                <Select
                  required
                  id="payday"
                  label="Quincena/Motivo"
                  value={state.payday}
                  error={error('payday')}
                  loading={loadings.titulars}
                  onChange={payday => setState(prev => ({...prev, payday: payday as string}))}>
                  <option value="">-- Quincena/Motivo --</option>
                  <option value="primera">Primera</option>
                  <option value="segunda">Segunda</option>
                  <option value="completo">Completo</option>
                </Select>
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
                <Input
                  id="adition"
                  error={error('adition')}
                  label="Ingreso adicional"
                  value={state.adition}
                  onChange={adition => setState(prev => ({...prev, adition}))} />
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
                <Input
                  id="adition_reason"
                  error={error('adition_reason')}
                  label="Razón del ingreso adicional"
                  required={state.adition !== ''}
                  disabled={state.adition === ''}
                  value={state.adition_reason}
                  onChange={adition_reason => setState(prev => ({...prev, adition_reason}))} />
              </div>

              <div className="w-full mt-4 lg:w-1/2 xl:w-1/4 px-2">
                <Switch.Group as="div" className="flex flex-col">
                  <Switch.Label className="cursor-pointer dark:text-gray-200">L.P.H</Switch.Label>
                  <div className="border dark:border-gray-700 rounded-xl p-2 bg-white dark:bg-gray-900 select-none flex items-center justify-between">
                    <Switch as="button" checked={has.lph} onChange={lph => setHas(prev => ({...prev, lph}))} className={`${has.lph ? "bg-blue-600" : "bg-gray-200 dark:bg-gray-800"} relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}>
                      {({ checked }) => (
                        <span className={`${checked ? "translate-x-5" : "translate-x-0"} inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white dark:bg-gray-900 rounded-full`} />
                      )}
                    </Switch>
                    <span className="dark:text-gray-300">{ has.lph ? calculateLPH() : 0 }</span>
                  </div>
                </Switch.Group>
              </div>
              <div className="w-full mt-4 lg:w-1/2 xl:w-1/4 px-2">
                <Switch.Group as="div" className="flex flex-col">
                  <Switch.Label className="cursor-pointer dark:text-gray-200">Bono de alimentación</Switch.Label>
                  <div className="border dark:border-gray-700 rounded-xl p-2 bg-white dark:bg-gray-900 select-none flex items-center justify-between">
                    <Switch as="button" checked={has.bonus} onChange={bonus => setHas(prev => ({...prev, bonus}))} className={`${has.bonus ? "bg-blue-600" : "bg-gray-200 dark:bg-gray-800"} relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}>
                      {({ checked }) => (
                        <span className={`${checked ? "translate-x-5" : "translate-x-0"} inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white dark:bg-gray-900 rounded-full`} />
                      )}
                    </Switch>
                    <span className="dark:text-gray-300">{ has.bonus ? settings.bonus : 0 }</span>
                  </div>
                </Switch.Group>
              </div>
              <div className="w-full mt-4 lg:w-1/2 xl:w-1/4 px-2">
                <Switch.Group as="div" className="flex flex-col">
                  <Switch.Label className="cursor-pointer dark:text-gray-200">S.S.O</Switch.Label>
                  <div className="border dark:border-gray-700 rounded-xl p-2 bg-white dark:bg-gray-900 select-none flex items-center justify-between">
                    <Switch as="button" checked={has.sso} onChange={sso => setHas(prev => ({...prev, sso}))} className={`${has.sso ? "bg-blue-600" : "bg-gray-200 dark:bg-gray-800"} relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}>
                      {({ checked }) => (
                        <span className={`${checked ? "translate-x-5" : "translate-x-0"} inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white dark:bg-gray-900 rounded-full`} />
                      )}
                    </Switch>
                    <span className="dark:text-gray-300">{ has.sso ? settings.sso : 0 }</span>
                  </div>
                </Switch.Group>
              </div>
              <div className="w-full mt-4 lg:w-1/2 xl:w-1/4 px-2">
                <Switch.Group as="div" className="flex flex-col">
                  <Switch.Label className="cursor-pointer dark:text-gray-200">Paro forzoso</Switch.Label>
                  <div className="border dark:border-gray-700 rounded-xl p-2 bg-white dark:bg-gray-900 select-none flex items-center justify-between">
                    <Switch as="button" checked={has.lacks} onChange={lacks => setHas(prev => ({...prev, lacks}))} className={`${has.lacks ? "bg-blue-600" : "bg-gray-200 dark:bg-gray-800"} relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}>
                      {({ checked }) => (
                        <span className={`${checked ? "translate-x-5" : "translate-x-0"} inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white dark:bg-gray-900 rounded-full`} />
                      )}
                    </Switch>
                    <span className="dark:text-gray-300">{ has.lacks ? settings.lacks : 0 }</span>
                  </div>
                </Switch.Group>
              </div>

              <div className="w-full mt-4 xl:w-1/3 px-2">
                <Input
                  id="discount"
                  label="Descuento"
                  error={error('discount')}
                  value={state.discount}
                  onChange={discount => setState(prev => ({...prev, discount}))}/>
              </div>
              <div className="w-full mt-4 xl:w-1/3 px-2">
                <Input
                  id="discount_reason"
                  error={error('discount_reason')}
                  label="Razón del descuento" value={state.discount_reason}
                  onChange={discount_reason => setState(prev => ({...prev, discount_reason}))}/>
              </div>
              <div className="w-full mt-4 xl:w-1/3 px-2">
                <DatePicker id="discount_reason" label="Fecha" value={state.issued_at} onChange={([issued_at]) => setState(prev => ({...prev, issued_at}))} />
              </div>
              <div className="w-full mt-4 xl:w-1/2 px-2">
                <Input
                  id="concept"
                  error={error('concept')}
                  label="Concepto"
                  value={state.concept}
                  onChange={concept => setState(prev => ({...prev, concept}))}/>
              </div>
              <div className="w-full mt-4 xl:w-1/2 px-2">
                <Input
                  error={error('authorized')}
                  id="observation"
                  label="Observación"
                  value={state.observation}
                  onChange={observation => setState(prev => ({...prev, observation}))}/>
              </div>
              <div className="w-full mt-4 xl:w-1/2 px-2">
                <Select
                  id="realized"
                  error={error('realized')}
                  label="Realizado por"
                  value={state.realized}
                  onChange={realized => setState(prev => ({...prev, realized: realized as string}))}>
                  <option value="Yoleizy Henriquez">Yoleizy Henriquez</option>
                </Select>
              </div>
              <div className="w-full mt-4 xl:w-1/2 px-2">
                <Select
                  id="authorized"
                  label="Autorizado por"
                  error={error('authorized')}
                  value={state.authorized}
                  onChange={authorized => setState(prev => ({...prev, authorized: authorized as string}))} >
                  <option value="Antonio Figueroa">Antonio Figueroa</option>
                  <option value="Glensimar García">Glensimar García</option>
                </Select>
              </div>
            </div>
          </div>
          <div className="flex w-full xl:w-1/6 flex-col space-y-2 px-2 mt-2 xl:mt-0">
            <Button variant="primary" onClick={handleStore}>
              {loadings.store ? 'Procesando...' : 'Guardar'}
            </Button>
            <Button to="/paysheets/create/many">
              Generar para todos
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreatePaysheetScreen
