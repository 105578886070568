import {useState, useEffect} from 'react'
import {Bank, Account} from '~/api/models'
import {Modal, CircleLoader} from '~/components/ui'
import {useRepository} from '~/components/hooks'
import {Link} from 'react-router-dom'

interface IBankAccountModalProps {
  show: boolean
  bank?: Bank,
  onCancel: () => void
}

const BankAccountsModal = ({ show, onCancel, bank }: IBankAccountModalProps) => {

  const [loading, setLoading] = useState(false)
  const [accounts, setAccounts] = useState<Account[]>([])
  const fetchAccounts = useRepository(({ accounts }) => accounts.index)
  // const fetch = useCallback(fetchAccounts, [fetchAccounts])

  useEffect(() => {
    if (bank) {
      setLoading(prev => !prev)
      fetchAccounts<Account[]>({ limit: 0, bank: bank.id, with: 'titular' })
        .then(accounts => setAccounts(accounts.map($0 => new Account($0))))
        .finally(() => setLoading(prev => !prev))
    }
  }, [bank]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      show={show}
      title={`Cuentas del banco ${bank?.name} (${bank?.accounts_count})`}
      onCancel={onCancel}>
      {loading && (
        <div className="flex flex-col justify-center items-center">
          <CircleLoader className="h-10 w-10 text-purple-600" />
          <h1>Cargando...</h1>
        </div>
      )}

      {!loading && accounts.length > 0 && (
        <ul className="space-y-3">
          {accounts.map($0 => (
            <li key={$0.id} className="flex flex-col">
              {$0.titular ? (
                <Link to={`/titulars/${$0.titular?.id}`} className="text-blue-400">{$0.titular?.name}</Link>
              ) : (
                <p className="text-gray-400">-- Sin titular asociado --</p>
              )}
              <div className="flex flex-wrap dark:text-gray-200 justify-between w-full">
                <span>{$0.number}</span>
                <span>{$0.type}</span>
              </div>
            </li>
          ))}
        </ul>
      )}

      {(!loading && !(accounts.length > 0)) && (
        <h1 className="text-center text-2xl">No hay datos encontrados</h1>
      )}
    </Modal>
  )
}

export default BankAccountsModal
