import {TitlePortal} from "~/components/ui";
import PaysheetsReports from "~/screens/reports/components/PaysheetsReports";
import PaymentsReports from "~/screens/reports/components/PaymentsReports";
// import IncomesReports from "~/screens/reports/components/IncomesReports";
// import TransfersReports from "~/screens/reports/components/TransfersReports";

const ReportsScreen = () => {

  return (
    <>
      <TitlePortal>
        Reportes
      </TitlePortal>
      <div className="p-4 space-y-4">

        <PaysheetsReports />

        <PaymentsReports />

        {/*<IncomesReports />*/}

        {/*<TransfersReports />*/}

      </div>
    </>
  )
}

export default ReportsScreen