import {useEffect, useState} from 'react';
import {useBreadcrumbs} from '~/context/breadcrumb';
import {Table, DeleteModal, Dropdown, DropdownItem, Button, TitlePortal, Portal} from '~/components/ui';
import {useRepository} from '~/components/hooks'
import {Pagination, Bank} from '~/api/models';
import BankAccountsModal from './modals/accounts'
import CreateBankModal from './modals/create'
import UpdateBankModal from './modals/update'

const BanksScreen = () => {

    const {setBreadcrumb} = useBreadcrumbs()
    const [query] = useState({ limit: 10, page: 1, withCount: 'accounts', sort_by: 'name,asc' })
    const [modals, setModals] = useState({ create: false, remove: false, update: false, accounts: false })
    const [selected, setSelected] = useState<Bank | undefined>(undefined)
    const [pagination, setPagination] = useState<Pagination<Bank>>({ data: [] })
    const [loadings, setLoading] = useState({ index: false, remove: false })
    const { fetchBanks, removeBank } = useRepository(({ banks }) => ({
        fetchBanks: banks.index,
        removeBank: banks.remove
    }))

    useEffect(() => {
        fetch()
        setBreadcrumb([
            { title: 'Listado de bancos', to: '/banks', loading: false },
        ])
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const select = (item: Bank, cb: () => void) => {
        setSelected(item)
        cb()
    }

    const fetch = (filters = {}) => {

        setLoading(prev => ({...prev, index: !prev.index}))

        fetchBanks<Pagination<Bank>>({...query, ...filters})
            .then(pagination => new Pagination<Bank>(pagination, $0 => new Bank($0)))
            .then(pagination => setPagination(pagination))
            .finally(() => setLoading(prev => ({...prev, index: !prev.index})))
    }

    const handleRemove = () => {
        setLoading(prev => ({...prev, remove: !prev.remove}))
        removeBank(selected!.id)
            .then(() => {
                fetch({ page: 1 })
                setModals(prev => ({...prev, remove: false}))
            })
            .finally(() => setLoading(prev => ({...prev, remove: !prev.remove})))
    }

  return (
    <>
      <TitlePortal>
        Listado de cuentas bancarias
      </TitlePortal>
      <Portal target="#main_action">
        <Button variant="primary" onClick={() => setModals(prev => ({...prev, create: true}))}>
          Crear banco
        </Button>
      </Portal>
      <div className="w-full p-2">
        <Table
          columnFilter
          onSearch={search => fetch({ search, page: 1 })}
          loading={loadings.index}
          source={pagination.data}
          pagination={pagination}
          onPageChange={page => fetch({ page })}
          onRefresh={() => fetch({ page: 1 })}
          columns={[
            {title: 'Prefijo', path: 'index'},
            {title: 'Nombre', path: 'name'},
            {title: 'N Cuentas asociadas', path: 'accounts_count'},
            {title: 'Acciones', path: 'id', td: ({ item }) => (
              <Dropdown right>
                {item.accounts_count > 0 && (
                  <DropdownItem onClick={() => select(item, () => setModals(prev => ({...prev, accounts: true})))}>
                    Ver cuentas
                  </DropdownItem>
                )}
                <DropdownItem onClick={() => select(item, () => setModals(prev => ({...prev, update: true})))}>
                  Actualizar
                </DropdownItem>
                <hr/>
                <DropdownItem onClick={() => select(item, () => setModals(prev => ({...prev, remove: true})))}>
                  Eliminar
                </DropdownItem>
              </Dropdown>
            )},
          ]} />
      </div>

      <DeleteModal
        show={modals.remove}
        title="Eliminar titular"
        heading="Un momento!! esta seguro de que desea eliminar el titular: "
        subheading={pagination.data.find($0 => $0.id === selected?.id)?.name ?? 0}
        loading={loadings.remove}
        onConfirm={handleRemove}
        onCancel={() => setModals(prev => ({...prev, remove: !prev.remove}))} />

      <BankAccountsModal
        show={modals.accounts}
        bank={selected}
        onCancel={() => setModals(prev => ({...prev, accounts: false}))}/>

      <CreateBankModal
        show={modals.create}
        onCreated={() => {
          fetch({ page: 1 })
          setModals(prev => ({...prev, create: false}))
        }}
        onCancel={() => setModals(prev => ({...prev, create: false}))}/>

      <UpdateBankModal
        show={modals.update}
        bank={selected}
        onUpdated={() => {
          fetch({ page: 1 })
          setModals(prev => ({...prev, update: false}))
        }}
        onCancel={() => setModals(prev => ({...prev, update: false}))}/>
    </>
  )
}

export default BanksScreen
