import { createContext, useReducer, FunctionComponent, useContext, Dispatch } from 'react';
import { User, Session } from '~/api/models';
import { SessionAction, SessionActions } from './actions';

export interface ISessionState {
    user?: User
}

const initialState = {
    user: localStorage.user ? JSON.parse(localStorage.user) : undefined,
}

const reducer = (state: ISessionState, action: SessionAction): ISessionState => {
    switch (action.type) {
        case SessionActions.SET_USER:
            return {...state, user: action.payload }
        default:
            return state
    }
}

const Store = createContext<{
    state: ISessionState,
    dispatch: Dispatch<SessionAction>
}>({
    state: initialState,
    dispatch: () => null
})

const Provider = Store.Provider
const SessionProvider: FunctionComponent = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState)

    return <Provider value={{state, dispatch}}>{children}</Provider>
}

export const useSession = () => {
    const { state, dispatch } = useContext(Store)
    
    const session = state.user
    const isUserLogged = session !== undefined
        && typeof session === 'object'
        && Object.keys(session).length > 0
        && localStorage.token !== undefined

    const setUser = (session: Session) => {
        try {
            localStorage.user = JSON.stringify(session.user)
            localStorage.token = session.access_token
            dispatch({ type: SessionActions.SET_USER, payload: session.user })
        } catch(err) {
            console.warn(`Failed to store user`, err)
        }
    }

    const updateUser = (user: User) => {
        try {
            const copy = {...session, ...user}

            localStorage.user = JSON.stringify(copy)

            dispatch({ type: SessionActions.SET_USER, payload: copy })
        } catch (err) {
            console.warn(`Failed to store user`, err)
        }
    }

    const removeUser = () => {
        try {
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            dispatch({ type: SessionActions.SET_USER, payload: undefined })
        } catch(err) {
            console.warn(`Failed to remove user`, err)
        }
    }

    return { session, setUser, removeUser, isUserLogged, updateUser }
}

export default SessionProvider