import {ButtonHTMLAttributes, FunctionComponent} from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styles from './Button.module.css'
import {CircleLoader} from "~/components/ui";
import classnames from "classnames";

interface IButtonProps {
  to?: string
  variant?: 'default' | 'primary' | 'dangerous' | 'ghost'
  loading?: Boolean
  size?: 'sm' | 'md' | 'lg'
}

const Button: FunctionComponent<IButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
  variant,
  to = undefined,
  loading = false,
  size = 'sm',
  children,
  className,
  ...rest
 }) => {

  const classNames = classnames([variant ? styles[variant] : null ?? styles.btn, styles[size], className])

  if (to) {
    return (
      <Link {...rest as LinkProps} className={classNames} to={to}>
        {loading ? <CircleLoader className="w-4 h-4" /> : children}
      </Link>
    )
  }

  return (
    <button {...rest} className={classNames}>
      {loading ? <CircleLoader className="w-6 h-6" /> : children}
    </button>
  )
}

export default Button
