import {useState, FormEvent} from 'react'
import {Input, Logo, Button} from '~/components/ui'
import {useRepository, useError, useToast} from '~/components/hooks'
import {Link} from 'react-router-dom'

const LoginScreen = () => {

  const { error, setErrors } = useError()
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const [state, setState] = useState({
    email: '',
    password: '',
    name: '',
    re_password: ''
  })

  const register = useRepository(({ authentication }) => authentication.register)

  const handleLogin = (event: FormEvent) => {
    event.preventDefault()

    setLoading(prev => !prev)
    register(state)
      .then(() => {
        toast.success({
          title: 'Registro exitoso',
          message: 'Dentro de poco alguien confirmará su solicitud de registro'
        })
      })
      .catch(setErrors(() => {
        toast.error({
          title: 'Oops!',
          message: 'Por favor verifique sus datos'
        })
      }))
      .finally(() => setLoading(prev => !prev))
  }

  return (
    <div className="h-screen w-full flex flex-col items-center justify-center">
      <Logo size="140px" />
      <div className="w-full flex w-full px-16 md:px-0 md:w-1/3 lg:w-2/6 xl:w-1/4">
        <form onSubmit={handleLogin} className="w-full flex flex-col items-center space-y-4">
          <Input
            id="name"
            label="Nombre"
            value={state.name}
            className="w-full"
            error={error('name')}
            onChange={name => setState(prev => ({...prev, name}))} />
          <Input
            id="email"
            label="Correo electrónico"
            value={state.email}
            className="w-full"
            error={error('email')}
            onChange={email => setState(prev => ({...prev, email}))} />

          <Input
            id="password"
            label="Contraseña"
            className="w-full"
            value={state.password}
            type="password"
            error={error('password')}
            onChange={password => setState(prev => ({...prev, password}))} />

          <Input
            id="re_password"
            label="Repetir contraseña"
            className="w-full"
            value={state.re_password}
            type="password"
            error={error('re_password')}
            onChange={re_password => setState(prev => ({...prev, re_password}))} />

          <Button variant="primary" className="w-full" disabled={loading} loading={loading}>
            Regístrate
          </Button>

          <span className="text-gray-600">
              ¿Ya tienes una cuenta?
            </span>

          <Link to="/">
            <a href="/" className="text-primary">
              Iniciar sesión
            </a>
          </Link>
        </form>
      </div>
    </div>
  )
}

export default LoginScreen
