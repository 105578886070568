import Account from './Account'

class Bank {

    id: number
    index: string
    name: string
    accounts: Account[]
    accounts_count: number

    constructor(map: Bank) {
        this.id = map.id
        this.index = map.index
        this.name = map.name
        this.accounts = map.accounts ?? []
        this.accounts_count = map.accounts_count ?? 0
    }

}

export default Bank