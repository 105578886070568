import Repository from '../contracts/Repository'
import RepositoryContext from '../contracts/RepositoryContext';
import { AxiosResponse } from 'axios'
import { Titular } from '~/api/models'

interface ICreateTitularBody {
    name?: string
    scope?: string
    type?: string
    ric?: string
    salary?: string
}

export interface ITitularEndpointsContext extends Repository<Titular, ICreateTitularBody, ICreateTitularBody> {}

const endpoints = ({ axios, mapQueryString }: RepositoryContext): ITitularEndpointsContext => ({
    index: <T>(query: {}) => axios.get(`/api/titulars${mapQueryString(query)}`).then(({ data }: AxiosResponse<T>) => data),
    show: (id: number, query: {}) => axios.get(`/api/titulars/${id}${mapQueryString(query)}`).then(({ data }: AxiosResponse<Titular>) => data),
    create: (body: ICreateTitularBody) => axios.post(`/api/titulars`, body).then(({ data }: AxiosResponse<Titular>) => new Titular(data)),
    update: (id: number, body: ICreateTitularBody) => axios.patch(`/api/titulars/${id}`, body).then(({ data }: AxiosResponse<Titular>) => new Titular(data)),
    remove: (id: number) => axios.delete(`/api/titulars/${id}`)
})


export default endpoints