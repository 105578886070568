import {useEffect, useState} from 'react'
import {Input, Button, CircleLoader} from '~/components/ui'
import {useRepository, useToast} from '~/components/hooks'
import {Setting, Titular} from '~/api/models'
import {useBreadcrumbs} from '~/context/breadcrumb'

const TitularRow = ({ titular }: { titular: Titular }) => {

  const [salary, setSalary] = useState<string>(`${titular.salary}` ?? '0')
  const [loading, setLoading] = useState(false)
  const updateEndpoint = useRepository(({ titulars }) => titulars.update)
  const toast = useToast()

  const update = () => {
    setLoading(prev => !prev)
    updateEndpoint(titular.id, {...titular, salary})
      .then(() => toast?.success({ title: 'Exito', message: `El salaro de ${titular.name} ha sido actualizado con exito` }))
      .finally(() => setLoading(prev => !prev))
  }

  return (
    <li className="rounded-2xl p-2">
      <Input
        id={`titular_${titular.id}`}
        label={titular.name}
        value={salary}
        onChange={salary => setSalary(salary)}
        suffix={
          <button disabled={!salary || parseFloat(salary) < 1 || isNaN(parseFloat(salary))} onClick={update} className="focus:outline-none rounded-r-md disabled:cursor-not-allowed dark:disabled:bg-gray-400 disabled:bg-gray-200 pr-2 bg-blue-600 text-white text-opacity-80 p-2">
            { loading ? 'Procesando...' : 'Guardar' }
          </button>
        }/>
    </li>
  )
}

const Settings = () => {

  const toast = useToast()
  const [settings, setSettings] = useState<Setting>({ sso: 0, lacks: 0, bonus: 0 })
  const [updating, setUpdating] = useState(false)
  const [fetching, setFetching] = useState(false)
  const {updateSettings, fetchSettings} = useRepository(({ settings }) => ({
    updateSettings: settings.update,
    fetchSettings: settings.show
  }))

  const handleSettigsUpdate = () => {
    setUpdating(prev => !prev)
    updateSettings(settings)
      .then(() => toast.success({ title: 'Exito', message: `La configuración de pagos ha sido actualizada con exito` }))
      .finally(() => setUpdating(prev => !prev))
  }

  useEffect(() => {
    setFetching(prev => !prev)
    fetchSettings()
      .then(settings => setSettings(settings))
      .finally(() => setFetching(prev => !prev))
  }, []) // eslint-disable-line

  return (
    <div className="bg-white dark:bg-gray-900 dark:bg-opacity-50 rounded-2xl p-4 shadow-md relative overflow-hidden">
      <Input value={settings.bonus} onChange={bonus => setSettings(prev => ({...prev, bonus}))} id="bonus" label="Bono alimenticio" />
      <Input value={settings.sso} onChange={sso => setSettings(prev => ({...prev, sso}))} id="sso" label="S.S.O" className="mt-4" />
      <Input value={settings.lacks} onChange={lacks => setSettings(prev => ({...prev, lacks}))} id="lacks" label="Paros forzosos" className="mt-4" />

      <div className="flex justify-end mt-2">
        <Button onClick={handleSettigsUpdate}>Guardar</Button>
      </div>

      {(updating || fetching) && (
        <div className="absolute inset-0 bg-gray-300 opacity-75 flex justify-center items-center">
          <CircleLoader className="h-16 w-16 text-white" />
        </div>
      )}
    </div>
  )
}

const SalariesScreen = () => {
  
  const { setBreadcrumb } = useBreadcrumbs()
  const [titulars, setTitulars] = useState<Titular[]>([])
  const [loadings, setLoadings] = useState({ titulars: false })
  const {fetchTitulars} = useRepository(({ titulars, settings }) => ({
    fetchTitulars: titulars.index,
    fetchSettings: settings.show,
    updateTitular: titulars.update
  }))

  useEffect(() => {
    setLoadings(prev => ({...prev, titulars: !prev.titulars}))
    fetchTitulars<Titular[]>({ limit: 0, scope: 'Empleado', sort_by: 'name,asc' })
      .then(titulars => setTitulars(titulars.map($0 => new Titular($0))))
      .finally(() => setLoadings(prev => ({...prev, titulars: !prev.titulars})))

    setBreadcrumb([
      { title: 'Configuraciones', to: '/settings' },
      { title: 'Salarios', to: '/settings/salary' },
    ])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full flex flex-wrap -mx-2">
      <div className="px-2 w-full xl:w-4/6 order-last xl:order-first">
        <h1 className="px-2 text-2xl my-2 dark:text-gray-200">
          Actualización de salarios
        </h1>

        {loadings.titulars && (
          <div className="flex flex-col justify-center items-center text-gray-500">
            <CircleLoader className="h-16 w-16" />
            <h1 className="text-xl">Cargando salarios...</h1>
          </div>
        )}

        <ul>
          {titulars.map(titular => <TitularRow key={titular.id} titular={titular} />)}
        </ul>
      </div>

      <div className="px-2 w-full xl:w-2/6 order-first xl:order-last">
        <Settings />
      </div>
    </div>
  )
}

export default SalariesScreen
