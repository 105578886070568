import { PropsWithChildren, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import styles from './DropdownItem.module.css'

interface IDropdownItem {
  to?: string
  href?: string
  target?: '_blank'
  onClick?: (_: MouseEvent) => void
  mode?: 'default' | 'dangerous'
}

const DropdownItem = ({ children, href, target, to = undefined, onClick = undefined, mode = 'default' }: PropsWithChildren<IDropdownItem>) => to
  ? (
    <Link to={to} className={styles[`dropdown-item-${mode}`]}>
      {children}
    </Link>
  )
  : href
    ? (
      <a href={href} target={target} className={styles[`dropdown-item-${mode}`]}>
        {children}
      </a>
    )
    : (
      <button onClick={onClick} className={styles[`dropdown-item-${mode}`]}>
        {children}
      </button>
  )

export default DropdownItem
