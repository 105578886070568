import { FunctionComponent } from 'react';
import { useSession } from '~/context/session';
import AuthenticatedLayout from './authenticated/AuthenticatedLayout';
import GuestLayout from './guest/GuestLayout';

const Layout: FunctionComponent = (props) => {

  const { isUserLogged } = useSession()
	const Wrapper = isUserLogged ? AuthenticatedLayout : GuestLayout

  return <Wrapper {...props} />
}

export default Layout