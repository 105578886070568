import RepositoryContext from '../contracts/RepositoryContext';
import { AxiosResponse } from 'axios'
import {Paysheet} from "~/api/models";

interface PaysheetsResponse {
  data: { [key: string]: { [key: string]: Paysheet[] } }
  years: { [key: string]: { [key: string]: { total: string, issued_at: string }[] } }
}

export interface IStatisticsEndpointsContext {
  paysheets: (year?: number) => Promise<PaysheetsResponse>,
}

const endpoints = ({ axios }: RepositoryContext): IStatisticsEndpointsContext => ({
  paysheets: (year = new Date().getFullYear()) => axios.get(`/api/statistics/paysheets/${year}`).then(({ data }: AxiosResponse) => data as PaysheetsResponse),
})


export default endpoints