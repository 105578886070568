import Repository from '../contracts/Repository'
import RepositoryContext from '../contracts/RepositoryContext';
import {AxiosResponse} from 'axios'
import {Paysheet} from '~/api/models'

interface ICreatePaysheetBody {}

export interface IPaysheetEndpointsContext extends Repository<Paysheet, ICreatePaysheetBody, ICreatePaysheetBody> {
    bulkCreate: (body: ICreatePaysheetBody[]) => Promise<any>
}

const endpoints = ({ axios, mapQueryString }: RepositoryContext): IPaysheetEndpointsContext => ({
    index: <T>(query: {}) => axios.get(`/api/paysheets${mapQueryString(query)}`).then(({ data }: AxiosResponse<T>) => data),
    show: (id: number, query: {}) => axios.get(`/api/paysheets/${id}${mapQueryString(query)}`).then(({ data }: AxiosResponse<Paysheet>) => data),
    create: (body: ICreatePaysheetBody) => axios.post(`/api/paysheets`, body).then(({ data }: AxiosResponse<Paysheet>) => new Paysheet(data)),
    bulkCreate: (body: ICreatePaysheetBody[]) => axios.post(`/api/paysheets/bulk-store`, body).then(({ data }: AxiosResponse) => data),
    update: (id: number, body: ICreatePaysheetBody) => axios.patch(`/api/paysheets/${id}`, body).then(({ data }: AxiosResponse<Paysheet>) => new Paysheet(data)),
    remove: (id: number) => axios.delete(`/api/paysheets/${id}`)
})


export default endpoints