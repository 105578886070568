import Repository from '../contracts/Repository'
import RepositoryContext from '../contracts/RepositoryContext';
import { AxiosResponse } from 'axios'
import { Preferences, User } from '~/api/models'
import {RoleType} from "~/api/models/User";

interface ICreateUserBody {
  index: string | number
  name: string
}

interface IUpdateUserBody {
  simgulary_bot_id?: number
  name?: string
  email?: string
  type?: RoleType
  birth_date?: string
}

interface IBindUserBody {
  type: RoleType
  titular_id: number
}

export interface IUserEndpointsContext extends Repository<User, ICreateUserBody, IUpdateUserBody> {
  bind: (id: number, body: IBindUserBody) => Promise<User>,
  unbound: (id: number) => Promise<User>
  updatePreferences: (id: number, body: Preferences) => Promise<Preferences>
}

const endpoints = ({ axios, mapQueryString }: RepositoryContext): IUserEndpointsContext => ({
  index: <T>(query: {}) => axios.get(`/api/users${mapQueryString(query)}`).then(({ data }: AxiosResponse<T>) => data),
  show: (id: number, query: {}) => axios.get(`/api/users/${id}${mapQueryString(query)}`).then(({ data }: AxiosResponse<User>) => data),
  create: (body: ICreateUserBody) => axios.post(`/api/users`, body).then(({ data }: AxiosResponse<User>) => new User(data)),
  update: (id: number, body: IUpdateUserBody) => axios.patch(`/api/users/${id}`, body).then(({ data }: AxiosResponse<User>) => new User(data)),
  remove: (id: number) => axios.delete(`/api/users/${id}`),
  bind: (id: number, body: IBindUserBody) => axios.patch(`/api/users/${id}/bind`, body).then(({ data }) => data as User),
  unbound: (id: number) => axios.patch(`/api/users/${id}/unbound`).then(({ data }) => data as User),
  updatePreferences: (id: number, body: Preferences) => axios.patch(`/api/users/${id}/preferences`, body)
})


export default endpoints