import {PropsWithChildren, useEffect} from 'react'
import { Transition } from '@headlessui/react'
import styles from './Modal.module.css'

interface IModalProps {
    show: boolean
    title?: string
    onConfirm?: () => void
    onCancel?: () => void
    isDanger?: boolean
    confirmText?: string
    cancelText?: string
}

const Modal = ({
    children,
    show,
    onConfirm,
    onCancel,
    isDanger = false,
    confirmText = 'Confirmar',
    cancelText = 'Cancelar',
    ...rest
}: PropsWithChildren<IModalProps>) => {

    useEffect(() => {
        document.body.style.overflow = show ? 'hidden' : 'auto'

        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [show])

    return (
        // <!-- This example requires Tailwind CSS v2.0+ -->
        <Transition show={show}>
            <div className={styles.overlay}>
                <div className={styles.overlayBox}>
                    <Transition.Child
                        enter={styles.transitionChild1Enter}
                        enterFrom={styles.transitionChild1EnterFrom}
                        enterTo={styles.transitionChild1EnterTo}
                        leave={styles.transitionChild1Leave}
                        leaveFrom={styles.transitionChild1LeaveFrom}
                        leaveTo={styles.transitionChild1LeaveTo}>
                        <div className={styles.overlayBackground} aria-hidden="true">
                            <div className={styles.overlayBackgroundInner}/>
                        </div>
                    </Transition.Child>

                    {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                    <span className={styles.trick} aria-hidden="true">&#8203;</span>

                    <Transition.Child
                        className={styles.transitionChild2Base} role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                        enter={styles.transitionChild2Enter}
                        enterFrom={styles.transitionChild2EnterFrom}
                        enterTo={styles.transitionChild2EnterTo}
                        leave={styles.transitionChild2Leave}
                        leaveFrom={styles.transitionChild2LeaveFrom}
                        leaveTo={styles.transitionChild2LeaveTo}>
                        <div className={styles.container}>
                            <div className={styles.containerFlex}>
                                {/* <!-- Heroicon name: outline/exclamation --> */}
                                {isDanger && (
                                    <div className={styles.dangerBackgroundIcon}>
                                        <svg className={styles.dangerIcon} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                        </svg>
                                    </div>
                                )}
                                <div className={[styles.content, isDanger && styles.dangerContent].filter($0 => $0).join(' ')}>
                                    {rest.title && (
                                        <h3 className={styles.title}>
                                            {rest.title}
                                        </h3>
                                    )}

                                    {children}
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            {onConfirm && (
                                <button onClick={onConfirm} type="button" className={isDanger ? styles.dangerConfirm : styles.regularConfirm}>
                                    {confirmText}
                                </button>
                            )}
                            {onCancel && (
                              <button onClick={onCancel} type="button" className={styles.cancel}>
                                  {cancelText}
                              </button>
                            )}
                        </div>
                    </Transition.Child>
                </div>
            </div>
        </Transition>
    )
}

export default Modal
