import {useEffect, useState} from 'react';
import {useBreadcrumbs} from '~/context/breadcrumb';
import {Table, DatePicker, DeleteModal, Dropdown, DropdownItem, Button, TitlePortal, Portal} from '~/components/ui';
import {useRepository} from '~/components/hooks'
import {Pagination, Paysheet} from '~/api/models';

const PaysheetsScreen = () => {

  const {setBreadcrumb} = useBreadcrumbs()
  const [query, setQuery] = useState({ limit: 10, page: 1, with: 'account.bank,enterprise.bank,titular', startDate: null, endDate: null, sort_by: 'created_at,desc' })
  const [modals, setModals] = useState({ create: false, remove: false, assign: false })
  const [selected, setSelected] = useState<Paysheet | undefined>(undefined)
  const [pagination, setPagination] = useState<Pagination<Paysheet>>({ data: [] })
  const [loadings, setLoading] = useState({ index: false, remove: false })
  const {fetchPaysheets, removePaysheet, paysheetReportURL, paysheetsReportsURL} = useRepository(({ paysheets, reports }) => ({
    fetchPaysheets: paysheets.index,
    removePaysheet: paysheets.remove,
    paysheetReportURL: reports.paysheet,
    paysheetsReportsURL: reports.paysheets,
  }))

  useEffect(() => {
    fetch()
    setBreadcrumb([
      { title: 'Listado de nóminas', to: '/paysheets', loading: false },
    ])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const select = (item: Paysheet, cb: () => void) => {
    setSelected(item)
    cb()
  }

  const fetch = (filters = {}) => {

    setLoading(prev => ({...prev, index: !prev.index}))
    const _query = {...query, ...filters}

    fetchPaysheets<Pagination<Paysheet>>(_query)
      .then(pagination => new Pagination<Paysheet>(pagination, $0 => new Paysheet($0)))
      .then(pagination => setPagination(pagination))
      .then(() => setQuery(_query))
      .finally(() => setLoading(prev => ({...prev, index: !prev.index})))
  }

  const handleRemove = () => {
    setLoading(prev => ({...prev, remove: !prev.remove}))
    removePaysheet(selected!.id)
      .then(() => {
        fetch({ page: 1 })
        setModals(prev => ({...prev, remove: false}))
      })
      .finally(() => setLoading(prev => ({...prev, remove: !prev.remove})))
  }

  return (
    <>
      <TitlePortal>
        Listado de órdenes de nómina
      </TitlePortal>
      <Portal target="#main_action">
        <Button variant="primary" to="/paysheets/create">
          Crear orden
        </Button>
      </Portal>
      <div className="w-full p-2">
        <Table
          columnFilter
          onSearch={search => fetch({ search, page: 1 })}
          loading={loadings.index}
          pagination={pagination}
          onPageChange={page => fetch({ page })}
          source={pagination.data}
          onRefresh={() => fetch({ page: 1 })}
          toolbar={{
            right: (query.startDate && query.endDate && !pagination.isEmpty) && (
              <a rel="noreferrer" target="_blank" href={paysheetsReportsURL({ startDate: query.startDate, endDate: query.endDate })} className="border flex items-center justify-content rounded-md px-2   ">
                Descargar resultados
              </a>
            )
          }}
          columns={[
            { title: 'Fecha de creación', path: 'issued_at', filterField: () => (
                <DatePicker range onChange={([startDate, endDate]) => fetch({ startDate: startDate && `issued_at,${startDate}`, endDate: endDate && `issued_at,${endDate}` })} />
              )},
            { title: 'Mes', path: 'month', visible: false },
            { title: 'Para', path: 'titular.name' },
            { title: 'A la cuenta', path: 'account.number' },
            { title: 'Desde la cuenta', path: 'enterprise.number' },
            { title: 'Monto', path: 'total' },
            { title: 'Medio', path: 'transaction' },
            { title: 'Quincena', path: 'payday' },
            { title: 'Acciones', path: 'id', td: ({ item }) => (
              <Dropdown right>
                <DropdownItem target="_blank" href={paysheetReportURL(item.id)}>
                  Generar
                </DropdownItem>
                <DropdownItem href={`/paysheets/${item.id}/edit`}>
                  Editar
                </DropdownItem>
                <DropdownItem onClick={() => select(item, () => setModals(prev => ({...prev, remove: true})))}>
                  Eliminar
                </DropdownItem>
              </Dropdown>
            ), columnFilterable: false },
          ]} />
      </div>

      <DeleteModal
        show={modals.remove}
        title="Eliminar órden de nómina"
        heading="Un momento!! ¿está seguro de que desea cotinuar?"
        subheading={
          <span>está por eliminar una orden de la fecha {selected?.issued_at} para <b>{selected?.titular?.name}</b> por un monto de {selected?.amount}</span>
        }
        loading={loadings.remove}
        onConfirm={handleRemove}
        onCancel={() => setModals(prev => ({...prev, remove: !prev.remove}))} />
    </>
  )
}

export default PaysheetsScreen
