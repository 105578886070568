// export const pick = (object: {}, ...props: any) => props.reduce((result, prop) => {
//     result[prop] = object[prop];
//     return result;
// }, {});

export const pickMap = <T, E>(object: T, reducer: (_: T) => E) => reducer(object)

export type Dictionary<Key extends string | symbol | number, Value> = {
    [key in Key]: Value
}

// export const omit = (object, ...props) => {
//     const result = { ...object };
//     props.forEach(prop => {
//       delete result[prop];
//     });
//     return result;
// }