import {Account, Setting, Titular} from "~/api/models";
import {IBasePaysheetForm} from "~/screens/paysheets/components/BaseManyForm";
import {FunctionComponent, useState, useEffect, Dispatch, SetStateAction, useCallback, useMemo} from "react";
import {baseForm, Group} from "~/screens/paysheets/create";
import {Button, DatePicker, Input, Select} from "~/components/ui";
import {Switch} from "@headlessui/react";
import {useError, useRepository, useToast} from "~/components/hooks";
import {IError} from "~/components/hooks/useError";
import classnames from "classnames";

interface IFormsProps {
  employees: Titular[]
  base: IBasePaysheetForm
  onCreate: () => void
  settings: Setting
}

type BaseForm = typeof baseForm

interface FullForm extends BaseForm {
  has: {
    sso: boolean
    lacks: boolean
    lph: boolean
    bonus: boolean
  },
  employee: Titular
}

const Forms: FunctionComponent<IFormsProps> = ({ employees, base, onCreate, settings }) => {

  const [state, setState] = useState<FullForm[]>(employees.map(employee => ({
    ...baseForm,
    ...base,
    titular_id: employee.id.toString(),
    employee: employee,
    has: {
      lph: false,
      sso: false,
      lacks: false,
      bonus: false
    },
  })))
  const [enterpriseAccounts, setEnterpriseAccounts] = useState<Group<Account>[]>([])
  const fetchEnterpriseAccounts = useRepository(({ accounts }) => accounts.index)
  const [index, setIndex] = useState<number>(0)
  const {error, setErrors} = useError()
  const bulkStore = useRepository(({ paysheets }) => paysheets.bulkCreate)
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  useEffect(() => {
    fetchEnterpriseAccounts<Account[]>({ limit: 0, scope: 'Empresa', with: 'bank,titular' })
      .then(accounts => {
        setEnterpriseAccounts(reducedAccounts(accounts, () => true))
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const reducedAccounts = useCallback((accounts: Account[], predicate: (value: Account, index: number, array: Account[]) => boolean): Group<Account>[] => {
    return accounts.reduce(($0, $1, _, self) => {
      if (!$0.some($2 => $2.title === $1.type)) {
        const title = $1.type
        const items = self.filter($3 => $3.type === $1.type).filter(predicate)
        $0.push({ title, items })
      }

      return $0
    }, [] as Group<Account>[])
      .filter($0 => $0.items.length > 0)
  }, [])

  const handleStore = () => {
    setLoading(prev => !prev)
    bulkStore(state.map(form => ({
      ...form,
      month: ((current) => {
        const issued_date = new Date(current.issued_at)
        const month = new Intl.DateTimeFormat('es-VE', { month: 'long' }).format(issued_date)

        return month.charAt(0).toUpperCase() + month.slice(1)
      })(form),
      sso: form.has.sso ? settings.sso : 0,
      lacks: form.has.lacks ? settings.lacks : 0,
      bonus: form.has.bonus ? settings.bonus : 0,
      lph: form.has.lph ? (form.employee?.salary || 0) * 0.01 : 0
    })))
      .then(() => {
        toast?.success({
          title: 'Operación completa',
          message: `Se han creado ${state.length} órdenes de nómina con éxito`,
          lifetime: -1
        })

        onCreate()
      })
      .catch(setErrors())
      .finally(() => setLoading(prev => !prev))
  }

  const handleStateChange = (value: any) => {
    const newValue = typeof value === 'function' ? value() : value
    setState(prev => prev.map((form, key) => {
      return key === index ? {...form, ...newValue} : form
    }))
  }

  const isEmployeeReadyToCreate = useCallback((current: number) => {
    const form = state[current]
    return [
      form.amount,
      form.number,
      form.account_id,
      form.enterprise_id
    ].every(value => value)
  }, [state])

  const isAllReadyToCreate = useMemo(() => state.every((_, index) => isEmployeeReadyToCreate(index)), [state, isEmployeeReadyToCreate])
  
  const indexHasError = useCallback((current: number) => {
    const form = state[current]
    return Object.keys(form).some(attribute => {
      const errorName = `${current}.${attribute}`
      const _error = error(errorName).value
      return _error
    })
  }, [state, error])

  return (
    <div className="relative flex -mt-2">
      <aside className="w-60 dark:bg-gray-900 flex-shrink-0 block">
        <ul className="space-y-1 p-2">
          {employees.map((employee, key) => (
            <li key={key} className="text-gray-700 text-sm">
              <button className={classnames('p-2 rounded-xl hover:bg-primary px-4 hover:bg-opacity-10 text-left w-full flex items-center space-x-2 focus:outline-none', {
                'bg-primary text-white bg-opacity-90 hover:bg-opacity-80': index === key && !isEmployeeReadyToCreate(key),
                'text-green-500': isEmployeeReadyToCreate(key) && !indexHasError(key),
                'text-red-500': indexHasError(key)
              })} onClick={() => setIndex(key)}>
                <span>{employee.name}</span>
              </button>
            </li>
          ))}
        </ul>
      </aside>
      <main className="flex-grow p-4 dark:bg-gray-900">
        <Form
          index={index}
          state={state[index]}
          setState={handleStateChange}
          titular={employees[index]}
          enterpriseAccounts={enterpriseAccounts}
          settings={settings}
          error={error}
        />

        <div className="flex justify-end mt-4" >
          <Button variant="primary" loading={loading} disabled={!isAllReadyToCreate} onClick={handleStore}>
            Crear todos
          </Button>
        </div>
      </main>
    </div>
  )
}

interface IFormProps {
  state: FullForm
  setState: Dispatch<SetStateAction<FullForm>>
  titular: Titular
  enterpriseAccounts: Group<Account>[]
  settings: Setting
  index: number
  error: (error: string) => IError
}

const Form: FunctionComponent<IFormProps> = ({
  state,
  setState,
  titular,
  enterpriseAccounts,
  settings,
  error,
  index
}) => {
  const [titularAccounts, setTitularAccounts] = useState<Group<Account>[]>([])
  const reducedAccounts = useCallback((predicate: (value: Account, index: number, array: Account[]) => boolean): Group<Account>[] => {
    return titular.accounts.reduce(($0, $1) => {
      if (!$0.some($2 => $2.title === $1.type)) {
        const title = $1.type
        const items = titular.accounts.filter($2 => $2.type === $1.type).filter(predicate)
        $0.push({ title, items })
      }

      return $0
    }, [] as Group<Account>[])
      .filter($0 => $0.items.length > 0)
  }, [titular])

  const amount = useMemo(() => {
    return state.payday === 'completo'
      ? titular.salary.toString()
      : (titular.salary / 2).toString()
  }, [titular, state])

  useEffect(() => {
    setTitularAccounts(reducedAccounts($0 => $0.titular_id?.toString() === state.titular_id.toString()))
  }, [titular]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setState(prev => ({
      ...prev,
      amount
    }))
  }, [titular]) // eslint-disable-line react-hooks/exhaustive-deps

  const calculateLPH = () => {
    return !titular ? 'Sin titular' : (titular?.salary || 0) * 0.01
  }

  return (
    <div className="w-full px-2 flex flex-wrap">
      <h1 className="text-2xl dark:text-gray-200 mb-2">
        {titular.name}
      </h1>
      <div className="bg-white dark:bg-gray-800 w-full flex flex-wrap p-2 rounded-2xl">
        <div className="w-full xl:w-1/3 lg:w-1/2 px-2 lg:mt-0 mt-4">
          <Select
            required
            id="account_id"
            label="Cuenta"
            disabled={!titular}
            error={error(`${index}.account_id`)}
            value={state.account_id}
            onChange={account_id => setState(prev => ({...prev, account_id: account_id as string}))}>
            <option value="">-- Cuenta --</option>
            {titularAccounts
              .map(($0, $1) => (
                <optgroup key={$1} label={$0.title}>
                  {$0.items.map(($2, $3) => (
                    <option title={$2.number.toString()} value={$2.id} key={`${$1}_${$3}`}>
                      {$2.bank?.name}
                    </option>
                  ))}
                </optgroup>
              ))}
          </Select>
        </div>
        <div className="w-full xl:w-1/3 lg:w-1/2 px-2">
          <Input
            required
            id="number"
            error={error(`${index}.number`)}
            label={`Número de ${state.transaction.toLowerCase() || 'transacción'}`}
            value={state.number}
            onChange={number => setState(prev => ({...prev, number}))} />
        </div>
        <div className="w-full xl:w-1/3 lg:w-1/2 px-2">
          <Input
            required
            id="amount"
            label="Monto"
            error={error(`${index}.amount`)}
            disabled
            value={amount} />
        </div>
        <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
          <Select
            required
            id="enterprise_id"
            error={error(`${index}.enterprise_id`)}
            label="Cuenta de la empresa"
            value={state.enterprise_id}
            onChange={enterprise_id => setState(prev => ({...prev, enterprise_id: enterprise_id as string}))}>
            <option value="">-- Cuenta --</option>
            {enterpriseAccounts
              .map(($0, $1) => (
                <optgroup key={$1} label={$0.title}>
                  {$0.items.map(($2, $3) => {
                    return (
                      <option
                        title={`${$2.titular?.name} - ${$2.number}`}
                        value={$2.id} key={`${$1}_${$3}`}>
                        {$2.bank?.name}
                      </option>
                    )
                  })}
                </optgroup>
              ))}
          </Select>
        </div>
        <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
          <Input
            id="adition"
            error={error(`${index}.adition`)}
            label="Ingreso adicional"
            value={state.adition}
            onChange={adition => setState(prev => ({...prev, adition}))} />
        </div>
        <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
          <Input
            id="adition_reason"
            error={error(`${index}.adition_reason`)}
            label="Razón del ingreso adicional"
            required={state.adition !== ''}
            disabled={state.adition === ''}
            value={state.adition_reason}
            onChange={adition_reason => setState(prev => ({...prev, adition_reason}))} />
        </div>

        <div className="w-full mt-4 lg:w-1/2 xl:w-1/4 px-2">
          <Switch.Group as="div" className="flex flex-col">
            <Switch.Label className="cursor-pointer dark:text-gray-200">L.P.H</Switch.Label>
            <div className="border dark:border-gray-700 rounded-xl p-2 bg-white dark:bg-gray-900 select-none flex items-center justify-between">
              <Switch as="button" checked={state.has.lph} onChange={lph => setState({...state, has: { ...state.has, lph }})} className={`${state.has.lph ? "bg-blue-600" : "bg-gray-200 dark:bg-gray-800"} relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}>
                {({ checked }) => (
                  <span className={`${checked ? "translate-x-5" : "translate-x-0"} inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white dark:bg-gray-900 rounded-full`} />
                )}
              </Switch>
              <span className="dark:text-gray-300">{ state.has.lph ? calculateLPH() : 0 }</span>
            </div>
          </Switch.Group>
        </div>
        <div className="w-full mt-4 lg:w-1/2 xl:w-1/4 px-2">
          <Switch.Group as="div" className="flex flex-col">
            <Switch.Label className="cursor-pointer dark:text-gray-200">Bono de alimentación</Switch.Label>
            <div className="border dark:border-gray-700 rounded-xl p-2 bg-white dark:bg-gray-900 select-none flex items-center justify-between">
            <Switch as="button" checked={state.has.bonus} onChange={bonus => setState({...state, has: { ...state.has, bonus }})} className={`${state.has.bonus ? "bg-blue-600" : "bg-gray-200 dark:bg-gray-800"} relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}>
                {({ checked }) => (
                  <span className={`${checked ? "translate-x-5" : "translate-x-0"} inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white dark:bg-gray-900 rounded-full`} />
                )}
              </Switch>
              <span className="dark:text-gray-300">{ state.has.bonus ? settings.bonus : 0 }</span>
            </div>
          </Switch.Group>
        </div>
        <div className="w-full mt-4 lg:w-1/2 xl:w-1/4 px-2">
          <Switch.Group as="div" className="flex flex-col">
            <Switch.Label className="cursor-pointer dark:text-gray-200">S.S.O</Switch.Label>
            <div className="border dark:border-gray-700 rounded-xl p-2 bg-white dark:bg-gray-900 select-none flex items-center justify-between">
            <Switch as="button" checked={state.has.sso} onChange={sso => setState({...state, has: { ...state.has, sso }})} className={`${state.has.sso ? "bg-blue-600" : "bg-gray-200 dark:bg-gray-800"} relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}>
                {({ checked }) => (
                  <span className={`${checked ? "translate-x-5" : "translate-x-0"} inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white dark:bg-gray-900 rounded-full`} />
                )}
              </Switch>
              <span className="dark:text-gray-300">{ state.has.sso ? settings.sso : 0 }</span>
            </div>
          </Switch.Group>
        </div>
        <div className="w-full mt-4 lg:w-1/2 xl:w-1/4 px-2">
          <Switch.Group as="div" className="flex flex-col">
            <Switch.Label className="cursor-pointer dark:text-gray-200">Paro forzoso</Switch.Label>
            <div className="border dark:border-gray-700 rounded-xl p-2 bg-white dark:bg-gray-900 select-none flex items-center justify-between">
            <Switch as="button" checked={state.has.lacks} onChange={lacks => setState({...state, has: { ...state.has, lacks }})} className={`${state.has.lacks ? "bg-blue-600" : "bg-gray-200 dark:bg-gray-800"} relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}>
                {({ checked }) => (
                  <span className={`${checked ? "translate-x-5" : "translate-x-0"} inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white dark:bg-gray-900 rounded-full`} />
                )}
              </Switch>
              <span className="dark:text-gray-300">{ state.has.lacks ? settings.lacks : 0 }</span>
            </div>
          </Switch.Group>
        </div>

        <div className="w-full mt-4 xl:w-1/3 px-2">
          <Input
            id="discount"
            label="Descuento"
            error={error(`${index}.discount`)}
            value={state.discount}
            onChange={discount => setState(prev => ({...prev, discount}))}/>
        </div>
        <div className="w-full mt-4 xl:w-1/3 px-2">
          <Input
            id="discount_reason"
            error={error(`${index}.discount_reason`)}
            label="Razón del descuento" value={state.discount_reason}
            onChange={discount_reason => setState(prev => ({...prev, discount_reason}))}/>
        </div>
        <div className="w-full mt-4 xl:w-1/3 px-2">
          <DatePicker id="discount_reason" label="Fecha" value={state.issued_at} onChange={([issued_at]) => setState(prev => ({...prev, issued_at}))} />
        </div>
        <div className="w-full mt-4 px-2">
          <Input
            error={error(`${index}.observation`)}
            id="observation"
            label="Observación"
            value={state.observation}
            onChange={observation => setState(prev => ({...prev, observation}))}/>
        </div>
      </div>
    </div>
  )
}

export default Forms