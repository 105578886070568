import {Role} from "~/constants";
import PaymentsScreen from "~/screens/payments";
import CreatePaymentScreen from "~/screens/payments/create";
import UpdatePaymentScreen from "~/screens/payments/update";
import {PrivateRoute} from "~/Application";
import {Switch} from "react-router-dom";

const PaymentsRouter = () => (
  <Switch>
    <PrivateRoute exact allows={[Role.admin, Role.developer]} path="/payments">
      <PaymentsScreen />
    </PrivateRoute>
    <PrivateRoute exact allows={[Role.admin, Role.developer]} path="/payments/create">
      <CreatePaymentScreen />
    </PrivateRoute>
    <PrivateRoute exact allows={[Role.admin, Role.developer]} path="/payments/:id/edit">
      <UpdatePaymentScreen />
    </PrivateRoute>

  </Switch>
)

export default PaymentsRouter