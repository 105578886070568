import { useEffect, useState } from 'react'
import { useBreadcrumbs } from '~/context/breadcrumb'
import { Table, DeleteModal, Dropdown, DropdownItem } from '~/components/ui';
import { useRepository } from '~/components/hooks'
import { Pagination, User } from '~/api/models'
import { BsCheck } from 'react-icons/bs'
import { FaBan } from 'react-icons/fa'
import BoundUsersModal from './components/BoundUsersModal'

const Screen = () => {

  const { setBreadcrumb } = useBreadcrumbs()
  const [query] = useState({ limit: 10, page: 1, sort_by: 'name,asc' })
  const [modals, setModals] = useState({ create: false, remove: false, update: false, accounts: false })
  const [selected, setSelected] = useState<User | undefined>(undefined)
  const [pagination, setPagination] = useState<Pagination<User>>({ data: [] })
  const [loadings, setLoading] = useState({ index: false, remove: false })
  const { fetchUsers, removeUser } = useRepository(({ users }) => ({
    fetchUsers: users.index,
    removeUser: users.remove
  }))

  const [bounding, setBounding] = useState<User | undefined>()

  useEffect(() => {
    fetch()
    setBreadcrumb([
      { title: 'Listado de usuarios', to: '/users', loading: false },
    ])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const select = (item: User, cb: () => void) => {
    setSelected(item)
    cb()
  }

  const fetch = (filters = {}) => {

    setLoading(prev => ({...prev, index: !prev.index}))

    fetchUsers<Pagination<User>>({...query, ...filters})
      .then(pagination => new Pagination<User>(pagination, $0 => new User($0)))
      .then(pagination => setPagination(pagination))
      .finally(() => setLoading(prev => ({...prev, index: !prev.index})))
  }

  const handleRemove = () => {
    setLoading(prev => ({...prev, remove: !prev.remove}))
    removeUser(selected!.id)
      .then(() => {
        fetch({ page: 1 })
        setModals(prev => ({...prev, remove: false}))
      })
      .finally(() => setLoading(prev => ({...prev, remove: !prev.remove})))
  }

  return (
    <>
      <div className="w-full p-2">
        <div className="flex items-end flex-wrap justify-between mb-4">
          <h1 className="text-2xl dark:text-gray-200">Listado de usuarios</h1>
        </div>

        <Table
          columnFilter
          onSearch={search => fetch({ search, page: 1 })}
          loading={loadings.index}
          source={pagination.data}
          pagination={pagination}
          onPageChange={page => fetch({ page })}
          onRefresh={() => fetch({ page: 1 })}
          columns={[
            { title: 'Nombre', path: 'name' },
            { title: 'Correo electrónico', path: 'email' },
            { title: 'Enlazado', path: 'is_bound', td: ({ item }) => item.is_bound
                ? <BsCheck className="text-green-500 text-2xl" />
                : <FaBan className="text-red-500 text-2xl" /> },
            { title: 'Telegram', path: 'simgulary_bot_id', td: ({ item }) => item.simgulary_bot_id
                ? <BsCheck className="text-green-500 text-2xl" />
                : <FaBan className="text-red-500 text-2xl" /> },
            { title: 'Titular', path: 'titular.name' },
            { title: 'Acciones', path: 'id', td: ({ item }) => (
                <Dropdown right>
                  <DropdownItem onClick={() => setBounding(item)}>
                    {item.is_bound ? 'Cambiar titular' : 'Enlazar'}
                  </DropdownItem>
                  <hr/>
                  <DropdownItem onClick={() => select(item, () => setModals(prev => ({...prev, remove: true})))}>
                    Eliminar
                  </DropdownItem>
                </Dropdown>
              ) },
          ]} />
      </div>

      <DeleteModal
        show={modals.remove}
        title="Eliminar titular"
        heading="Un momento!! esta seguro de que desea eliminar el titular: "
        subheading={pagination.data.find($0 => $0.id === selected?.id)?.name ?? 0}
        loading={loadings.remove}
        onConfirm={handleRemove}
        onCancel={() => setModals(prev => ({...prev, remove: !prev.remove}))} />

      <BoundUsersModal
        bounding={bounding}
        onClose={() => setBounding(undefined)}
        onBound={(user: User) => {
          fetch()
          setBounding(prev => ({...prev, ...user}))
        }}
      />
    </>
  )
}

export default Screen
