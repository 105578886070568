import {useEffect, useState} from 'react';
import {
  Table,
  DatePicker,
  DeleteModal,
  Dropdown,
  DropdownItem,
  Button,
  TitlePortal,
  Portal
} from '~/components/ui';
import {useRepository} from '~/components/hooks';
import {useBreadcrumbs} from '~/context/breadcrumb'
import {Pagination, Payment} from '~/api/models'

const PaymentsScreen = () => {

    const { setBreadcrumb } = useBreadcrumbs()
    const [query, setQuery] = useState({ limit: 10, page: 1, with: 'account.bank,enterprise.bank,titular', startDate: null, endDate: null, sort_by: 'created_at,desc' })
    const [modals, setModals] = useState({ create: false, remove: false, assign: false })
    const [selected, setSelected] = useState<Payment | undefined>(undefined)
    const [pagination, setPagination] = useState<Pagination<Payment>>({ data: [] })
    const [loadings, setLoading] = useState({ index: false, remove: false })
    const { fetchPayments, removePayment, paymentReportURL, paymentsReportsURL } = useRepository(({ payments, reports }) => ({
        fetchPayments: payments.index,
        removePayment: payments.remove,
        paymentReportURL: reports.payment,
        paymentsReportsURL: reports.payments,
    }))

    useEffect(() => {
        fetch()
        setBreadcrumb([
            { title: 'Listado de órdenes personales', to: '/payments', loading: false },
        ])
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const select = (item: Payment, cb: () => void) => {
        setSelected(item)
        cb()
    }

    const fetch = (filters = {}) => {

        setLoading(prev => ({...prev, index: !prev.index}))
        const _query = {...query, ...filters}

        fetchPayments<Pagination<Payment>>(_query)
            .then(setPagination)
            .then(() => setQuery(_query))
            .finally(() => setLoading(prev => ({...prev, index: !prev.index})))
    }

    const handleRemove = () => {
        setLoading(prev => ({...prev, remove: !prev.remove}))
        removePayment(selected!.id)
            .then(() => {
                fetch({ page: 1 })
                setModals(prev => ({...prev, remove: false}))
            })
            .finally(() => setLoading(prev => ({...prev, remove: !prev.remove})))
    }

  return (
    <>
      <TitlePortal>
        Listado de órdenes personales
      </TitlePortal>
      <Portal target="#main_action">
        <Button variant="primary" to="/payments/create">
          Crear orden
        </Button>
      </Portal>
      <div className="w-full p-2">
        <Table
          columnFilter
          onSearch={search => fetch({ search, page: 1 })}
          loading={loadings.index}
          source={pagination.data}
          pagination={pagination}
          onPageChange={page => fetch({ page })}
          onRefresh={() => fetch({ page: 1 })}
          toolbar={{
            right: (query.startDate && query.endDate && !pagination.isEmpty) && (
              <a rel="noreferrer" target="_blank" href={paymentsReportsURL({ startDate: query.startDate, endDate: query.endDate })} className="border flex items-center justify-content rounded-md px-2 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200">
                Descargar resultados
              </a>
            )
          }}
          columns={[
            {title: 'Fecha de creación', path: 'issued_at', filterField: () => (
                <DatePicker range onChange={([startDate, endDate]) => fetch({ startDate: startDate && `issued_at,${startDate}`, endDate: endDate && `issued_at,${endDate}` })} />
            )},
            {title: 'Mes', path: 'month', visible: false},
            {title: 'Para', path: 'titular.name'},
            {title: 'A la cuenta', path: 'account.number', td: ({ item })  => item.account?.number ?? <span className="text-gray-600">--No asociado--</span>},
            {title: 'Desde la cuenta', path: 'enterprise.number'},
            {title: 'Monto', path: 'amount'},
            {title: 'Medio', path: 'transaction'},
            {title: 'Acciones', path: 'id', td: ({ item }) => (
              <Dropdown right>
                <DropdownItem target="_blank" href={paymentReportURL(item.id)}>
                  Generar
                </DropdownItem>
                <DropdownItem href={`/payments/${item.id}/edit`}>
                  Editar
                </DropdownItem>
                <DropdownItem onClick={() => select(item, () => setModals(prev => ({...prev, remove: true})))}>
                  Eliminar
                </DropdownItem>
              </Dropdown>
            ), columnFilterable: false },
          ]} />
      </div>

      <DeleteModal
        show={modals.remove}
        title="Eliminar órden de nómina"
        heading="Un momento!! ¿está seguro de que desea cotinuar?"
        subheading={
          <span>está por eliminar una orden de la fecha {selected?.issued_at} para <b>{selected?.titular?.name}</b> por un monto de {selected?.amount}</span>
        }
        loading={loadings.remove}
        onConfirm={handleRemove}
        onCancel={() => setModals(prev => ({...prev, remove: !prev.remove}))} />
    </>
  )
}

export default PaymentsScreen
