import { useEffect, useState } from 'react'
import { Table, DatePicker } from '~/components/ui'
import { useRepository } from '~/components/hooks'
import { useParams } from 'react-router-dom'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { Paysheet, Pagination, Titular } from '~/api/models'
import {useBreadcrumbs} from "~/context/breadcrumb";

const Paysheets = ({ titular }: { titular?: Titular }) => {

    const { setBreadcrumb } = useBreadcrumbs()
    const { id } = useParams<{ id: string }>()
    const [query, setQuery] = useState({ limit: 10, page: 1, titular: id, with: 'account.bank', startDate: null, endDate: null })
    const {fetchPaysheets, paysheetReportURL, paysheetsReportsURL} = useRepository(({ paysheets, reports }) => ({
        fetchPaysheets: paysheets.index,
        paysheetReportURL: reports.paysheet,
        paysheetsReportsURL: reports.paysheets
    }))
    const [pagination, setPagination] = useState<Pagination<Paysheet>>({ data: [] })
    const [loadings, setLoadings] = useState({ index: false })

    const fetch = (_filters = {}) => {
        const _query = {...query, ..._filters}
        setLoadings(prev => ({...prev, index: !prev.index}))
        fetchPaysheets<Pagination<Paysheet>>(_query)
            .then(pagination => new Pagination<Paysheet>(pagination, $0 => new Paysheet($0)))
            .then(pagination => setPagination(pagination))
            .then(() => setQuery(_query))
            .finally(() => setLoadings(prev => ({...prev, index: !prev.index})))
    }

  useEffect(() => {
    setBreadcrumb([
      { title: 'Listado de titulares', to: '/titulars', loading: false },
      { title: titular?.name ?? '', to: `/titulars/${titular?.id}`, loading: !titular },
      { title: 'Nóminas', to: `/titulars/${id}/paysheets`, loading: false }
    ])

    return () => setBreadcrumb([])
  }, [titular]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <div className="flex items-end flex-wrap justify-between mb-4">
                <h1 className="text-2xl dark:text-gray-200">Órdenes de nómina</h1>
            </div>

            <Table
                columnFilter
                onSearch={search => fetch({ search })}
                loading={loadings.index}
                onRefresh={fetch}
                pagination={pagination}
                onPageChange={page => fetch({ page })}
                source={pagination.data}
                toolbar={{
                    right: (query.startDate && query.endDate && !pagination.isEmpty) && (
                        <a rel="noreferrer" target="_blank" href={paysheetsReportsURL({ startDate: query.startDate, endDate: query.endDate, titular: query.titular })} className="border flex items-center justify-content rounded-md px-2   ">
                            Descargar resultados
                        </a>
                    )
                }}
                columns={[
                    { title: 'Tipo', path: 'transaction' },
                    { title: 'Destino', path: 'account.bank.name' },
                    { title: 'Fecha', path: 'issued_at', filterField: () => (
                        <DatePicker range onChange={([startDate, endDate]: string[]) => fetch({ startDate: startDate && `issued_at,${startDate}`, endDate: endDate && `issued_at,${endDate}` })} />
                    )},
                    { title: 'Total', path: 'total' },
                    { title: 'Descargar', path: 'id', td: ({ item }) => (
                        <a rel="noreferrer" target="_blank" href={`${paysheetReportURL(item.id)}`}>
                            <AiOutlineFilePdf className="text-xl text-purple-500" />
                        </a>
                    )}
                ]} />
        </>
    )
}

export default Paysheets
