import {Fragment, FunctionComponent} from 'react';
import { Dropdown, DropdownItem, CircleLoader } from '~/components/ui'
import {IBreadcrumbSegment, useBreadcrumbs} from '~/context/breadcrumb'
import {BsLayoutSidebar} from 'react-icons/bs'
import {NavLink, useHistory} from 'react-router-dom';
import classnames from "classnames";
import {useSession} from "~/context/session";
import {AiOutlineEllipsis} from "react-icons/ai";

interface IBreadcrumbProps {
  segments: IBreadcrumbSegment[]
  className?: string
}

const Breadcrumbs: FunctionComponent<IBreadcrumbProps> = ({ segments, className }) => (
  <ul className={classnames('flex items-center', className)}>
    <li className="pl-2">
      {segments.map(($0, $1, self) => {
        if ($0.loading) {
          return (
            <Fragment key={$1}>
              {$0.customLoading ?? <CircleLoader className="h-4 w-4 inline-block mx-1 text-blue-400" />}

              {$1 + 1 !== self.length && (<span className="inline-block px-1 pr-2 text-sm">»</span>)}
            </Fragment>
          )
        }

        if ($1 + 1 !== self.length) {
          return (
            <Fragment key={$1}>
              <NavLink className="text-blue-400 text-sm" to={$0.to}>{$0.title}</NavLink>
              <span className="inline-block px-2 text-sm dark:text-gray-200">»</span>
            </Fragment>
          )
        }

        return <span key={$1} className="text-gray-600 dark:text-gray-200 text-sm">{$0.title}</span>
      })}
    </li>
  </ul>
)

interface IHeaderProps {
  onSidebarToggle: () => void
}

const Header: FunctionComponent<IHeaderProps> = ({ onSidebarToggle }) => {

  const { segments } = useBreadcrumbs()
  const { removeUser, session } = useSession()
  const router = useHistory()

  const logout = () => {
    removeUser()
    router.push('/')
  }

  return (
    <nav className="bg-white dark:bg-gray-900 flex flex-col items-center right-0 left-0 lg:left-52 z-10">
      <div className="h-16 border-b dark:border-gray-700 w-full flex justify-between items-center">
        <div className="flex justify-center items-center">
          <button className="p-2 rounded-full items-center flex space-x-2 focus:outline-none" onClick={onSidebarToggle}>
            <BsLayoutSidebar className="text-xl dark:text-gray-200 lg:hidden" />
            <div id="title_header" />
          </button>
        </div>
        <ul className="flex-grow flex justify-end">
          <li className="px-4 py-2 float-left flex items-center">
            <Dropdown right label={() => (
              <div className="flex space-x-2 items-start">
                <div className={['mr-2 flex items-center justify-center h-10 w-10 rounded-full overflow-hidden', 'from-blue-400 to-blue-800 bg-gradient-to-br'].join(' ')}>
                  <span className="text-2xl text-white">
                    {session?.name.slice(0, 1)}
                  </span>
                </div>
              </div>
            )}>
              <DropdownItem onClick={logout}>
                Cerrar sesión
              </DropdownItem>
            </Dropdown>
          </li>
        </ul>
      </div>
      <div className="h-14 border-b dark:border-gray-700 px-2 w-full items-center justify-between flex">
        <Breadcrumbs segments={segments} className="hidden lg:block" />
        {segments.length >= 2 ? (
          <Dropdown label={() => (
            <div className="lg:hidden flex space-x-1">
              <AiOutlineEllipsis className="text-xl transform" />
              <span>{segments[segments.length - 1].title}</span>
            </div>
          )}>
            {segments.filter((_, index, self) => index !== self.length -1)
              .map((segment, key) => (
              <DropdownItem key={key} to={segment.to}>
                {segment.title}
              </DropdownItem>
            ))}
          </Dropdown>
        ) : <span />}

        <div id="main_action" />
      </div>
    </nav>
  )
}

export default Header
