import Repository from '../contracts/Repository'
import RepositoryContext from '../contracts/RepositoryContext';
import { AxiosResponse } from 'axios'
import { Payment } from '~/api/models'

interface ICreatePaymentBody {}

export interface IPaymentEndpointsContext extends Repository<Payment, ICreatePaymentBody, ICreatePaymentBody> {}

const endpoints = ({ axios, mapQueryString }: RepositoryContext): IPaymentEndpointsContext => ({
    index: <T>(query: {}) => axios.get(`/api/payments${mapQueryString(query)}`).then(({ data }: AxiosResponse<T>) => data),
    show: (id: number, query: {}) => axios.get(`/api/payments/${id}${mapQueryString(query)}`).then(({ data }: AxiosResponse<Payment>) => data),
    create: (body: ICreatePaymentBody) => axios.post(`/api/payments`, body).then(({ data }: AxiosResponse<Payment>) => new Payment(data)),
    update: (id: number, body: ICreatePaymentBody) => axios.patch(`/api/payments/${id}`, body).then(({ data }: AxiosResponse<Payment>) => new Payment(data)),
    remove: (id: number) => axios.delete(`/api/payments/${id}`)
})


export default endpoints