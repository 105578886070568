import { Preferences } from '.'
import Role from './Role'

export enum RoleType {
    admin = 1,
    developer,
    employee
}

class User {
    id!: number
    name!: string
    email!: string
    role_id!: RoleType
    role!: Role
    type!: RoleType
    simgulary_bot_id?: number | string
    birth_date?: string
    is_bound!: boolean
    isAdmin!: boolean
    isDeveloper!: boolean
    isEmployee!: boolean
    preferences?: Preferences

    constructor(user: User) {
        Object.assign(this, user)
        this.role = new Role(user.role)
        this.isAdmin = user.role_id === 1
        this.isDeveloper = user.role_id === 2
        this.isEmployee = user.role_id === 3
        this.preferences = user.preferences
    }
}

export default User