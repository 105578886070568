import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { baseURL } from '~/constants'

const onRequest = (config: AxiosRequestConfig) => {

    const token = localStorage.token
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
}
const onResponse = <T>(response: AxiosResponse<T>) => response

const onRequestError = (error: any) => Promise.reject(error)
const onResponseError = (error: any) => Promise.reject(error)

const useConfiguredAxios = () => {

    const _axios = axios.create({
        baseURL: baseURL,
        // withCredentials: true
    })

    _axios.interceptors.request.use(onRequest, onRequestError)
    _axios.interceptors.response.use(onResponse, onResponseError)

    return _axios
}

export default useConfiguredAxios