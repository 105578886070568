import {Role} from "~/constants";
import TitularsScreen from "~/screens/titulars";
import TitularScreen from "~/screens/titulars/show";
import {PrivateRoute} from "~/Application";
import {Switch} from "react-router-dom";

const TitularsRouter = () => (
  <Switch>
    <PrivateRoute exact allows={[Role.admin, Role.developer]} path="/titulars">
      <TitularsScreen />
    </PrivateRoute>
    <PrivateRoute allows={[Role.admin, Role.developer]} path="/titulars/:id">
      <TitularScreen />
    </PrivateRoute>
  </Switch>
)

export default TitularsRouter