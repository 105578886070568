import { MouseEvent, useMemo } from 'react'
import classnames from "classnames";

interface IPaginationProps {
  current: number
  total: number
  perPage: number
  pageRange: number
  onChange: (_: number) => void
}

const Pagination = ({ onChange = () => {}, current, total, perPage, pageRange }: IPaginationProps) => {

  const changePage = (event: MouseEvent<HTMLButtonElement>, page: any) => {
    event.preventDefault()

    if (page > 0 && page <= totalPages) {
      onChange?.(page)
    }
  }

  const hasPrev = useMemo(() => {
    return current > 1
  }, [current])

  const rangeStart = useMemo(() => {
    let start = current - pageRange
    return (start > 0) ? start : 1
  }, [current, pageRange])

  const totalPages = useMemo(() => {
    return Math.ceil(total / perPage)
  }, [total, perPage])

  const rangeEnd = useMemo(() => {
    let end = current + pageRange
    return (end < totalPages) ? end : totalPages
  }, [current, pageRange, totalPages])

  const nextPage = useMemo(() => {
    return current + 1
  }, [current])

  const prevPage = useMemo(() => {
    return current - 1
  }, [current])

  const hasNext = useMemo(() => {
    return current < totalPages
  }, [current, totalPages])

  const pages = useMemo(() => {
    let pages = []
    for (let i = rangeStart; i <= rangeEnd; i++) {
      pages.push(i)
    }
    return pages
  }, [rangeStart, rangeEnd])

  const hasFirst = useMemo(() => {
    return rangeStart !== 1
  }, [rangeStart])

  const hasLast = useMemo(() => {
    return rangeEnd < totalPages
  }, [rangeEnd, totalPages])

  return (
    <div className="min-w-max">
      <section className="flex justify-between bg-white rounded-lg border border-gray-200 px-10 py-3 text-gray-700 font-montserrat">
        <ul className="flex items-center">
          {hasPrev && (
            <li className="pr-6 text-white text-sm">
              <button type="button" onClick={(event) => changePage(event, prevPage)}>
                <div className="flex items-center justify-center bg-indigo-300 hover:bg-blue-400 rounded-md transform rotate-45 h-6 w-6">
                  <div className="transform -rotate-45">
                    <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"/>
                    </svg>
                  </div>
                </div>
              </button>
            </li>
          )}
          {hasFirst && (
            <li className="pr-6 text-white text-sm">
              <button type="button" onClick={(event) => changePage(event, 1)}>
                <div className="flex bg-gradient-to-r from-blue-200 to-indigo-200 hover:from-blue-300 hover:to-indigo-300 rounded-md transform rotate-45 h-6 w-6 items-center justify-center">
                  <span className="transform -rotate-45">
                      1
                  </span>
                </div>
              </button>
            </li>
          )}
          {hasFirst && (
            <li className="pr-6">...</li>
          )}

          {pages.map((page, key) => (
            <li className="pr-6 text-white text-sm" key={key}>
              <button type="button" onClick={(event) => changePage(event, page)}>
                <div className={classnames('flex bg-gradient-to-r from-blue-200 to-indigo-200 hover:from-blue-300 hover:to-indigo-300 rounded-md transform rotate-45 h-6 w-6 items-center justify-center', {
                  'bg-gradient-to-r from-blue-400 to-indigo-400': current === page
                })}>
                  <span className="transform -rotate-45">
                    { page }
                  </span>
                </div>
              </button>
            </li>
          ))}
          {hasLast && (
            <li className="pr-6">...</li>
          )}
          {hasLast && (
            <li className="pr-6 text-white text-sm">
              <button type="button" onClick={(event) => changePage(event, totalPages)}>
                <div className="flex bg-gradient-to-r from-blue-200 to-indigo-200 hover:from-blue-300 hover:to-indigo-300 rounded-md transform rotate-45 h-6 w-6 items-center justify-center">
                  <span className="transform -rotate-45">
                      { totalPages }
                  </span>
                </div>
              </button>
            </li>
          )}
          {hasNext && (
            <li className="pr-6 text-white text-sm">
              <button type="button" onClick={(event) => changePage(event, nextPage)}>
                <div className="flex items-center justify-center bg-indigo-300 hover:bg-blue-400 rounded-md transform rotate-45 h-6 w-6">
                  <div className="transform -rotate-45">
                    <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
                    </svg>
                  </div>
                </div>
              </button>
            </li>
          )}
        </ul>

        <div className="flex items-center">
          <div className="pr-2 text-gray-400 font-medium">
            <span id="text-before-input">
              Ir a la página
            </span>
          </div>
          <div className="w-14 rounded-md border border-indigo-400 px-1 py-1">
            <input className="w-12 focus:outline-none px-2" type="text" />
          </div>
          <button type="button" onClick={(event) => changePage(event, 2)} className="flex items-center pl-4 font-medium cursor-pointer">
            <span id="text-after-input">
                ir
            </span>
            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
            </svg>
          </button>
        </div>
      </section>
    </div>
  )

  // const generatePagination = () => {
  //
  //     const { current_page, last_page, next_page_url, prev_page_url } = pagination
  //
  //     if (current_page && last_page) {
  //         const delta = 1
  //         const right = current_page + delta + 1
  //         const left = current_page - delta
  //         let range = []
  //         let rangeWithDots = []
  //         let last;
  //
  //         for (let index = 1; index <= last_page; index++) {
  //             if ((index === 1 || index === last_page) || (index >= left && index < right)) {
  //                 range.push(index);
  //             }
  //         }
  //
  //         for (let index of range) {
  //             if (last) {
  //                 if (index - last === 2) {
  //                     rangeWithDots.push(last + 1);
  //                 } else if (index - last !== 1) {
  //                     rangeWithDots.push('...');
  //                 }
  //             }
  //             rangeWithDots.push(index);
  //             last = index;
  //         }
  //
  //         return (
  //             <ul className={styles.container}>
  //                 {prev_page_url && (
  //                     <li className={styles.listItem} onClick={(event) => handleClick(event, current_page - 1)}>
  //                         <span className={styles.buttonItem}>
  //                             Anterior
  //                         </span>
  //                     </li>
  //                 )}
  //
  //                 {rangeWithDots.map((page, index) => (
  //                     (
  //                         <li className={styles.listItem} key={index}>
  //                             <button
  //                                 className={page === current_page ? styles.activeButtonItem : styles.buttonItem}
  //                                 onClick={(event) => handleClick(event, page === '...' ? index + 1 : page, current_page)}>
  //                                 {page}
  //                             </button>
  //                         </li>
  //                     )
  //                 ))}
  //
  //                 {next_page_url && (
  //                     <li className={styles.listItem} onClick={(event) => handleClick(event, current_page + 1)}>
  //                         <span className={styles.buttonItem}>
  //                             Siguiente
  //                         </span>
  //                     </li>
  //                 )}
  //             </ul>
  //         )
  //     }
  //
  //     return <>Invalid pagination</>
  // }
  //
  // const handleClick = (event: MouseEvent, page: string | number, current_page?: number | null) => {
  //     event.preventDefault()
  //     if (current_page !== page) {
  //         onChange?.(page as number)
  //     }
  // }
  //
  // return (
  //     <div className={className}>{generatePagination()}</div>
  // )
}

export default Pagination;