import { useRef, useEffect, useState, FunctionComponent } from 'react'
import { createPortal } from 'react-dom'

const Portal: FunctionComponent<{ target: string }> = ({ children, target }) => {
  const ref = useRef<HTMLDivElement>()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector(target) as HTMLDivElement
    setMounted(true)
  }, [target])

  return mounted && ref.current ? createPortal(children, ref.current) : null
}

export default Portal