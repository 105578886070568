import {useEffect} from 'react'
import {NavLink, useRouteMatch, Switch} from 'react-router-dom'
import {useBreadcrumbs} from '~/context/breadcrumb'
import SalariesScreen from './salaries'
import AccountConfigurationScreen from './account'
import AnnouncementsConfigurationScreen from './announcements'
import PreferencesScreen from './preferences'
import {PrivateRoute} from "~/Application";
import {Role} from "~/constants";
import {useSession} from "~/context/session";
import {TitlePortal} from "~/components/ui";
import UsersScreen from './users'

const LinkItem = ({ to, label }: { to: string, label: string }) => (
  <li className="text-gray-700 text-sm">
    <NavLink exact activeClassName="bg-blue-500 text-white hover:bg-opacity-80" className="hover:bg-blue-500 hover:bg-opacity-25 p-2 block rounded-xl" to={to}>
      { label }
    </NavLink>
  </li>
)

const getView = (url: string, id?: number) => {
  const role = id ?? 0
  const {admin, developer, employee} = Role

  return [
    {for: [admin, developer, employee], component: <LinkItem to={`${url}`} label="Mi cuenta"/>},
    {for: [admin, developer], component: <LinkItem to={`${url}/salary`} label="Salarios"/>},
    {for: [admin, developer], component: <LinkItem to={`${url}/users`} label="Usuarios"/>},
    {for: [admin, developer], component: <LinkItem to={`${url}/announcements`} label="Anuncios"/>},
    {for: [admin, developer, employee], component: <LinkItem to={`${url}/preferences`} label="Preferencias"/>},
  ].filter(view => view.for.includes(role))
    .map((view, key) => ({...view.component, key}))
}

const SettingsScreen = () => {

  let {path, url} = useRouteMatch()
  const { setBreadcrumb } = useBreadcrumbs()
  const {session} = useSession()

  useEffect(() => {
    setBreadcrumb([
      { title: 'Configuraciones', to: '/settings' }
    ])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TitlePortal>
        Configuración
      </TitlePortal>
      <div className="flex w-full flex-wrap flex-col md:flex-row md:flex-nowrap divide-x dark:divide-gray-700 flex-grow">
        <div className="w-full md:w-56 px-2 flex-shrink-0">
          <ul className="space-y-1 py-2 w-full">
            {getView(url, session?.type)}
          </ul>
        </div>
        <div className="flex-grow p-2">
          <Switch>
            <PrivateRoute exact path={path}>
              <AccountConfigurationScreen />
            </PrivateRoute>
            <PrivateRoute redirectsTo="/settings" allows={[Role.admin, Role.developer]} exact path={`${path}/salary`}>
              <SalariesScreen />
            </PrivateRoute>
            <PrivateRoute exact redirectsTo="/settings" allows={[Role.admin, Role.developer]} path={`${path}/users`}>
              <UsersScreen />
            </PrivateRoute>
            <PrivateRoute exact redirectsTo="/settings" allows={[Role.admin, Role.developer]} path={`${path}/announcements`}>
              <AnnouncementsConfigurationScreen />
            </PrivateRoute>
            <PrivateRoute exact path={`${path}/preferences`}>
              <PreferencesScreen />
            </PrivateRoute>
          </Switch>
        </div>
      </div>
    </>
  )
}

export default SettingsScreen
