import RepositoryContext from '../contracts/RepositoryContext';
import { baseURL } from '~/constants';
interface IReportsQueryString {
    startDate?: null | string
    endDate?: null | string
    titular?: null | string | number
    account?: null | string | number
}

export interface IReportsEndpointContext {
    paysheet: (_: number) => string,
    paysheets: (_: IReportsQueryString) => string,
    payment: (_: number) => string,
    payments: (_: IReportsQueryString) => string,
    income: (_: number) => string,
    incomes: (_: IReportsQueryString) => string,
    transfer: (_: number) => string,
    transfers: (_: IReportsQueryString) => string,
}

const endpoints = ({ mapQueryString, getToken }: RepositoryContext): IReportsEndpointContext => ({
    paysheet: (id: number) => `${baseURL}/api/reports/paysheets/${id}${mapQueryString({ token: getToken() })}`,
    paysheets: (query: {}) => `${baseURL}/api/reports/paysheets${mapQueryString({ ...query, token: getToken() })}`,
    payment: (id: number) => `${baseURL}/api/reports/payments/${id}${mapQueryString({ token: getToken() })}`,
    payments: (query: {}) => `${baseURL}/api/reports/payments${mapQueryString({ ...query, token: getToken() })}`,
    income: (id: number) => `${baseURL}/api/reports/incomes/${id}${mapQueryString({ token: getToken() })}`,
    incomes: (query: {}) => `${baseURL}/api/reports/incomes${mapQueryString({ ...query, token: getToken() })}`,
    transfer: (id: number) => `${baseURL}/api/reports/transfers/${id}${mapQueryString({ token: getToken() })}`,
    transfers: (query: {}) => `${baseURL}/api/reports/transfers${mapQueryString({ ...query, token: getToken() })}`,
})


export default endpoints