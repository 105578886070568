class Pagination<Model> {
    data: Model[] = []
    current_page?: number
    last_page?: number
    next_page_url?: string
    prev_page_url?: string
    per_page?: number
    total?: number

    isEmpty?: boolean

    constructor(map: Pagination<Model>, mapFunction: (_: Model) => Model) {
        this.data = map.data.map(mapFunction)
        this.current_page = map.current_page
        this.last_page = map.last_page
        this.next_page_url = map.next_page_url
        this.prev_page_url = map.prev_page_url
        this.per_page = map.per_page
        this.total = map.total

        Object.defineProperty(this, 'isEmpty', {
            get() {
                return this.data.length === 0
            }
        });

        // this.isEmpty = map.data.length > 0
    }

    // public get isEmpty(): boolean {
    //     return this.data?.length > 0
    // }
}

export default Pagination