import {useEffect, useState} from 'react';
import {useBreadcrumbs} from '~/context/breadcrumb';
import {Table, Select, DeleteModal, Dropdown, DropdownItem, Button, TitlePortal, Portal} from '~/components/ui';
import {useRepository} from '~/components/hooks'
import {Pagination, Titular} from '~/api/models';
import CreateTitularModal from './modals/create'


const TitularsScreen = () => {

  const { setBreadcrumb } = useBreadcrumbs()
  const [query] = useState({ limit: 10, page: 1, withCount: 'accounts', sort_by: 'name,asc' })
  const [pagination, setPagination] = useState<Pagination<Titular>>({ data: [] })
  const [loadings, setLoading] = useState({ index: false, remove: false })
  const { fetchTitulars, removeTitular } = useRepository(({ titulars }) => ({
    fetchTitulars: titulars.index,
    removeTitular: titulars.remove
  }))
  const [modals, setModals] = useState({ remove: false, create: false })
  const [selected, setSelected] = useState<Titular | undefined>(undefined)

  useEffect(() => {
    fetch()

    setBreadcrumb([
      { title: 'Listado de titulares', to: '/titulars', loading: false },
    ])

    return () => setBreadcrumb([])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const select = (item: Titular, cb: () => void) => {
    setSelected(item)
    cb()
  }

  const fetch = (filters = {}) => {

    setLoading(prev => ({...prev, index: !prev.index}))

    fetchTitulars<Pagination<Titular>>({...query, ...filters})
      .then(pagination => new Pagination<Titular>(pagination, $0 => new Titular($0)))
      .then(pagination => setPagination(pagination))
      .finally(() => setLoading(prev => ({...prev, index: !prev.index})))
  }

    const deleteTitular = () => {
      setLoading(prev => ({...prev, remove: !prev.remove}))
      removeTitular(selected!.id)
        .then(() => {
          fetch({ page: 1 })
          setModals(prev => ({...prev, remove: false}))
        })
        .finally(() => setLoading(prev => ({...prev, remove: !prev.remove})))
    }

    return (
      <>
        <TitlePortal>
          Listado de titulares
        </TitlePortal>
        <Portal target="#main_action">
          <Button variant="primary" onClick={() => setModals(prev => ({...prev, create: true}))}>
            Nuevo titular
          </Button>
        </Portal>

        <div className="w-full p-2">
          <Table
            columnFilter
            onSearch={search => fetch({ search, page: 1 })}
            loading={loadings.index}
            source={pagination.data}
            pagination={pagination}
            onPageChange={page => fetch({ page })}
            onRefresh={() => fetch({ page: 1 })}
            columns={[
              { title: 'Nombre', path: 'name' },
              { title: 'CI/RIC', path: 'ric' },
              { title: 'Ámbito', path: 'scope', filterField: () => (
                <Select loading={loadings.index} onChange={scope => fetch({ scope })}>
                  <option value="">-- TODOS --</option>
                  <option value="Empresa">Empresa</option>
                  <option value="Empleado">Empleado</option>
                  <option value="Externo">Externo</option>
                </Select>
              )},
              { title: 'Tipo', path: 'type', filterField: () => (
                <Select loading={loadings.index} onChange={type => fetch({ type })}>
                  <option value="">-- TODOS --</option>
                  <option value="Natural">Natural</option>
                  <option value="Jurídico">Jurídico</option>
                </Select>
              )},
              { title: 'Cuentas', path: 'accounts_count' },
              { title: 'Acciones', path: 'id', columnFilterable: false, td: ({ item }) => (
                <Dropdown right>
                  <DropdownItem to={`/titulars/${item.id}`}>Ver detalles</DropdownItem>
                  <DropdownItem to={`/titulars/${item.id}/accounts`}>Ver cuentas bancarias</DropdownItem>
                  <DropdownItem to={`/titulars/${item.id}/paysheets`}>Ver órdenes de nómina</DropdownItem>
                  <DropdownItem to={`/titulars/${item.id}/payments`}>Ver órdenes de pago</DropdownItem>
                  <hr />
                  <DropdownItem onClick={() => select(item, () => setModals(prev => ({...prev, remove: true})))}>
                    Eliminar
                  </DropdownItem>
                </Dropdown>
              )},
            ]} />
        </div>

        <DeleteModal
          show={modals.remove}
          title="Eliminar titular"
          heading="Un momento!! esta seguro de que desea eliminar el titular: "
          subheading={pagination.data.find($0 => $0.id === selected?.id)?.name}
          loading={loadings.remove}
          onConfirm={deleteTitular}
          onCancel={() => setModals(prev => ({...prev, remove: !prev.remove}))} />

        <CreateTitularModal
          show={modals.create}
          onCreated={() => {
            fetch({ page: 1 })
            setModals(prev => ({...prev, create: false}))
          }}
          onCancel={() => setModals(prev => ({...prev, create: false}))}/>
      </>
  )
}

export default TitularsScreen
