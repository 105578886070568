import {useState, useEffect, FormEvent} from 'react'
import { Bank } from '~/api/models'
import { Modal, Input } from '~/components/ui'
import { useError, useRepository } from '~/components/hooks'

interface IUpdateBankModal {
    show: boolean
    bank: Bank | undefined
    onUpdated: (_: Bank) => void
    onCancel: () => void
    onFinish?: () => void
}

const UpdateBankModal = ({ show, bank, onCancel, onUpdated, onFinish }: IUpdateBankModal) => {

    const [state, setState] = useState<{index: number | string, name: string }>({ index: '', name: '' })
    const [loading, setLoading] = useState(false)
    const { error, setErrors } = useError()
    const update = useRepository(({ banks }) => banks.update)

    useEffect(() => {
        setState({
            index: bank?.index ?? '',
            name: bank?.name ?? ''
        })
    }, [bank])

    const handleStore = (event?: FormEvent) => {
        event?.preventDefault()

        setLoading(prev => !prev)
        update(bank!.id, state)
            .then(bank => onUpdated(bank))
            .catch(setErrors())
            .finally(() => {
                setLoading(prev => !prev)
                onFinish?.()
            })
    }

    return (
        <Modal
            show={show}
            title="Actualizar banco"
            confirmText={loading ? 'Procesando...' : 'Guardar'}
            onConfirm={handleStore}
            onCancel={onCancel}>
            <form className="w-full" onSubmit={handleStore}>
                <Input
                    id="index"
                    label="Prefijo"
                    value={state.index}
                    error={error('index')}
                    hint="0105, 0134..."
                    onChange={index => setState($0 => ({...$0, index}))} />

                <Input
                    id="name"
                    label="Nombre"
                    value={state.name}
                    className="mt-4"
                    error={error('name')}
                    onChange={name => setState($0 => ({...$0, name}))} />
            </form>
        </Modal>
    )
}

export default UpdateBankModal
