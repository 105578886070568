export const debounce = (func: any, wait: number) => {
    let timeout: any = null

    return (...args: any) => {
        const later = () => {
            clearTimeout(timeout)
            func(...args)
        }
  
        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(later, wait)
    }
}

const parseObject = (object: object): string => {
return Object.entries(object)
    .filter(([_, value]) => value)
    .map(([attribute]) => attribute)
    .join(' ')
}
  
const parseArray = (array: Array<any>): string => {
    return array.filter(item => item).join(' ')
}
  
export const clx = (...args: any[]) => {
    return args.map(param => {
        if (Array.isArray(param)) return parseArray(param)
        if (typeof param === 'object') return parseObject(param)
        return param
    })
    .filter(Boolean)
    .join(' ')
}