import Account from './Account'
import Titular from './Titular'
import Bank from './Bank'

class Paysheet {

    id: number
    order: number
    number: string
    purchase_id?: number
    titular_id?: number
    enterprise_id?: number
    account_id?: number
    transaction: string
    total?: number
    concept: string
    payday?: string
    amount: string | number
    observation: string
    realized: string
    authorized: string
    issued_at: string
    month?: string
    bank?: Bank
    titular?: Titular
    account?: Account
    enterprise?: Account
    //additional attribute
    adition?: string
    adition_reason?: string
    discount?: string
    discount_reason?: string

    constructor(map: Paysheet) {
        this.id = map.id
        this.order = map.order
        this.number = map.number
        this.purchase_id = map.purchase_id
        this.titular_id = map.titular_id
        this.enterprise_id = map.enterprise_id
        this.account_id = map.account_id
        this.transaction = map.transaction
        this.concept = map.concept
        this.amount = map.amount
        this.observation = map.observation
        this.realized = map.realized
        this.authorized = map.authorized
        this.issued_at = map.issued_at
        this.total = map.total
        this.payday = map.payday
        this.month = map.month

        this.bank = map.bank
        this.titular = map.titular
        this.account = map.account
        this.enterprise = map.enterprise

        //additional attribute
        this.adition = map.adition
        this.adition_reason = map.adition_reason
        this.discount = map.discount
        this.discount_reason = map.discount_reason
    }
}

export default Paysheet