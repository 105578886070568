import Repository from '../contracts/Repository'
import RepositoryContext from '../contracts/RepositoryContext';
import { AxiosResponse } from 'axios'
import { Bank } from '~/api/models'

interface ICreateBankBody {
    index: string | number
    name: string
}

export interface IBankEndpointsContext extends Repository<Bank, ICreateBankBody, ICreateBankBody> {}

const endpoints = ({ axios, mapQueryString }: RepositoryContext): IBankEndpointsContext => ({
    index: <T>(query: {}) => axios.get(`/api/banks${mapQueryString(query)}`).then(({ data }: AxiosResponse<T>) => data),
    show: (id: number, query: {}) => axios.get(`/api/banks/${id}${mapQueryString(query)}`).then(({ data }: AxiosResponse<Bank>) => data),
    create: (body: ICreateBankBody) => axios.post(`/api/banks`, body).then(({ data }: AxiosResponse<Bank>) => new Bank(data)),
    update: (id: number, body: ICreateBankBody) => axios.patch(`/api/banks/${id}`, body).then(({ data }: AxiosResponse<Bank>) => new Bank(data)),
    remove: (id: number) => axios.delete(`/api/banks/${id}`)
})


export default endpoints