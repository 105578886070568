import { useEffect, useState } from 'react'
import { Table, DatePicker } from '~/components/ui'
import { useRepository } from '~/components/hooks'
import { useParams } from 'react-router-dom'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { Payment, Pagination } from '~/api/models'

const Payments = ({enterprise}: {enterprise? : string}) => {
    let { id: account } = useParams<{ id: string }>()    
    account= (enterprise) ? '' : account

    const [query, setQuery] = useState({ limit: 10, page: 1, account, enterprise, with: 'account.bank,titular', startDate: null, endDate: null })
    const {fetchPayments, paymentReportURL, paysmentsReportsURL} = useRepository(({ payments, reports }) => ({
        fetchPayments: payments.index,
        paymentReportURL: reports.payment,
        paysmentsReportsURL: reports.paysheets
    }))
    const [pagination, setPagination] = useState<Pagination<Payment>>({ data: [] })
    const [loadings, setLoadings] = useState({ index: false })

    const fetch = (_filters = {}) => {
        const _query = {...query, ..._filters}
        setLoadings(prev => ({...prev, index: !prev.index}))
        fetchPayments<Pagination<Payment>>(_query)
            .then(pagination => new Pagination<Payment>(pagination, $0 => new Payment($0)))
            .then(pagination => setPagination(pagination))
            .then(() => setQuery(_query))
            .finally(() => setLoadings(prev => ({...prev, index: !prev.index})))
    }

    useEffect(() => {
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <div className="flex items-end flex-wrap justify-between mb-4">
                <h1 className="text-2xl dark:text-gray-200">Órdenes de pago</h1>
            </div>

            <Table
                columnFilter
                onSearch={search => fetch({ search })}
                pagination={pagination}
                onPageChange={page => fetch({ page })}
                loading={loadings.index}
                onRefresh={fetch}
                toolbar={{
                    right: (query.startDate && query.endDate && !pagination.isEmpty) && (
                        <a rel="noreferrer" target="_blank" href={paysmentsReportsURL({ startDate: query.startDate, endDate: query.endDate, account: query.account })} className="border flex items-center justify-content rounded-md px-2   ">
                            Descargar resultados
                        </a>
                    )
                }}
                source={pagination.data}
                columns={[
                    { title: 'Tipo', path: 'transaction' },
                    { title: 'Titular', path: 'titular.name' },
                    { title: 'Fecha', path: 'issued_at', filterField: () => (
                        <DatePicker range onChange={([startDate, endDate]: string[]) => fetch({ startDate: startDate && `issued_at,${startDate}`, endDate: endDate && `issued_at,${endDate}` })} />
                    )},
                    { title: 'Total', path: 'amount' },
                    { title: 'Descargar', path: 'id', td: ({ item }) => (
                        <a rel="noreferrer" target="_blank" href={`${paymentReportURL(item.id)}`}>
                            <AiOutlineFilePdf className="text-xl text-purple-500" />
                        </a>
                    )}
                ]} />
        </>
    )
}

export default Payments
