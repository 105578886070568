import {Role} from "~/constants";

import ComapanyAccountsScreen from "~/screens/company-accounts";
import ComapanyAccountScreen from "~/screens/company-accounts/show";


import {PrivateRoute} from "~/Application";
import {Switch} from "react-router-dom";

const ComapanyAccountsRouter = () => (
  <Switch>
  <PrivateRoute exact allows={[Role.admin, Role.developer]} path="/company-accounts">
    <ComapanyAccountsScreen />
  </PrivateRoute>
  <PrivateRoute allows={[Role.admin, Role.developer]} path="/company-accounts/:id">
    <ComapanyAccountScreen />
  </PrivateRoute>
</Switch>
)

export default ComapanyAccountsRouter

