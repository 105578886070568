import Account from './Account'
import User from "~/api/models/User";
class Titular {
  id: number
  name: string
  ric: string
  scope: string
  type: string
  user_id?: number
  accounts: Account[]
  salary: number
  full_salary: number
  accounts_count?: number
  paysheets_count?: number
  payments_count?: number
  user?: User

  constructor(map: Titular) {
    this.id = map.id
    this.name = map.name
    this.ric = map.ric
    this.scope = map.scope
    this.type = map.type
    this.salary = map.salary
    this.full_salary = map.full_salary
    this.user_id = map.user_id
    this.accounts = map.accounts ?? []
    this.accounts_count = map.accounts_count ?? 0
    this.payments_count = map.payments_count ?? 0
    this.paysheets_count = map.paysheets_count ?? 0
    if (map.user) {
      this.user = new User(map.user)
    }
  }
}

export default Titular
