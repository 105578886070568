import {FunctionComponent, useMemo, useState} from "react";
import {Button, DatePicker, Input, Select} from "~/components/ui";

interface IBaseDataProps {
  onNext: (payload: {}) => void
  onBack: () => void
}
// concept, realized, authorized

export interface IBasePaysheetForm {
  payday?: string
  issued_at?: string
  transaction?: string
  concept?: string
  realized?: string
  authorized?: string
}

const baseState: IBasePaysheetForm = {
  realized: 'Yoleizy Henriquez',
  payday: '',
  issued_at: '',
  transaction: '',
  concept: '',
  authorized: ''
}

const BasicData: FunctionComponent<IBaseDataProps> = ({ onBack, onNext }) => {

  const [state, setState] = useState<IBasePaysheetForm>(baseState)
  const shouldDisableNextButton = useMemo(() => !Object.values(state).every(value => value), [state])

  return (
    <div className="p-4">
      <Button onClick={onBack}>
        Regresar
      </Button>
      <small className="dark:text-gray-300 block my-2">
        Ahora, por favor introduzca la información minima necesaria que sera replicada en todas las siguientes nóminas
      </small>

      <div className="flex flex-wrap -mx-2">
        <div className="w-full md:w-1/2 xl:w-1/3 px-2">
          <Select
            required
            id="payday"
            label="Quincena/Motivo"
            value={state.payday}
            onChange={payday => setState(prev => ({...prev, payday: payday as string}))}>
            <option value="">-- Quincena/Motivo --</option>
            <option value="primera">Primera</option>
            <option value="segunda">Segunda</option>
            <option value="completo">Completo</option>
          </Select>
        </div>
        <div className="w-full md:w-1/2 xl:w-1/3 px-2 mt-2 md:mt-0">
          <Select
            required
            id="transaction"
            label="Forma de pago"
            value={state.transaction}
            onChange={transaction => setState(prev => ({...prev, transaction: transaction as string}))}>
            <option value="">-- Forma de pago --</option>
            <option value="Cheque">Cheque</option>
            <option value="Depósito">Depósito</option>
            <option value="Transferencia">Transferencia</option>
          </Select>
        </div>
        <div className="w-full md:w-1/2 mt-2 xl:mt-0 xl:w-1/3 px-2">
          <DatePicker
            id="issued_at"
            label="Fecha"
            value={state.issued_at}
            onChange={([issued_at]) => setState(prev => ({...prev, issued_at}))} />
        </div>
        <div className="w-full md:w-1/2 xl:w-1/3 px-2 mt-2">
          <Select
            id="realized"
            label="Realizado por"
            value={state.realized}
            onChange={realized => setState(prev => ({...prev, realized: realized as string}))}>
            <option value="">-- Seleccione --</option>
            <option value="Yoleizy Henriquez">Yoleizy Henriquez</option>
          </Select>
        </div>
        <div className="w-full md:w-1/2 xl:w-1/3 px-2 mt-2">
          <Input
            id="concept"
            label="Concepto"
            value={state.concept}
            onChange={concept => setState(prev => ({...prev, concept}))}/>
        </div>
        <div className="w-full md:w-1/2 xl:w-1/3 px-2 mt-2">
          <Select
            id="authorized"
            label="Autorizado por"
            value={state.authorized}
            onChange={authorized => setState(prev => ({...prev, authorized: authorized as string}))} >
            <option value="">-- Seleccione --</option>
            <option value="Antonio Figueroa">Antonio Figueroa</option>
            <option value="Glensimar García">Glensimar García</option>
          </Select>
        </div>
      </div>

      <div className="flex justify-end mt-2">
        <Button variant="primary" disabled={shouldDisableNextButton} onClick={() => onNext(state)}>
          Siguiente
        </Button>
      </div>
    </div>
  )
}

export default BasicData