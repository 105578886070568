import {useRepository} from "~/components/hooks";
import {DatePicker, Select} from "~/components/ui";
import {useEffect, useState} from "react";
import {Titular} from "~/api/models";
import {useSession} from "~/context/session";

const PaysheetsReports = () => {

  const [titulars, setTitulars] = useState<Titular[]>([])
  const [loading, setLoading] = useState(false)
  const { session } = useSession()
  const [query, setQuery] = useState({})
  const {paysheets, employees} = useRepository(({ reports, titulars }) => ({
    paysheets: reports.paysheets,
    employees: titulars.index
  }))

  useEffect(() => {
    setLoading(prev => !prev)
    employees<Titular[]>({ limit: 0, scope: 'Empleado', sort_by: 'name,asc' })
      .then(titulars => setTitulars(titulars.map(titular => new Titular(titular))))
      .finally(() => setLoading(prev => !prev))
  }, []) // eslint-disable-line

  return (
    <div className="bg-white rounded-xl border border-gray-50 shadow-md p-4">
      <div className="flex flex-col space-y-4">
        <h1 className="text-xl">Reporte de nóminas</h1>

        <div className="flex gap-2">
          <DatePicker
            label="Fecha inicial"
            id="startDate"
            onChange={([startDate]) => setQuery(prev => ({
              ...prev,
              ...(startDate ? {startDate: `issued_at,${startDate}`} : null)
            }))} />
          <DatePicker
            label="Fecha final"
            id="endDate"
            onChange={([endDate]) => setQuery(prev => ({
              ...prev,
              ...(endDate ? {endDate: `issued_at,${endDate}`} : null)
            }))} />

          {session?.isAdmin && (
            <Select
              label="Empleado"
              id="paysheet_employee"
              loading={loading}
              onChange={titular => setQuery(prev => ({...prev, titular}))}>
              <option value="">-- Opcional --</option>
              {titulars.map(titular => (
                <option value={titular.id}>
                  {titular.name}
                </option>
              ))}
            </Select>
          )}
        </div>
      </div>

      <div className="flex justify-end">
        <a
          className="bg-blue-500 px-4 py-2 rounded-lg text-white"
          href={`${paysheets(query)}`} target="_blank" rel="noreferrer">
          Generar reporte
        </a>
      </div>
    </div>
  )
}

export default PaysheetsReports