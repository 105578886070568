import {FunctionComponent} from "react";
import {BrowserRouter as Router, Switch, Route, Redirect, RouteProps, useHistory} from 'react-router-dom'
import SessionProvider, { useSession } from '~/context/session'
import ToastProvider from '~/components/hooks/useToast'
import Layout from '~/layouts/index'
import LoginScreen from '~/screens/authentication/login/login'

import DashboardScreen from "~/screens/dashboard/index";
import ReportsScreen from '~/screens/reports'
import SettingsScreen from '~/screens/settings'
import TitularsRouter from "~/routes/TitularsRouter";
import AccountsRouter from "~/routes/AccountsRouter";
import ComapanyAccountsRouter from "~/routes/CompanyAccountsRouter"
import BanksRouter from "~/routes/BanksRouter";
import PaysheetsRouter from "~/routes/PaysheetsRouter";
import PaymentsRouter from "~/routes/PaymentsRouter";
import IncomesRouter from "~/routes/IncomesRouter";

import {Modal} from "./components/ui";
import RegisterScreen from "~/screens/authentication/register/register";

interface IMiddlewaredRouteProps extends RouteProps {
  allows?: number[]
  redirectsTo?: string
}

export const PrivateRoute: FunctionComponent<IMiddlewaredRouteProps> = ({
  allows = [],
  redirectsTo,
 ...rest
}) => {

  const { isUserLogged, session } = useSession()
  const router = useHistory()

  if (isUserLogged) {

    const role = session?.type ?? 0

    if (allows.length === 0 || allows.includes(role)) {
      return <Route {...rest} />
    } else {
      return (
        <Modal
          show={true}
          onConfirm={() => router.push(redirectsTo ?? '/dashboard')}>
          <h1 className="text-2xl text-center">No tienes permitido acceder a esta información</h1>
        </Modal>
      )
    }
  }

  return <Redirect to={{pathname: '/'}}/>
}

const OnlyGuestRoute = ({...rest}: any) => {

  const { isUserLogged } = useSession()

  return isUserLogged
    ? <Redirect to={{pathname: '/dashboard'}}/>
    : <Route {...rest}/>
}

const Application = () => {

  return (
    <Router>
      <ToastProvider>
        <SessionProvider>
          <Layout>
            <Switch>
              <OnlyGuestRoute exact path="/">
                <LoginScreen />
              </OnlyGuestRoute>

              <OnlyGuestRoute exact path="/register">
                <RegisterScreen />
              </OnlyGuestRoute>

              <PrivateRoute exact path="/dashboard">
                <DashboardScreen />
              </PrivateRoute>
              <PrivateRoute path="/settings">
                <SettingsScreen />
              </PrivateRoute>
              <PrivateRoute path="/reports">
                <ReportsScreen />
              </PrivateRoute>
            </Switch>

            <TitularsRouter />
            <AccountsRouter />
            <ComapanyAccountsRouter />
            <BanksRouter />
            <PaysheetsRouter />
            <PaymentsRouter />
            <IncomesRouter />
          </Layout>
        </SessionProvider>
      </ToastProvider>
    </Router>
  )
}

export default Application
